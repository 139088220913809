import React from "react";
import {
  Ionicons,
  MaterialCommunityIcons,
  FontAwesome5,
  FontAwesome
} from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { getFocusedRouteNameFromRoute } from "@react-navigation/native";
import { useFocusEffect } from "@react-navigation/core";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
  useIsDrawerOpen
} from "@react-navigation/drawer";
import { DrawerActions } from "@react-navigation/compat";
import { Text, TouchableOpacity, View } from "react-native";
import { Avatar } from "react-native-elements";
import ChatUsers from "../containers/ChatUsers";
import CommunityDetails from "../containers/CommunityDetails";
import GoodDeals from "../containers/GoodDeals";
import LiveScreen from "../containers/LiveScreen";
import Meteo from "../containers/Meteo";
import { t } from "../services/i18n";
import commonStyles, {
  COLOR2,
  COLOR5,
  WHITE_COLOR,
  isiOS
} from "../styles/commonStyles";
import { ROLE_GUEST } from "../config/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  LOGOUT,
  DISPLAY_ALL,
  SHOW_QR_CODE_SCANNER_MODAL
} from "../actions/types";
import Settings from "../containers/Settings";
import { getImage } from "../assets/Images";
import { insetsBottom } from "../containers/Insets";
import ChatCampers from "../containers/ChatCampers";
import ChatEmployees from "../containers/ChatEmployees";
import ChatGroup from "../containers/ChatGroup";
import AppInfos from "../containers/AppInfos";
import GoodDealsMap from "../containers/GoodDealsMap";
import AsyncStorage from "@react-native-async-storage/async-storage";

const BottomTabs = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

function CustomDrawerContent(props) {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.profileReducer);
  const isDrawerOpen = useIsDrawerOpen();

  const {
    flex1,
    mv20,
    mb20,
    mh20,
    fontBold,
    row,
    alignItemsCenter,
    justifyContentCenter,
    bgWhite,
    bgColor2,
    bgColor5
  } = commonStyles;
  const { navigation, displayDisconnect = false } = props;

  useFocusEffect(() => {
    navigation
      ?.dangerouslyGetParent()
      ?.dangerouslyGetParent()
      ?.setOptions({
        // headerTitle: getHeaderTitle(route),
        headerLeft: () => (
          <TouchableOpacity
            style={flex1}
            onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
          >
            <View
              style={[
                isDrawerOpen ? bgWhite : bgColor2,
                alignItemsCenter,
                justifyContentCenter,
                {
                  height: "100%",
                  width: 56,
                  paddingBottom: 3
                }
              ]}
            >
              <MaterialCommunityIcons
                name="menu"
                color={isDrawerOpen ? COLOR2 : WHITE_COLOR}
                size={28}
              />
            </View>
          </TouchableOpacity>
        )
      });
  });

  return (
    <DrawerContentScrollView {...props}>
      <TouchableOpacity
        onPress={() => {
          navigation.navigate("ProfileUpdateScreen");
          navigation.dispatch(DrawerActions.closeDrawer());
        }}
        disabled={!user || user?.role === ROLE_GUEST}
        style={[row, alignItemsCenter, isiOS ? mb20 : mv20]}
      >
        {user?.role === ROLE_GUEST ? (
          <Avatar
            rounded
            size={40}
            icon={{ name: "guest", type: "zocial" }}
            activeOpacity={0.7}
            containerStyle={[mh20, bgColor5]}
          />
        ) : (
          <Avatar
            rounded
            size={40}
            source={
              user?.photoUri
                ? { uri: user?.photoUri }
                : user?.gender === "F"
                ? getImage("avatarF")
                : getImage("avatar")
            }
            title={user?.firstname?.toUpperCase().substr(0, 2)}
            activeOpacity={0.7}
            containerStyle={[mh20]}
          />
        )}
        <Text style={[fontBold]}>
          {!user || user.role === ROLE_GUEST
            ? t("profile:guest")
            : `${user?.firstname} ${user?.lastname}`}
        </Text>
      </TouchableOpacity>
      <DrawerItemList {...props} />
      {displayDisconnect && (
        <DrawerItem
          label={t("button:disconnect")}
          icon={({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <MaterialCommunityIcons
                name="exit-run"
                size={size}
                color={color}
              />
            </View>
          )}
          onPress={() => {
            AsyncStorage.removeItem("REDIRECT_LINK");
            dispatch({
              type: LOGOUT
            });
          }}
        />
      )}
    </DrawerContentScrollView>
  );
}

function LiveDrawer(props) {
  const { navigation, route } = props;
  const {
    bgWhite,
    flex1,
    bgColor2,
    justifyContentCenter,
    alignItemsCenter
  } = commonStyles;

  const { filter, onClickFilter, displayFilterOptions } = useSelector(
    state => state.liveReducer
  );
  const { communityDetails } = useSelector(state => state.communityReducer);

  const isDefault = filter === DISPLAY_ALL && !displayFilterOptions;
  const routeName = getFocusedRouteNameFromRoute(route) ?? route.name;

  useFocusEffect(() => {
    navigation.dangerouslyGetParent().setOptions({
      headerTitle: getHeaderTitle(route),
      headerRight: () =>
        routeName.startsWith("Live") && (
          <TouchableOpacity style={flex1} onPress={onClickFilter}>
            <View
              style={[
                isDefault ? bgColor2 : bgWhite,
                alignItemsCenter,
                justifyContentCenter,
                {
                  height: "100%",
                  width: 56,
                  paddingBottom: 3
                }
              ]}
            >
              <FontAwesome
                name="filter"
                color={isDefault ? WHITE_COLOR : COLOR2}
                size={isDefault ? 24 : 32}
                style={{
                  backgroundColor: "transparent"
                }}
              />
            </View>
          </TouchableOpacity>
        )
    });
  });

  return (
    <Drawer.Navigator
      drawerContent={props => (
        <CustomDrawerContent
          {...props}
          displayFilter={displayFilterOptions}
          displayDisconnect={true}
        />
      )}
      drawerContentOptions={{
        activeTintColor: WHITE_COLOR,
        activeBackgroundColor: COLOR2,
        contentContainerStyle: [bgWhite]
      }}
      edgeWidth={200}
    >
      <Drawer.Screen
        name="LiveScreen"
        component={LiveScreen}
        initialParams={{ communityUri: communityDetails?.customUri }}
        options={{
          title: t("menu:live"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <MaterialCommunityIcons
                name="animation-outline"
                size={size}
                color={color}
              />
            </View>
          )
        }}
      />
      <Drawer.Screen
        name="SettingsScreen"
        component={Settings}
        initialParams={{ communityUri: communityDetails?.customUri }}
        options={{
          title: t("menu:parameters"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <Ionicons name="settings" size={size} color={color} />
            </View>
          ),
          headerShown: false
        }}
      />
    </Drawer.Navigator>
  );
}

function ChatDrawer(props) {
  const { navigation, route } = props;
  const { justifyContentCenter, alignItemsCenter } = commonStyles;
  const { communityDetails } = useSelector(state => state.communityReducer);

  useFocusEffect(() => {
    navigation.dangerouslyGetParent().setOptions({
      headerTitle: getHeaderTitle(route),
      headerRight: () => undefined
    });
  });
  return (
    <Drawer.Navigator
      drawerContent={props => <CustomDrawerContent {...props} />}
      drawerContentOptions={{
        activeTintColor: WHITE_COLOR,
        activeBackgroundColor: COLOR2
      }}
      drawerStyle={{ width: 300 }}
      edgeWidth={200}
    >
      <Drawer.Screen
        name="ChatOwnScreen"
        component={ChatUsers}
        options={{
          title: t("chat:myconversations"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <MaterialCommunityIcons name="chat" size={size} color={color} />
            </View>
          )
        }}
      />
      <Drawer.Screen
        name="ChatGroupScreen"
        component={ChatGroup}
        initialParams={{ communityUri: communityDetails?.customUri }}
        options={{
          title: t("chat:groupconversations"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <Ionicons name="ios-chatbubbles" size={size} color={color} />
            </View>
          )
        }}
      />
      <Drawer.Screen
        name="ChatEmployeesScreen"
        component={ChatEmployees}
        initialParams={{ communityUri: communityDetails?.customUri }}
        options={{
          title: t("chat:atmyservice"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <FontAwesome name="handshake-o" size={size} color={color} />
            </View>
          )
        }}
      />
      <Drawer.Screen
        name="ChatCampersScreen"
        component={ChatCampers}
        initialParams={{ communityUri: communityDetails?.customUri }}
        options={{
          title: t("chat:cocampers"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <FontAwesome name="users" size={size} color={color} />
            </View>
          )
        }}
      />
    </Drawer.Navigator>
  );
}

function GoodDealsDrawer(props) {
  const dispatch = useDispatch();
  const { navigation, route } = props;
  const {
    bgWhite,
    flex1,
    bgColor2,
    justifyContentCenter,
    alignItemsCenter,
    row,
    h100p
  } = commonStyles;
  const { showQrCodeScannerModal } = useSelector(state => state.urlscanReducer);
  const {
    filter: filterGD,
    onClickFilter: onClickFilterGD,
    displayFilterOptions: displayFilterOptionsGD
  } = useSelector(state => state.gooddealReducer);
  const { communityDetails } = useSelector(state => state.communityReducer);

  const isDefault = !filterGD.length && !displayFilterOptionsGD;
  const routeName = getFocusedRouteNameFromRoute(route) ?? route.name;
  useFocusEffect(() => {
    navigation.dangerouslyGetParent().setOptions({
      headerTitle: getHeaderTitle(route),
      headerRight: () =>
        (routeName === "GoodDealsScreen" || routeName === "GoodDealsTab") &&
        !showQrCodeScannerModal && (
          <View style={[flex1, row]}>
            <TouchableOpacity
              style={flex1}
              onPress={() =>
                dispatch({
                  type: SHOW_QR_CODE_SCANNER_MODAL
                })
              }
            >
              <View
                style={[
                  alignItemsCenter,
                  justifyContentCenter,
                  h100p,
                  {
                    width: 56
                  }
                ]}
              >
                <MaterialCommunityIcons
                  name="qrcode-scan"
                  color={WHITE_COLOR}
                  size={28}
                />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={flex1} onPress={onClickFilterGD}>
              <View
                style={[
                  isDefault ? bgColor2 : bgWhite,
                  alignItemsCenter,
                  justifyContentCenter,
                  {
                    height: "100%",
                    width: 56,
                    paddingBottom: 3
                  }
                ]}
              >
                <FontAwesome
                  name="filter"
                  color={isDefault ? WHITE_COLOR : COLOR2}
                  size={isDefault ? 24 : 32}
                  style={{
                    backgroundColor: "transparent"
                  }}
                />
              </View>
            </TouchableOpacity>
          </View>
        )
    });
  });

  return (
    <Drawer.Navigator
      drawerContent={props => (
        <CustomDrawerContent
          {...props}
          displayFilter={displayFilterOptionsGD}
        />
      )}
      drawerContentOptions={{
        activeTintColor: WHITE_COLOR,
        activeBackgroundColor: COLOR2
      }}
      edgeWidth={200}
    >
      <Drawer.Screen
        name="GoodDealsScreen"
        component={GoodDeals}
        initialParams={{ communityUri: communityDetails?.customUri }}
        options={{
          title: t("menu:localLife"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <FontAwesome5 name="map-marker-alt" size={size} color={color} />
            </View>
          )
        }}
      />
      <Drawer.Screen
        name="GoodDealsMapScreen"
        component={GoodDealsMap}
        initialParams={{ communityUri: communityDetails?.customUri }}
        options={{
          title: t("menu:near"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <FontAwesome5 name="map-marked-alt" size={size} color={color} />
            </View>
          )
        }}
      />
    </Drawer.Navigator>
  );
}

function MeteoDrawer(props) {
  const { navigation, route } = props;
  const { justifyContentCenter, alignItemsCenter } = commonStyles;
  const { communityDetails } = useSelector(state => state.communityReducer);

  useFocusEffect(() => {
    navigation.dangerouslyGetParent().setOptions({
      headerTitle: getHeaderTitle(route),
      headerRight: () => undefined
    });
  });

  return (
    <Drawer.Navigator
      drawerContent={props => <CustomDrawerContent {...props} />}
      drawerContentOptions={{
        activeTintColor: WHITE_COLOR,
        activeBackgroundColor: COLOR2
      }}
      edgeWidth={200}
    >
      <Drawer.Screen
        name="MeteoScreen"
        component={Meteo}
        initialParams={{ communityUri: communityDetails?.customUri }}
        options={{
          title: t("menu:weather"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <MaterialCommunityIcons
                name="weather-partly-cloudy"
                size={size}
                color={color}
              />
            </View>
          )
        }}
      />
    </Drawer.Navigator>
  );
}

function CommunityDetailsDrawer(props) {
  const { navigation, route } = props;
  const { justifyContentCenter, alignItemsCenter } = commonStyles;
  const { communityDetails } = useSelector(state => state.communityReducer);

  useFocusEffect(() => {
    navigation.dangerouslyGetParent().setOptions({
      headerTitle: getHeaderTitle(route),
      headerRight: () => undefined
    });
  });

  return (
    <Drawer.Navigator
      drawerContent={props => <CustomDrawerContent {...props} />}
      drawerContentOptions={{
        activeTintColor: WHITE_COLOR,
        activeBackgroundColor: COLOR2
      }}
      edgeWidth={200}
    >
      <Drawer.Screen
        name="CommunityDetailsScreen"
        component={CommunityDetails}
        initialParams={{ communityUri: communityDetails?.customUri }}
        options={{
          title: t("menu:camping"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <FontAwesome5 name="campground" size={size} color={color} />
            </View>
          )
        }}
      />
      <Drawer.Screen
        name="AppInfosScreen"
        component={AppInfos}
        options={{
          title: t("menu:about"),
          drawerIcon: ({ color, size }) => (
            <View
              style={[
                { width: 30, height: 30 },
                alignItemsCenter,
                justifyContentCenter
              ]}
            >
              <FontAwesome5 name="info-circle" size={size} color={color} />
            </View>
          )
        }}
      />
    </Drawer.Navigator>
  );
}

function getHeaderTitle(route) {
  const routeName = getFocusedRouteNameFromRoute(route) ?? route.name;
  switch (routeName) {
    case "TabScreen":
    case "LiveTab":
    case "LiveScreen":
      return t("menu:live");
    case "ChatTab":
      return t("menu:chat");
    case "GoodDealsTab":
    case "GoodDealsScreen":
      return t("menu:localLife");
    case "GoodDealsMapScreen":
      return t("menu:near");
    case "MeteoTab":
    case "MeteoScreen":
      return t("menu:weather");
    case "CommunityDetailsTab":
    case "CommunityDetailsScreen":
      return t("menu:camping");
    case "SettingsScreen":
      return t("menu:parameters");
    case "ChatOwnScreen":
      return t("chat:myconversations");
    case "ChatEmployeesScreen":
      return t("chat:atmyservice");
    case "ChatCampersScreen":
      return t("chat:cocampers");
    case "ChatGroupScreen":
      return t("chat:groupconversations");
    case "AppInfosScreen":
      return t("menu:about");
    default:
      return routeName;
  }
}

export default function BottomTabsNavigator() {
  const { cptNewGoodDeals } = useSelector(state => state.gooddealReducer);
  const { totalNotRead } = useSelector(state => state.chatReducer);
  const { cptNewEvents } = useSelector(state => state.liveReducer);

  return (
    <BottomTabs.Navigator
      tabBarOptions={{
        activeTintColor: "white",
        inactiveTintColor: "white",
        activeBackgroundColor: COLOR2,
        inactiveBackgroundColor: COLOR5,
        adaptive: false,
        style: {
          height: 50 + insetsBottom
        },
        tabStyle: {
          paddingBottom: 5
        }
      }}
    >
      <BottomTabs.Screen
        name="LiveTab"
        component={LiveDrawer}
        options={{
          tabBarLabel: t("menu:live"),
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons
              name="animation-outline"
              size={size}
              color={color}
            />
          ),
          tabBarBadge: cptNewEvents ? cptNewEvents : undefined
        }}
      />
      <BottomTabs.Screen
        name="ChatTab"
        component={ChatDrawer}
        options={{
          tabBarLabel: t("menu:chat"),
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="ios-chatbubbles" size={size} color={color} />
          ),
          tabBarBadge: totalNotRead ? totalNotRead : undefined
        }}
      />
      <BottomTabs.Screen
        name="GoodDealsTab"
        component={GoodDealsDrawer}
        options={{
          tabBarLabel: t("menu:localLife"),
          tabBarIcon: ({ color, size }) => (
            <FontAwesome5 name="map-marker-alt" size={size} color={color} />
          ),
          tabBarBadge: cptNewGoodDeals ? cptNewGoodDeals : undefined
        }}
      />
      <BottomTabs.Screen
        name="MeteoTab"
        component={MeteoDrawer}
        options={{
          tabBarLabel: t("menu:weather"),
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons
              name="weather-partly-cloudy"
              size={size}
              color={color}
            />
          )
        }}
      />
      <BottomTabs.Screen
        name="CommunityDetailsTab"
        component={CommunityDetailsDrawer}
        options={{
          tabBarLabel: t("menu:infos"),
          tabBarIcon: ({ color, size }) => (
            <FontAwesome5 name="info-circle" size={size} color={color} />
          )
        }}
      />
    </BottomTabs.Navigator>
  );
}
