import React, { Component } from "react";
import {
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Platform
} from "react-native";
import { connect } from "react-redux";
import commonStyles from "../styles/commonStyles";
import { t } from "../services/i18n";
import Constants from "expo-constants";
import { getImage } from "../assets/Images";
import {
  BUSINESS_NAME,
  SLOGAN,
  WEB_URL,
  CONTACT_MAIL
} from "../config/constants.js";
import { openURL, openBrowser } from "../utils/UrlUtil";
import { useNavigation } from "@react-navigation/native";
import { useSelector, useDispatch } from "react-redux";

export default function AppInfos() {
  const {
    color3,
    color2,
    color4,
    color5,
    bgWhite,
    font,
    bgColor1,
    alignItemsCenter,
    row,
    mt5,
    mt10,
    mt20,
    p20,
    mb10,
    fs14,
    shadowGrey,
    textCenter,
    underline,
    flex1
  } = commonStyles;
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const serverVersion = useSelector(
    state => state.globalInformationsReducer.serverVersion
  );

  return (
    <View style={[flex1, bgColor1]}>
      <ScrollView style={[bgColor1]} showsVerticalScrollIndicator={false}>
        <View style={[p20, bgWhite, mt5, shadowGrey, mb10]}>
          <View style={[alignItemsCenter]}>
            <Text style={[font, color5, { fontSize: 20 }]}>
              {BUSINESS_NAME}
            </Text>
            {Boolean(SLOGAN) && (
              <Text style={[font, color4, fs14]}>{SLOGAN}</Text>
            )}
            <Text
              style={[mt10, font, color2, underline, fs14]}
              onPress={() =>
                Platform.OS === "web"
                  ? openBrowser(
                      dispatch,
                      `https://${WEB_URL}/conditions-generales-utilisation`
                    )
                  : navigation.navigate("CGUScreen")
              }
            >
              {t("login:cgu")}
            </Text>
          </View>
          <View style={[row, mt20, { justifyContent: "space-between" }]}>
            <View>
              <Text style={[font, mt10, color5, { fontSize: 12 }]}>
                {t("infos:clientversion")}{" "}
                <Text style={[font, mt10, color3, { fontSize: 15 }]}>
                  {Constants.manifest.version}
                  {" Beta"}
                </Text>
              </Text>

              <Text style={[font, mt10, color5, { fontSize: 12 }]}>
                {t("infos:serverversion")}{" "}
                <Text style={[font, mt10, color3, { fontSize: 15 }]}>
                  {serverVersion}
                </Text>
              </Text>
            </View>

            <TouchableOpacity
              onPress={() => openBrowser(dispatch, `https://${WEB_URL}`)}
            >
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "flex-end",
                  height: 100
                }}
              >
                <Image
                  style={{ width: 100, height: 100 }}
                  source={getImage("logo")}
                />
              </View>
            </TouchableOpacity>
          </View>
          <Text style={[font, color5, { fontSize: 12, lineHeight: 20 }]}>
            {t("infos:author")}
            <Text style={[font, mt20, color3, { fontSize: 12 }]}>
              {t("infos:salah")}
            </Text>
            {t("infos:author2")}
          </Text>
          <Text style={[font, mt20, color5, { fontSize: 12 }]}>
            {t("infos:contact")}{" "}
            <Text
              style={[font, mt20, color2, { fontSize: 12 }]}
              onPress={() => openURL(dispatch, `mailto:${CONTACT_MAIL}`)}
            >
              {CONTACT_MAIL}
            </Text>
          </Text>
          <Text style={[font, mt20, color5, { fontSize: 12 }]}>
            {t("infos:website")}{" "}
            <Text
              style={[font, mt20, color2, { fontSize: 12 }]}
              onPress={() => openBrowser(dispatch, `https://${WEB_URL}`)}
            >
              {WEB_URL}
            </Text>
          </Text>
        </View>
        <View style={[p20, bgWhite, shadowGrey, mb10]}>
          <Text style={[font, color5, textCenter, { fontSize: 17 }]}>
            {t("infos:thanks")}
          </Text>
          <Text style={[font, mt10, color5, { fontSize: 12, lineHeight: 20 }]}>
            {t("infos:thanksbruno")}
            <Text style={[font, color3, { fontSize: 12 }]}>
              {t("infos:bruno")}
            </Text>
            {t("infos:thanksbruno2")}
          </Text>
          <Text style={[font, mt10, color5, { fontSize: 12, lineHeight: 20 }]}>
            {t("infos:thanksreacteur")}
            <Text style={[font, color3, { fontSize: 12 }]}>
              {t("infos:farid")}
            </Text>
            {t("infos:and")}
            <Text style={[font, color3, { fontSize: 12 }]}>
              {t("infos:xavier")}
            </Text>
            {t("infos:thanksreacteur2")}
            <Text
              style={[font, color2, { fontSize: 12 }]}
              onPress={() => openBrowser(dispatch, "https://www.lereacteur.io")}
            >
              www.lereacteur.io
            </Text>
            {t("infos:thanksreacteur3")}
          </Text>
          <Text style={[font, mt10, color5, { fontSize: 12, lineHeight: 20 }]}>
            {t("infos:thanksnathalie")}
            <Text style={[font, color3, { fontSize: 12 }]}>
              {t("infos:nathalie")}
            </Text>
            {t("infos:thanksnathalie2")}
            <Text
              style={[font, color2, { fontSize: 12 }]}
              onPress={() => openBrowser(dispatch, "https://etoile-et-vies.fr")}
            >
              www.etoile-et-vies.fr
            </Text>
            {t("infos:thanksnathalie3")}
          </Text>
        </View>
        <View style={[p20, bgWhite, shadowGrey, mb10]}>
          <Text style={[font, color5, textCenter, { fontSize: 17 }]}>
            {t("infos:resources")}
          </Text>
          <Text style={[font, mt10, color5, { fontSize: 12, lineHeight: 20 }]}>
            {t("infos:pictos")}
            <Text
              style={[font, mt20, color2, { fontSize: 12 }]}
              onPress={() => openBrowser(dispatch, "https://www.freepik.com")}
            >
              Freepik
            </Text>
            {t("infos:from")}
            <Text
              style={[font, mt20, color2, { fontSize: 12 }]}
              onPress={() => openBrowser(dispatch, "https://www.flaticon.com")}
            >
              www.flaticon.com
            </Text>
            .
          </Text>
        </View>
      </ScrollView>
    </View>
  );
}
