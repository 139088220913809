import Constants from "expo-constants";
import * as IntentLauncher from "expo-intent-launcher";
import * as SplashScreen from "expo-splash-screen";
import React, { Component } from "react";
import { Platform, View } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchLogout } from "../actions/login";
import {
  CLEAR_DROP_DOWN_ALERT_ERROR,
  CLEAR_DROP_DOWN_ALERT_INFO,
  CLEAR_DROP_DOWN_ALERT_MESSAGE,
  CLEAR_DROP_DOWN_ALERT_WARN,
  CLEAR_LOGOUT,
  LOGOUT,
  RESET_AND_SUBSCRIBE,
  RESET_DATA
} from "../actions/types";
import {
  ROLE_GUEST,
  APPLE_STORE_URL,
  GOOGLE_PLAY_URL
} from "../config/constants";
import { t } from "./i18n";
import { openURL } from "../utils/UrlUtil";

class ErrorManagement extends Component {
  async componentDidUpdate(prevProps, prevState) {
    const {
      dispatch,
      navigationRef,
      fetchLogout,
      isMaintenance,
      dropDownAlertError,
      dropDownAlertInfo,
      dropDownAlertWarn,
      dropDownAlertMessage,
      dropDownAutoClearAlertError,
      dropDownAutoClearAlertWarn,
      dropDownAutoClearAlertInfo,
      logout,
      logoutResult,
      splashScreenHide,
      dropDownHolder
    } = this.props;

    if (!prevProps.splashScreenHide && splashScreenHide) {
      await SplashScreen.hideAsync();
    }

    if (!prevProps.isMaintenance && isMaintenance) {
      navigationRef.current?.navigate("MaintenanceScreen");
    }

    if (!prevProps.logout && logout) {
      fetchLogout();
    }

    if (!prevProps.logoutResult && logoutResult) {
      dispatch({
        type: RESET_DATA
      });

      //   navigationRef.current?.navigate(
      //     "LoadingScreen",
      //     logout === RESET_AND_SUBSCRIBE ? { subscribe: true } : undefined
      //   );

      dispatch({
        type: CLEAR_LOGOUT
      });
    }

    // Cas des erreurs
    if (dropDownAutoClearAlertError && !prevProps.dropDownAutoClearAlertError) {
      dropDownHolder?.alertWithType(
        "error",
        t("alert:error"),
        t("login:" + dropDownAutoClearAlertError)
      );

      dispatch({
        type: CLEAR_DROP_DOWN_ALERT_ERROR
      });
    }
    if (dropDownAlertError && !prevProps.dropDownAlertError) {
      if (!splashScreenHide) {
        await SplashScreen.hideAsync();
      }
      AsyncStorage.removeItem("REDIRECT_LINK");
      dropDownHolder?.alertWithType(
        "error",
        t("alert:error"),
        t("login:" + dropDownAlertError)
      );
    }
    if (dropDownAlertInfo && !prevProps.dropDownAlertInfo) {
      dropDownHolder?.alertWithType(
        "info",
        t("alert:info"),
        t("login:" + dropDownAlertInfo)
      );
    }
    if (dropDownAlertWarn && !prevProps.dropDownAlertWarn) {
      // Case obsolete version
      if (dropDownAlertWarn.startsWith("obsolete") || !splashScreenHide) {
        await SplashScreen.hideAsync();
      }
      dropDownHolder?.alertWithType(
        "warn",
        t("alert:warn"),
        t("login:" + dropDownAlertWarn),
        {
          dropDownAlertWarn
        },
        Number.MAX_VALUE
      );
    }
    if (dropDownAutoClearAlertWarn && !prevProps.dropDownAutoClearAlertWarn) {
      dropDownHolder?.alertWithType(
        "warn",
        t("alert:warn"),
        t("login:" + dropDownAutoClearAlertWarn),
        {
          dropDownAutoClearAlertWarn
        }
      );
      dispatch({
        type: CLEAR_DROP_DOWN_ALERT_WARN
      });
    }
    if (dropDownAutoClearAlertInfo && !prevProps.dropDownAutoClearAlertInfo) {
      dropDownHolder?.alertWithType(
        "info",
        t("alert:info"),
        t("login:" + dropDownAutoClearAlertInfo),
        {
          dropDownAutoClearAlertInfo
        }
      );
      dispatch({
        type: CLEAR_DROP_DOWN_ALERT_INFO
      });
    }
    if (dropDownAlertMessage && !prevProps.dropDownAlertMessage) {
      dropDownHolder?.alertWithType(
        "custom",
        dropDownAlertMessage.title,
        dropDownAlertMessage.message,
        dropDownAlertMessage
      );

      dispatch({
        type: CLEAR_DROP_DOWN_ALERT_MESSAGE
      });
    }
  }

  onTap = message => {
    const { dispatch, user, navigationRef } = this.props;

    if (message.payload) {
      if (user && user !== ROLE_GUEST && message.payload.id) {
        navigationRef.current?.navigate("ChatTeamScreen", {
          to: message.payload.id
        });
      } else if (user && user !== ROLE_GUEST && message.payload.pseudo) {
        navigationRef.current?.navigate("ChatScreen", {
          pseudo: message.payload.pseudo
        });
      } else if (user && user !== ROLE_GUEST && message.payload.eventId) {
        navigationRef.current?.navigate("ChatGrpScreen", {
          eventId: message.payload.eventId
        });
      } else if (message.payload.dropDownAlertWarn === "obsoleteversionios") {
        openURL(dispatch, APPLE_STORE_URL);
      } else if (
        message.payload.dropDownAlertWarn === "obsoleteversionandroid"
      ) {
        openURL(dispatch, GOOGLE_PLAY_URL);
      } else if (
        message.payload.dropDownAutoClearAlertInfo === "locationinfo" ||
        message.payload.dropDownAutoClearAlertInfo === "notificationinfo" ||
        message.payload.dropDownAutoClearAlertInfo === "photosinfo" ||
        message.payload.dropDownAutoClearAlertInfo === "camerainfo"
      ) {
        if (Platform.OS === "ios") {
          openURL(dispatch, "app-settings:");
        } else {
          const pkg = Constants.manifest.releaseChannel
            ? Constants.manifest.android.package // When published, considered as using standalone build
            : "host.exp.exponent"; // In expo client mode

          IntentLauncher.startActivityAsync(
            IntentLauncher.ACTION_APPLICATION_DETAILS_SETTINGS,
            {
              data: "package:" + pkg
            }
          );
        }
      } else if (
        message.payload.dropDownAutoClearAlertWarn === "guestforbidden"
      ) {
        dispatch({
          type: LOGOUT,
          value: RESET_AND_SUBSCRIBE
        });
      }
    }
  };

  onClose = message => {
    // if (message.payload.dropDownAutoClearAlertWarn === "needupdate") {
    //   Updates.reloadAsync();
    // }
  };

  render() {
    // Empty view
    return <View />;
  }
}

const mapStateToProps = state => {
  const {
    isMaintenance,
    dropDownAlertInfo,
    dropDownAlertError,
    dropDownAlertMessage,
    dropDownAutoClearAlertError,
    dropDownAutoClearAlertWarn,
    dropDownAutoClearAlertInfo,
    splashScreenHide,
    logout: errorlogout
  } = state.errorReducer;

  const { logout, logoutResult } = state.loginReducer;

  // TODO: Remove dropDownAlert from globalInformationsReducer
  const {
    dropDownAlertInfo: dropDownAlertInfoGI,
    dropDownAlertWarn,
    dropDownAlertError: dropDownAlertErrorGI
  } = state.globalInformationsReducer;

  const { user } = state.profileReducer;
  return {
    isMaintenance,
    dropDownAlertInfo: dropDownAlertInfo || dropDownAlertInfoGI,
    dropDownAlertWarn,
    dropDownAlertError: dropDownAlertError || dropDownAlertErrorGI,
    dropDownAlertMessage,
    dropDownAutoClearAlertError,
    dropDownAutoClearAlertWarn,
    dropDownAutoClearAlertInfo,
    user,
    logout: logout || errorlogout,
    logoutResult,
    splashScreenHide
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators(
    {
      fetchLogout
    },
    dispatch
  );
  return { ...actions, dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(ErrorManagement);
