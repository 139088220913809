import React, { Component } from "react";
import Popup from "../components/mapLinks/components/Popup";
import { t } from "../services/i18n";

class MapLink extends Component {
  _mounted = false;

  state = {
    isVisible: false
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  setVisibleFalse = () => {
    if (this._mounted) {
      this.setState({ isVisible: false });
    }
  };

  setVisibleTrue = () => {
    if (this._mounted) {
      this.setState({ isVisible: true });
    }
  };

  render() {
    const { latitude, longitude, title, lang } = this.props;
    let dialogTitle, dialogMessage, cancelText;
    if (lang === "fr") {
      dialogTitle = t("mapRoute:dialogTitle");
      dialogMessage = t("mapRoute:dialogMessage");
      cancelText = t("button:cancel");
    }
    return (
      <Popup
        isVisible={this.state.isVisible}
        onCancelPressed={this.setVisibleFalse}
        onAppPressed={this.setVisibleFalse}
        onBackButtonPressed={this.setVisibleFalse}
        modalProps={{
          // you can put all react-native-modal props inside.
          animationIn: "slideInUp"
        }}
        options={{
          latitude,
          longitude,
          title,
          dialogTitle,
          dialogMessage,
          cancelText,
          googleForceLatLon: true
        }}
        style={{
          itemText: { fontFamily: "font" },
          titleText: { fontFamily: "font" }
        }}
      />
    );
  }
}

export default MapLink;
