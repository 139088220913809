import React from "react";
import { TouchableOpacity, View, Image } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
// import CGUReader from "../containers/CGUReader";
import LoadingScreen from "../containers/LoadingScreen";
import LandingScreen from "../containers/LandingScreen";
import MaintenanceScreen from "../containers/Maintenance";
import EventDetails from "../containers/EventDetails";
import GoodDealDetails from "../containers/GoodDealDetails";
import AppInfos from "../containers/AppInfos";
import ProfileUpdate from "../containers/ProfileUpdate";
import EventInterestedUsers from "../containers/EventInterestedUsers";
import Post from "../containers/Post";
import Profile from "../containers/Profile";
import SelectTemplate from "../containers/SelectTemplate";
import SelectTemplateDate from "../containers/SelectTemplateDate";
import CameraScreen from "../containers/CameraScreen";
import Chat from "../containers/Chat";
import ImageZoom from "../containers/ImgZoom";
import Youtube from "../containers/Youtube";
import BottomTabNavigator from "./BottomTabsNavigator";
import { BUSINESS_NAME } from "../config/constants";
import commonStyles, {
  COLOR2,
  COLOR5,
  WHITE_COLOR
} from "../styles/commonStyles";
import { t } from "../services/i18n";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { RESET_POST, CLEAR_PUBLISH, LOGOUT } from "../actions/types";
import CommunityDetails from "../containers/CommunityDetails";
import { DEFAULT_HEADER_STYLE, getHeaderLeftBack } from "./CommonNavigation";

const Stack = createStackNavigator();

function getTemplateHeaderStyle(displayPreview) {
  if (displayPreview) {
    return {
      backgroundColor: COLOR5
    };
  }
  return DEFAULT_HEADER_STYLE;
}

function getTemplateHeaderRight(onClickPreview, displayPreview) {
  const {
    flex1,
    bgWhite,
    bgColor2,
    justifyContentCenter,
    alignItemsCenter
  } = commonStyles;

  return () => (
    <TouchableOpacity style={flex1} onPress={onClickPreview}>
      <View
        style={[
          displayPreview ? bgWhite : bgColor2,
          alignItemsCenter,
          justifyContentCenter,
          {
            height: "100%",
            width: 56,
            paddingBottom: 3
          }
        ]}
      >
        <MaterialCommunityIcons
          name="eye-check"
          color={displayPreview ? COLOR2 : WHITE_COLOR}
          size={28}
        />
      </View>
    </TouchableOpacity>
  );
}

export const createRootAppStackNavigator = ({
  dispatch,
  user,
  accessDenied,
  isFetchingError,
  navigation,
  onClickPreview,
  displayPreview,
  communityDetails
}) => {
  return (
    <Stack.Navigator
      screenOptions={{
        title: BUSINESS_NAME,
        headerTitleAlign: "left",
        headerStyle: DEFAULT_HEADER_STYLE,
        headerTintColor: WHITE_COLOR,
        headerTitleStyle: {
          fontFamily: "fontBold",
          textTransform: "uppercase"
        }
      }}
    >
      {Boolean(user && !isFetchingError) ? (
        user.needUpdate ? (
          <>
            <Stack.Screen
              name="ProfileUpdateScreen"
              component={ProfileUpdate}
              options={{
                title: t("menu:profile"),
                headerLeft: getHeaderLeftBack(navigation, {
                  callback: () =>
                    dispatch({
                      type: LOGOUT
                    })
                })
              }}
            />
            <Stack.Screen
              name="CameraScreen"
              component={CameraScreen}
              options={{ headerShown: false }}
            />
          </>
        ) : (
          <>
            <Stack.Screen name="TabScreen" component={BottomTabNavigator} />
            <Stack.Screen
              name="ProfileUpdateScreen"
              component={ProfileUpdate}
              options={{
                title: t("menu:profile"),
                headerLeft: getHeaderLeftBack(navigation)
              }}
            />
            <Stack.Screen
              name="CameraScreen"
              component={CameraScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="SelectActivityScreen"
              component={SelectTemplate.SelectActivity}
              initialParams={{ communityUri: communityDetails?.customUri }}
              options={{
                title: t("menu:instantsharing"),
                headerLeft: getHeaderLeftBack(navigation)
              }}
            />
            <Stack.Screen
              name="SelectProposalScreen"
              component={SelectTemplate.SelectProposal}
              initialParams={{ communityUri: communityDetails?.customUri }}
              options={{
                title: t("menu:needservice"),
                headerLeft: getHeaderLeftBack(navigation)
              }}
            />
            <Stack.Screen
              name="SelectTemplateScreen"
              component={SelectTemplate.SelectTemplate}
              initialParams={{ communityUri: communityDetails?.customUri }}
              options={{
                title: t("menu:templates"),
                headerLeft: getHeaderLeftBack(navigation)
              }}
            />
            <Stack.Screen
              name="SelectTemplateDateScreen"
              component={SelectTemplateDate}
              initialParams={{ communityUri: communityDetails?.customUri }}
              options={({ route }) => {
                return {
                  headerTitle: route?.params?.name,
                  headerLeft: getHeaderLeftBack(navigation, {
                    callback: () => dispatch({ type: CLEAR_PUBLISH })
                  }),
                  headerRight: getTemplateHeaderRight(
                    onClickPreview,
                    displayPreview
                  ),
                  headerStyle: getTemplateHeaderStyle(displayPreview)
                };
              }}
            />
            <Stack.Screen
              name="PostScreen"
              component={Post}
              initialParams={{ communityUri: communityDetails?.customUri }}
              options={{
                title: t("menu:snapshot"),
                headerLeft: getHeaderLeftBack(navigation, {
                  callback: () => dispatch({ type: RESET_POST })
                })
              }}
            />
            <Stack.Screen
              name="EventInterestedUsersScreen"
              component={EventInterestedUsers}
              initialParams={{ communityUri: communityDetails?.customUri }}
              options={{
                title: t("menu:post"),
                headerLeft: getHeaderLeftBack(navigation)
              }}
            />
            <Stack.Screen
              name="ProfileScreen"
              component={Profile}
              options={{
                title: t("menu:profile"),
                headerLeft: getHeaderLeftBack(navigation)
              }}
            />
            <Stack.Screen
              name="ProfileTeamScreen"
              component={Profile}
              options={{
                title: t("menu:profile"),
                headerLeft: getHeaderLeftBack(navigation)
              }}
            />
            <Stack.Screen
              name="GoodDealDetailsScreen"
              component={GoodDealDetails}
              initialParams={{ communityUri: communityDetails?.customUri }}
              options={() => {
                return {
                  title: t("menu:localLife"),
                  headerLeft: getHeaderLeftBack(navigation)
                };
              }}
            />
            <Stack.Screen
              name="adDetailsScreen"
              component={GoodDealDetails}
              initialParams={{ communityUri: communityDetails?.customUri }}
              options={() => {
                return {
                  title: t("menu:localLife"),
                  headerLeft: getHeaderLeftBack(navigation)
                };
              }}
            />
            <Stack.Screen
              name="CommunityDetailsScreen"
              component={CommunityDetails}
              initialParams={{ communityUri: communityDetails?.customUri }}
              options={{
                title: t("menu:camping"),
                headerLeft: getHeaderLeftBack(navigation)
              }}
            />
            <Stack.Screen
              name="ChatScreen"
              component={Chat}
              options={() => {
                return {
                  headerTitleStyle: {
                    textTransform: "none"
                  },
                  headerLeft: getHeaderLeftBack(navigation, {
                    // origin: "ChatTab"
                  })
                };
              }}
            />
            <Stack.Screen
              name="ChatTeamScreen"
              component={Chat}
              options={() => {
                return {
                  headerTitleStyle: {
                    textTransform: "none"
                  },
                  headerLeft: getHeaderLeftBack(navigation, {
                    // origin: "ChatTab"
                  })
                };
              }}
            />
            <Stack.Screen
              name="ChatGrpScreen"
              component={Chat}
              initialParams={{ communityUri: communityDetails?.customUri }}
              options={() => {
                return {
                  headerLeft: getHeaderLeftBack(navigation),
                  headerTitleStyle: {
                    textTransform: "none"
                  }
                };
              }}
            />
            <Stack.Screen
              name="ImageZoomScreen"
              component={ImageZoom}
              options={{
                title: t("menu:zoom"),
                headerLeft: getHeaderLeftBack(navigation)
              }}
            />
            <Stack.Screen
              name="YoutubeScreen"
              component={Youtube}
              options={{
                title: t("menu:youtube"),
                headerLeft: getHeaderLeftBack(navigation)
              }}
            />
            <Stack.Screen
              name="AppInfosScreen"
              component={AppInfos}
              options={{
                title: t("menu:about"),
                headerLeft: getHeaderLeftBack(navigation)
              }}
            />
            {/* <Stack.Screen
              name="CGUScreen"
              component={CGUReader}
              options={{
                title: t("menu:CGU"),
                headerLeft: getHeaderLeftBack(navigation)
              }}
            /> */}
            <Stack.Screen
              name="MaintenanceScreen"
              component={MaintenanceScreen}
              options={{ headerShown: false }}
            />
          </>
        )
      ) : accessDenied || isFetchingError ? (
        <>
          <Stack.Screen
            name="LandingScreen"
            component={LandingScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="AppInfosScreen"
            component={AppInfos}
            options={{
              title: t("menu:about"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          {/* <Stack.Screen
            name="CGUScreen"
            component={CGUReader}
            options={{
              title: t("menu:cgu"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          /> */}
          <Stack.Screen
            name="GoodDealDetailsScreen"
            component={GoodDealDetails}
            options={() => {
              return {
                title: t("menu:localLife"),
                headerLeft: getHeaderLeftBack(navigation)
              };
            }}
          />
          <Stack.Screen
            name="SelectTemplateDateScreen"
            component={SelectTemplateDate}
            options={({ route }) => {
              return {
                headerTitle: route?.params?.name,
                headerLeft: getHeaderLeftBack(navigation, {
                  callback: () => dispatch({ type: CLEAR_PUBLISH })
                }),
                headerRight: getTemplateHeaderRight(
                  onClickPreview,
                  displayPreview
                ),
                headerTitleStyle: {
                  textTransform: "none"
                },
                headerStyle: getTemplateHeaderStyle(displayPreview)
              };
            }}
          />
          <Stack.Screen
            name="EventDetailsScreen"
            component={EventDetails}
            options={{
              title: t("menu:post"),
              headerLeft: getHeaderLeftBack(navigation)
            }}
          />
          <Stack.Screen
            name="MaintenanceScreen"
            component={MaintenanceScreen}
            options={{ headerShown: false }}
          />
        </>
      ) : (
        <>
          <Stack.Screen
            name="LoadingScreen"
            component={LoadingScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="MaintenanceScreen"
            component={MaintenanceScreen}
            options={{ headerShown: false }}
          />
        </>
      )}
    </Stack.Navigator>
  );
};
