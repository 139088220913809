import {
  SET_GLOBAL_INFORMATIONS,
  FETCH_GET_GLOBAL_INFORMATIONS,
  API_START,
  API_END,
  SET_GLOBAL_INFORMATIONS_FAILURE,
  SET_LANG,
  SET_GLOBAL_INFORMATIONS_VERSIONS,
  FETCH_GET_GLOBAL_INFORMATIONS_VERSION,
  RESET_DATA,
  SET_PROFILE_DETAILS,
  SET_GLOBAL_INFORMATIONS_VERSIONS_FAILURE,
  SET_INITIAL_URL,
  CLEAR_INITIAL_URL
} from "../actions/types";
import { Platform } from "react-native";
import { Record } from "immutable";
import { parse } from "search-params";
import i18n from "../services/i18n";

const InitialState = new Record({
  isFetching: false,
  datetimeDiff: 0,
  clientVersion: null,
  serverVersion: null,
  dropDownAlertWarn: null,
  dropDownAlertError: null,
  accessDenied: false,
  obsoleteversion: false,
  needupdate: false,
  forceupdate: false,
  lang: "en",
  isFetchingError: false,
  initialUrl: null,
  initUrl: null
});

const initialState = new InitialState();

export default function globalInformationsReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case API_START: {
      switch (action.payload) {
        case FETCH_GET_GLOBAL_INFORMATIONS_VERSION:
          return state
            .set("isFetching", true)
            .set("isFetchingError", false)
            .set("dropDownAlertError", null)
            .set("dropDownAlertWarn", null)
            .set("dropDownAlertInfo", null);

        case FETCH_GET_GLOBAL_INFORMATIONS:
          return state
            .set("isFetching", true)
            .set("isFetchingError", false)
            .set("dropDownAlertError", null)
            .set("dropDownAlertWarn", null)
            .set("dropDownAlertInfo", null)
            .set("accessDenied", false);

        default:
          // Do nothing
          break;
      }
      break;
    }

    case SET_INITIAL_URL: {
      let accessDenied = false;
      let match;
      let lg = state.lang;
      if (
        (match = action.value.match(/^(http:\/\/|https:\/\/|www.)\S+\?(\S+)$/))
      ) {
        const params = match[2];
        const { offline, lang } = parse(params);
        if (offline && (offline == "true" || offline == "1")) {
          accessDenied = true;
        }
        if (lang && (lang == "en" || lang == "fr")) {
          (async () => {
            await i18n.setLanguage(lang);
          })();
          lg = lang;
        }
      }
      return state
        .set("initUrl", action.value)
        .set("initialUrl", action.value)
        .set("accessDenied", accessDenied)
        .set("lang", lg);
    }

    case CLEAR_INITIAL_URL:
      return state.set("initialUrl", null);
    case RESET_DATA: {
      return initialState
        .set("lang", state.lang)
        .set("serverVersion", state.serverVersion)
        .set("initUrl", state.initUrl);
    }

    case SET_GLOBAL_INFORMATIONS_VERSIONS:
    case SET_GLOBAL_INFORMATIONS: {
      const now = Date.now();
      const serverDt = action.payload.serverDateTime;
      let datetimeDiff = now - serverDt;
      if (datetimeDiff > 0 && datetimeDiff < 2000) {
        datetimeDiff = 0;
      }
      return state
        .set("serverVersion", action.payload.serverVersion)
        .set("clientVersion", action.payload.clientVersion)
        .set("datetimeDiff", datetimeDiff)
        .set("needupdate", action.payload.needupdate)
        .set("forceupdate", action.payload.forceupdate);
    }

    case SET_PROFILE_DETAILS:
      return state.set("accessDenied", false);

    case SET_GLOBAL_INFORMATIONS_VERSIONS_FAILURE:
      return state.set("isFetchingError", true);

    case SET_GLOBAL_INFORMATIONS_FAILURE:
      if (
        (action.payload.request &&
          action.payload.request._response &&
          (action.payload.request._response ===
            "Could not connect to the server." ||
            action.payload.request._response === "The request timed out." ||
            action.payload.request._response.startsWith(
              "Failed to connect to"
            ))) ||
        action.payload?.message === "Network Error"
      ) {
        return state
          .set("dropDownAlertError", "networkerror")
          .set("isFetchingError", true);
      }
      if (action.payload.response && action.payload.response.status === 403) {
        return state.set("accessDenied", true);
      }
      if (action.payload.response && action.payload.response.status === 411) {
        // It's minor or major version
        return state
          .set(
            "dropDownAlertWarn",
            Platform.OS === "ios"
              ? "obsoleteversionios"
              : "obsoleteversionandroid"
          )
          .set("obsoleteversion", true);
      }
      return state
        .set("dropDownAlertError", "unexpectederror")
        .set("isFetchingError", true);

    case SET_LANG:
      return state.set("lang", action.lang);

    case API_END: {
      switch (action.payload) {
        case FETCH_GET_GLOBAL_INFORMATIONS:
        case FETCH_GET_GLOBAL_INFORMATIONS_VERSION:
          return state.set("isFetching", false);
        default:
          // Do nothing
          break;
      }
      break;
    }

    default:
      return state;
  }
  return state;
}
