import * as Localization from "expo-localization";

const languageDetector = {
  type: "languageDetector",
  async: true,
  detect: callback => {
    return (
      Localization.getLocalizationAsync()

        // We will get back a string like "fr-FR". We
        // return a string like "fr" to match our language
        // files.
        .then(lng => {
          const lang = lng.locale.split("-")[0];
          callback(lang);
        })
    );
  },
  init: () => {},
  cacheUserLanguage: () => {}
};

export default languageDetector;
