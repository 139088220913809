import React, { useState, useCallback } from "react";
import { View } from "react-native";
import YoutubePlayer from "react-native-youtube-iframe";
import commonStyles, { SCREEN_WIDTH } from "../styles/commonStyles";

const Youtube = ({ route }) => {
  const { videoId } = route.params;
  const [playing, setPlaying] = useState(true);

  const { w100p, h100p, justifyContentCenter, bgColor1 } = commonStyles;

  const onStateChange = useCallback(state => {
    if (state === "ended") {
      setPlaying(false);
    }
  }, []);

  return (
    <View style={[w100p, h100p, justifyContentCenter, bgColor1]}>
      <YoutubePlayer
        height={(SCREEN_WIDTH * 9) / 16}
        width={SCREEN_WIDTH}
        play={playing}
        videoId={videoId}
        onChangeState={onStateChange}
      />
    </View>
  );
};

export default Youtube;
