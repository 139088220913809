import {
  API_START,
  API_END,
  FETCH_GET_EVENT_DETAILS,
  SET_GET_EVENT_DETAILS_RESULT
} from "../actions/types";

const initialState = {
  isFetching: false,
  event: null,
  user: null
};

export default function eventReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_GET_EVENT_DETAILS) {
        return {
          ...state,
          isFetching: true
        };
      }
      break;

    case SET_GET_EVENT_DETAILS_RESULT:
      return {
        ...state,
        event: Object.values(action.entities.events)[0],
        user: Object.values(action.entities.users)[0]
      };

    case API_END:
      if (action.payload === FETCH_GET_EVENT_DETAILS) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;

    default:
      return state;
  }

  return state;
}
