import Constants from "expo-constants";
import React, { Component } from "react";
// Workaround use react-native Image, react-native-expo-image-cache crashed in case of ios
import {
  ActivityIndicator,
  Animated,
  Easing,
  FlatList,
  Image,
  LayoutAnimation,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MaterialCommunityIcons, Entypo } from "@expo/vector-icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchGetGoodDeals } from "../actions/gooddeal";
import {
  GOODDEALS_ON_TOP,
  CATEGORIES_FILTER,
  SHOW_QR_CODE_SCANNER_MODAL,
  HIDE_QR_CODE_SCANNER_MODAL,
  SET_GOODDEAL_ON_CLICK_FILTER,
  SET_GOODDEAL_DISPLAY_FILTER_OPTION
} from "../actions/types";
import { getImage } from "../assets/Images";
import { getTranslatedProperty, t } from "../services/i18n";
import commonStyles, {
  COLOR2,
  SCREEN_WIDTH,
  SCREEN_HEIGHT,
  COLOR4,
  ORANGE_COLOR
} from "../styles/commonStyles";
import LabelSelect from "../components/LabelSelect";
import { Button, CheckBox } from "react-native-elements";
import QrCodeScannerModal from "../components/QrCodeScannerModal";

class GoodDeals extends Component {
  state = {
    bottomPosition: new Animated.Value(-60),
    allChecked: true,
    menuOpen: false
  };

  getGoodDeals = (page, filter) => {
    const { dispatch, fetchGetGoodDeals } = this.props;
    dispatch({
      type: CATEGORIES_FILTER,
      payload: filter
    });
    fetchGetGoodDeals(page, filter);
  };

  onClickFilter = () => {
    const { displayFilterOptions } = this.props;
    LayoutAnimation.easeInEaseOut();
    this.props.dispatch({
      type: SET_GOODDEAL_DISPLAY_FILTER_OPTION,
      payload: !displayFilterOptions
    });
  };

  async componentDidMount() {
    this.props.dispatch({
      type: SET_GOODDEAL_ON_CLICK_FILTER,
      payload: this.onClickFilter
    });

    const filter = await AsyncStorage.getItem("gooddealfilter");
    this.getGoodDeals(1, filter ? JSON.parse(filter) : []);
    if (filter) {
      this.props.dispatch({
        type: SET_GOODDEAL_DISPLAY_FILTER_OPTION,
        payload: true
      });
      setTimeout(this.hideFilter, 1000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchGetGoodDeals, filter, reload, goodDeals } = this.props;

    // When disconnected, we reload gooddeals asking first page
    if (reload && reload > prevProps.reload) {
      fetchGetGoodDeals(1, filter.size ? Array.from(filter) : null);
    }

    if (
      Object.entries(goodDeals).length >
      Object.entries(prevProps.goodDeals).length
    ) {
      this.onEndReachedCalledDuringMomentum = false;
    }
  }

  _displayLoading() {
    if (this.props.isFetching) {
      return (
        <View style={styles.loading_container}>
          <ActivityIndicator size="large" color={COLOR2} />
        </View>
      );
    }
  }

  handleScroll = event => {
    const y = event.nativeEvent.contentOffset.y;
    const { bottomPosition } = this.state;
    if (y > 140) {
      if (bottomPosition.__getValue() === -60) {
        Animated.timing(bottomPosition, {
          toValue: 25,
          duration: 400,
          easing: Easing.linear
        }).start();
        if (this.props.goodDealsOnTop) {
          this.props.dispatch({
            type: GOODDEALS_ON_TOP,
            value: false
          });
        }
      }
    } else if (bottomPosition.__getValue() !== -60) {
      Animated.timing(bottomPosition, {
        toValue: -60,
        duration: 400,
        easing: Easing.linear
      }).start();
      if (!this.props.goodDealsOnTop) {
        this.props.dispatch({
          type: GOODDEALS_ON_TOP,
          value: true
        });
      }
    }
  };

  hideFilter = () => {
    LayoutAnimation.easeInEaseOut();
    this.props.dispatch({
      type: SET_GOODDEAL_DISPLAY_FILTER_OPTION,
      payload: false
    });
  };

  selectConfirm = async categoriesIds => {
    if (categoriesIds.size) {
      let { filter } = this.props;
      filter = [...filter, ...categoriesIds];
      await AsyncStorage.setItem("gooddealfilter", JSON.stringify(filter));
      this.getGoodDeals(1, filter);

      setTimeout(this.hideFilter, 1000);
    }
  };

  deleteItem = async categoryId => {
    const { filter } = this.props;
    filter.delete(categoryId);

    if (filter.size) {
      await AsyncStorage.setItem(
        "gooddealfilter",
        JSON.stringify(Array.from(filter))
      );
    } else {
      await AsyncStorage.removeItem("gooddealfilter");
    }
    this.getGoodDeals(1, filter.size ? Array.from(filter) : []);
  };

  renderLabelSelectLabels() {
    const components = [];
    let { filter, categories } = this.props;

    for (const categoryId of filter) {
      components.push(
        <LabelSelect.Label
          key={categoryId}
          data={categories[categoryId]}
          onCancel={() => {
            this.deleteItem(categoryId);
          }}
        >
          {getTranslatedProperty(categories[categoryId], "name")}
        </LabelSelect.Label>
      );
    }

    return components;
  }

  clearFilter = async () => {
    await AsyncStorage.removeItem("gooddealfilter");
    this.getGoodDeals(1, []);
    setTimeout(this.hideFilter, 1000);
  };

  showQrCodeScannerModal = () => {
    this.props.dispatch({
      type: SHOW_QR_CODE_SCANNER_MODAL
    });
  };

  onQrCodeScannerModalClosed = () => {
    this.props.dispatch({
      type: HIDE_QR_CODE_SCANNER_MODAL
    });
  };

  render() {
    const {
      bgColor1,
      bgColor5,
      bgWhite,
      row,
      w100p,
      font,
      alignItemsCenter,
      justifyContentCenter,
      spaceBetween,
      mv10,
      shadowGrey,
      bgLightgrey,
      rounded30,
      rounded22,
      mv5,
      mr5,
      p10,
      pv10,
      ph10,
      h60,
      mb10,
      h44,
      fs14,
      fs20,
      fontBold,
      darkgrey,
      color5,
      ml10,
      mt10,
      rounded5,
      positionAbsolute
    } = commonStyles;

    const { navigate } = this.props.navigation;
    const {
      categories,
      filter,
      goodDeals,
      hasNextPage,
      nextPage,
      fetchGetGoodDeals,
      displayFilterOptions,
      inGoodDealsScreen
    } = this.props;
    const { bottomPosition } = this.state;

    return (
      <Animated.View style={[StyleSheet.absoluteFillObject, bgColor1]}>
        <QrCodeScannerModal onClosed={this.onQrCodeScannerModalClosed} />
        {displayFilterOptions && (
          <View style={[bgWhite, w100p, pv10, shadowGrey, ph10]}>
            <Text style={[ml10, mb10, fontBold, darkgrey]}>
              {t("filter:display")}
            </Text>
            <CheckBox
              title={t("filter:all")}
              textStyle={[color5, fontBold]}
              checkedIcon="dot-circle-o"
              uncheckedIcon="circle-o"
              checked={filter.size === 0}
              onPress={this.clearFilter}
            />
            <CheckBox
              title={t("filter:selection")}
              textStyle={[color5, fontBold]}
              checkedIcon="dot-circle-o"
              uncheckedIcon="circle-o"
              checked={filter.size > 0}
              onPress={() => this.refs.select.openModal()}
            />
            <LabelSelect
              title={t("filter:display")}
              ref="select"
              style={styles.labelSelect}
              hobbiesIds={filter}
              onConfirm={this.selectConfirm}
            >
              {this.renderLabelSelectLabels()}
              {Object.values(categories)
                .filter(item => !filter.has(item._id))
                .map(category => (
                  <LabelSelect.ModalItem
                    key={category._id}
                    data={category}
                    hobbiesIds={categories}
                  >
                    {getTranslatedProperty(category, "name")}
                  </LabelSelect.ModalItem>
                ))}
            </LabelSelect>
          </View>
        )}
        <FlatList
          ref={list => (this.listRef = list)}
          showsVerticalScrollIndicator={false}
          data={goodDeals}
          contentContainerStyle={{
            paddingBottom: 90 + Constants.statusBarHeight
          }}
          renderItem={({ item }) => (
            <View style={[w100p, bgWhite, mv10, p10, shadowGrey, mv5]}>
              <View
                style={[
                  bgLightgrey,
                  row,
                  h60,
                  rounded30,
                  alignItemsCenter,
                  p10,
                  spaceBetween
                ]}
              >
                <View style={[row, alignItemsCenter]}>
                  <View
                    style={[
                      h44,
                      rounded22,
                      {
                        width: 44,
                        backgroundColor: categories[item.categoryId].bgColor
                          ? categories[item.categoryId].bgColor
                          : COLOR4
                      },
                      mr5,
                      justifyContentCenter,
                      alignItemsCenter
                    ]}
                  >
                    <Image
                      style={{
                        width: 30,
                        height: 30,
                        position: "absolute",
                        tintColor: "white"
                      }}
                      source={getImage(categories[item.categoryId].picto)}
                    />
                    <Image
                      style={{
                        width: 30,
                        height: 30,
                        position: "absolute",
                        opacity: 0
                      }}
                      source={getImage(categories[item.categoryId].picto)}
                    />
                  </View>
                  <Text style={[fs14, fontBold]} numberOfLines={1}>
                    {getTranslatedProperty(categories[item.categoryId], "name")}
                  </Text>
                </View>
                <Button
                  icon={
                    <MaterialCommunityIcons
                      name="dots-horizontal"
                      size={30}
                      color="white"
                    />
                  }
                  buttonStyle={[bgColor5, h44, rounded22]}
                  onPress={() =>
                    navigate("adDetailsScreen", {
                      adUri: item.customUri
                    })
                  }
                />
              </View>
              <TouchableOpacity
                onPress={() => {
                  navigate("adDetailsScreen", {
                    adUri: item.customUri
                  });
                }}
              >
                <Image
                  source={{ uri: item.photoUri }}
                  style={[mt10, rounded5, { height: SCREEN_HEIGHT / 3 }, w100p]}
                />
                {Boolean(item.promotion) && (
                  <View style={[styles.promotionView]}>
                    <Text style={[fs14, font]}>
                      {getTranslatedProperty(item, "promotion")}
                    </Text>
                  </View>
                )}
              </TouchableOpacity>
              <View style={styles.titleView}>
                <View style={[spaceBetween]}>
                  <Text style={[fs20, mb10, font]}>
                    {getTranslatedProperty(item, "title")}
                  </Text>
                </View>
                <View>
                  <Text style={[font, mb10]} numberOfLines={3}>
                    {getTranslatedProperty(item, "description")}
                  </Text>
                </View>
              </View>
            </View>
          )}
          keyExtractor={item => item._id}
          onScroll={this.handleScroll}
          onEndReachedThreshold={0.5}
          onEndReached={() => {
            if (
              hasNextPage &&
              !this.onEndReachedCalledDuringMomentum &&
              inGoodDealsScreen
            ) {
              this.onEndReachedCalledDuringMomentum = true;
              fetchGetGoodDeals(
                nextPage,
                filter.size ? Array.from(filter) : null
              );
            }
          }}
        />
        {this._displayLoading()}
        <Animated.View
          style={[
            positionAbsolute,
            {
              alignSelf: "center",
              bottom: bottomPosition
            }
          ]}
        >
          <TouchableOpacity
            onPress={() => {
              this.listRef.scrollToOffset({
                x: 0,
                y: 0,
                animated: true
              });
            }}
            style={[
              bgColor5,
              {
                height: 50,
                width: 50,
                borderRadius: 25
              },
              justifyContentCenter,
              alignItemsCenter,
              ph10
            ]}
          >
            <Entypo name="chevron-up" color="white" size={45} />
          </TouchableOpacity>
        </Animated.View>
      </Animated.View>
    );
  }
}

const mapStateToProps = state => {
  const {
    isFetching,
    gooddeals,
    nextPage,
    hasNextPage,
    reload,
    goodDealsOnTop,
    filter,
    displayFilterOptions,
    inGoodDealsScreen
  } = state.gooddealReducer;

  return {
    isFetching,
    nextPage,
    hasNextPage,
    goodDeals: Object.values(gooddeals),
    reload,
    categories: state.entitiesReducer.goodDealsCategories,
    goodDealsOnTop,
    filter: new Set(filter),
    displayFilterOptions,
    inGoodDealsScreen
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators({ fetchGetGoodDeals }, dispatch);
  return { ...actions, dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoodDeals);

const styles = StyleSheet.create({
  loading_container: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 100,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center"
  },
  titleView: {
    marginTop: 10,
    width: SCREEN_WIDTH - 60,
    marginRight: "auto",
    marginLeft: "auto"
  },
  promotionView: {
    height: 28,
    position: "absolute",
    bottom: 10,
    right: 10,
    backgroundColor: ORANGE_COLOR,
    borderRadius: 14,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 10
  },
  labelSelect: {
    width: "100%",
    marginTop: 5,
    marginBottom: 20,
    padding: 5,
    borderWidth: 1,
    borderRadius: 6,
    borderStyle: "dashed",
    borderColor: COLOR2,
    backgroundColor: "#fafafa"
  }
});
