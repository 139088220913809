import React from "react";
import {
  ActivityIndicator,
  FlatList,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Avatar, Badge, Button } from "react-native-elements";
import { fetchGetChatEvents } from "../actions/chat";
import commonStyles, { WHITE_COLOR, COLOR2 } from "../styles/commonStyles";
import { t, getTranslatedProperty } from "../services/i18n";
import { RESET_AND_SUBSCRIBE, LOGOUT } from "../actions/types";
import { ROLE_GUEST } from "../config/constants";
import { getImage } from "../assets/Images";
import { useSelector, useDispatch } from "react-redux";
import { formatDateIntl } from "../utils/DateUtils";
import { Ionicons } from "@expo/vector-icons";

export default function ChatGroup(props) {
  const dispatch = useDispatch();
  const navigate = props?.navigation?.navigate;

  const {
    isFetchingDataEvents,
    reload,
    chatevents,
    liveevents,
    livetemplates
  } = useSelector(state => state.chatReducer);

  const { user } = useSelector(state => state.profileReducer);

  const { lang, datetimeDiff } = useSelector(
    state => state.globalInformationsReducer
  );

  React.useEffect(() => {
    if (user && user.role !== ROLE_GUEST) {
      dispatch(fetchGetChatEvents());
    }
  }, [reload]);

  const renderEvent = record => {
    const {
      bgWhite,
      row,
      w100p,
      font,
      flex1,
      alignItemsCenter,
      justifyContentCenter,
      p5,
      mv10,
      shadowGrey,
      bgLightgrey,
      bgColor2,
      darkgrey,
      color4,
      rounded30,
      rounded22,
      mv5,
      p10,
      pr10,
      h60,
      mb10,
      h44,
      fs10
    } = commonStyles;

    const { eventId, notRead } = record;
    const event = liveevents[eventId];
    const { dateAdded, templateId } = event;
    const template = livetemplates[templateId];
    const { picto, name, photoUri } = template;

    return (
      <View style={[w100p, bgWhite, mv10, p10, shadowGrey, mv5]}>
        <View
          style={[bgLightgrey, row, h60, rounded30, alignItemsCenter, pr10]}
        >
          <View>
            <Avatar
              rounded
              size={60}
              source={photoUri ? { uri: photoUri } : getImage(picto)}
              title={name.toUpperCase().substr(0, 2)}
              activeOpacity={0.7}
              onPress={() =>
                navigate("EventInterestedUsersScreen", {
                  id: eventId
                })
              }
            />
            {Boolean(notRead) && (
              <Badge
                status="error"
                value={notRead}
                containerStyle={{ position: "absolute", top: -4, right: -4 }}
              />
            )}
          </View>
          <View style={[justifyContentCenter, flex1, p5]}>
            <TouchableOpacity
              onPress={() =>
                navigate("ChatGrpScreen", {
                  eventId
                })
              }
            >
              <Text style={[font, darkgrey, { fontSize: 15 }, mb10]}>
                {getTranslatedProperty(template, "name")}
              </Text>
            </TouchableOpacity>
            <Text style={[font, color4, fs10]}>
              {formatDateIntl(lang, dateAdded, datetimeDiff)}
            </Text>
          </View>
          <Button
            icon={
              <Ionicons
                name={"ios-chatbubbles"}
                size={32}
                color={WHITE_COLOR}
              />
            }
            // iconContainerStyle={{ marginBottom: 5 }}
            buttonStyle={[bgColor2, h44, rounded22]}
            onPress={() =>
              navigate("ChatGrpScreen", {
                eventId
              })
            }
          />
        </View>
      </View>
    );
  };

  const {
    bgColor1,
    flex1,
    alignItemsCenter,
    justifyContentCenter,
    w100p,
    font,
    bgColor2,
    bgWhite,
    rounded5,
    darkgrey,
    mh10,
    p10,
    shadowGrey,
    textCenter,
    h44,
    rounded22,
    white,
    fontBold
  } = commonStyles;

  return (
    <View style={[flex1, bgColor1]}>
      {user && user.role === ROLE_GUEST ? (
        <View style={[flex1, justifyContentCenter, alignItemsCenter]}>
          <View style={[bgWhite, mh10, rounded5, shadowGrey, alignItemsCenter]}>
            <Text style={[font, darkgrey, textCenter, p10]}>
              {t("chat:conversationgroupguest")}
            </Text>
            <Button
              buttonStyle={[bgColor2, h44, rounded22, { width: 120 }]}
              containerStyle={[p10]}
              activeOpacity={0.8}
              title={t("button:signup")}
              onPress={() =>
                dispatch({
                  type: LOGOUT,
                  value: RESET_AND_SUBSCRIBE
                })
              }
              titleStyle={[white, fontBold, { fontSize: 16 }]}
            />
          </View>
        </View>
      ) : isFetchingDataEvents ? (
        <ActivityIndicator
          style={[flex1, justifyContentCenter]}
          size="large"
          color={COLOR2}
        />
      ) : (
        <FlatList
          style={[flex1, w100p]}
          data={Object.values(chatevents)}
          renderItem={({ item }) => renderEvent(item)}
          keyExtractor={item => item._id}
        />
      )}
    </View>
  );
}
