import React, { Component } from "react";
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { t } from "../services/i18n";
import { FontAwesome5 } from "@expo/vector-icons";
import commonStyles, {
  DARKGREY_COLOR,
  SCREEN_WIDTH
} from "../styles/commonStyles";
import MapLink from "./MapLink";
import MapView from "react-native-maps";

export default class MapRoute extends Component {
  setVisibleTrue = () => {
    this.refs.mapLink.setVisibleTrue();
  };

  render() {
    const { font } = commonStyles;
    const { latitude, longitude, title, description } = this.props;
    return (
      <View>
        <MapLink ref={"mapLink"} {...this.props} />
        <MapView
          style={styles.googleMap}
          initialRegion={{
            latitude,
            longitude,
            latitudeDelta: 0.0043,
            longitudeDelta: 0.0034
          }}
        >
          <MapView.Marker
            coordinate={{
              latitude,
              longitude
            }}
            title={title}
            description={description}
          />
        </MapView>

        <TouchableOpacity
          style={{
            width: 200,
            zIndex: 999,
            position: "absolute",
            bottom: 60,
            right: SCREEN_WIDTH / 2 - 100
          }}
          onPress={() => this.refs.mapLink.setVisibleTrue()}
        >
          <FontAwesome5.Button
            name="route"
            size={30}
            color={DARKGREY_COLOR}
            backgroundColor={"rgba(255, 255, 255, 0.4)"}
            borderRadius={25}
            onPress={() => this.refs.mapLink.setVisibleTrue()}
          >
            <Text style={[font]}>{t("infos:clickmap")}</Text>
          </FontAwesome5.Button>
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  googleMap: {
    marginHorizontal: 20,
    height: 300,
    marginBottom: 20,
    borderRadius: 5
  }
});
