import React, { Component } from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchGetEventDetails } from "../actions/event";
import commonStyles, { COLOR2 } from "../styles/commonStyles";
import LiveEvent from "../components/LiveEvent";
import { GROUP_ID_PUBLISH } from "../config/constants";

class EventDetails extends Component {
  componentDidMount() {
    const { fetchGetEventDetails, route } = this.props;
    if (route.params) {
      const { id } = route.params;
      fetchGetEventDetails(id);
    }
  }

  render() {
    const { bgColor1, flex1, justifyContentCenter } = commonStyles;

    const {
      isFetching,
      event,
      user,
      dispatch,
      datetimeDiff,
      lang
    } = this.props;

    const { dateUpdated: date, _id } = event || {};

    const props = {
      dispatch,
      datetimeDiff,
      lang,
      user
    };
    const itemProps = {
      // ...event,
      _id,
      date
    };

    return (
      <View style={[flex1, bgColor1]}>
        {isFetching ? (
          <ActivityIndicator
            style={[flex1, justifyContentCenter]}
            size="large"
            color={COLOR2}
          />
        ) : (
          <ScrollView>
            <LiveEvent
              {...props}
              {...itemProps}
              event={event}
              from={user}
              groupId={GROUP_ID_PUBLISH}
              isDetails={true}
            />
          </ScrollView>
        )}
      </View>
    );
  }
}

const mapStateToProps = state => {
  const { isFetching, event, user } = state.eventReducer;
  const { lang, datetimeDiff } = state.globalInformationsReducer;

  return {
    isFetching,
    event,
    lang,
    datetimeDiff,
    user
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators({ fetchGetEventDetails }, dispatch);
  return { ...actions, dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
