import React, { Component } from "react";
import { Image, Text, View } from "react-native";
import { getImage } from "../assets/Images";
import { t } from "../services/i18n";
import commonStyles from "../styles/commonStyles";

class Maintenance extends Component {
  render() {
    const {
      bgColor1,
      flex1,
      alignItemsCenter,
      justifyContentCenter,
      font,
      mt10,
      color5,
      textCenter,
      fs20,
      p20
    } = commonStyles;

    return (
      <View
        style={[p20, flex1, bgColor1, alignItemsCenter, justifyContentCenter]}
      >
        <Image source={getImage("maintenance")} />
        <Text style={[mt10, font, color5, fs20, textCenter]}>
          {t("login:maintenance")}
        </Text>
      </View>
    );
  }
}

export default Maintenance;
