import { wsConnected, wsDisconnected } from "../actions/ws";
import {
  WS_EVENT,
  SET_PROFILE_DETAILS,
  LOGOUT_RESULT,
  WS_DISCONNECTED,
  SET_TEMPLATES,
  NEW_CATEGORIES_TEMPLATES,
  SERVER_IN_MAINTENANCE
} from "../actions/types";
import { Platform } from "react-native";
import { normalize } from "normalizr";
import { categorie } from "../actions/template";
import { setTemplatesUpdateTimeStamp } from "../actions/live";
import { EVENT_PING } from "../config/constants";
import env from "../config/env";

const URL_WS = env.wsUrl;
const SOCKET_STATES = { CONNECTING: 0, OPEN: 1, CLOSING: 2, CLOSED: 3 };

let websocket;
let dispatchDisconnected = false;
let userLogged = false;
let timeoutId;

let tmpId = 0;

function ping() {
  if (websocket) {
    websocket.send(JSON.stringify({ event: EVENT_PING }));
  }
}

const wsMiddleware = ({ dispatch }) => next => action => {
  if (!action) {
    return;
  }
  if (Platform.OS === "android" && action && action.type === WS_DISCONNECTED) {
    next(action);
    return;
  }
  if (action.type === SET_PROFILE_DETAILS) {
    userLogged = true;
  } else if (
    action.type === LOGOUT_RESULT ||
    action.type === SERVER_IN_MAINTENANCE
  ) {
    userLogged = false;
    if (websocket) {
      websocket.close();
      websocket = null;
    }
  }

  if (userLogged && !websocket) {
    websocket = new WebSocket(URL_WS);
    Object.assign(websocket, {
      onopen: () => {
        dispatchDisconnected = false;
        dispatch(wsConnected());
        timeoutId = setInterval(ping, 40000);
      },
      onclose: () => {
        websocket = null;
        if (!dispatchDisconnected) {
          dispatch(wsDisconnected());
          dispatchDisconnected = true;
        }
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      },
      onerror: error => {
        console.log(`WS Error: ${error.message ? error.message : ""}`);
      },
      onmessage: event => {
        const data = JSON.parse(event.data);
        switch (data.type) {
          case NEW_CATEGORIES_TEMPLATES:
            const normalized = normalize(data.categoriesTemplates, [categorie]);
            setTemplatesUpdateTimeStamp(data.templatesUpdateTimeStamp);
            dispatch({
              type: SET_TEMPLATES,
              entities: normalized.entities
            });
            break;
          default:
            if (data && data.type !== "PING") {
              dispatch({
                type: WS_EVENT,
                payload: data
              });
            }
            break;
        }
      }
    });
  }

  if (action.meta && action.meta.websocket) {
    tmpId++;
    if (websocket.readyState === SOCKET_STATES.OPEN) {
      // Remove action metadata before sending
      const cleanAction = Object.assign({}, action, {
        meta: undefined,
        tmpId: "_" + tmpId
      });
      websocket.send(JSON.stringify(cleanAction));
    } else {
      setTimeout(() => {
        if (websocket && websocket.readyState === SOCKET_STATES.OPEN) {
          // Remove action metadata before sending
          const cleanAction = Object.assign({}, action, {
            meta: undefined,
            tmpId: "_" + tmpId
          });
          websocket.send(JSON.stringify(cleanAction));
        }
      }, 1000);
    }
    action._id = "_" + tmpId;
  }
  next(action);
};

export default wsMiddleware;
