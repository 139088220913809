import React, { Component } from "react";
import { TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchLoginWithFB, fetchSignUpWithFB } from "../actions/login";
import {
  SET_LOGIN_WITH_FB_RESULT,
  SET_DROP_DOWN_ALERT_ERROR
} from "../actions/types";
import commonStyles, { FB_COLOR } from "../styles/commonStyles";
import { FontAwesome5 } from "@expo/vector-icons";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

class FBLogin extends Component {
  responseFacebook = response => {
    const {
      dispatch,
      selectedCategory,
      fetchLoginWithFB,
      fetchSignUpWithFB,
      communityId,
      registerkey,
      lang
    } = this.props;
    if (!response || !response.accessToken) {
      dispatch({
        type: SET_DROP_DOWN_ALERT_ERROR,
        error: "facebookerror"
      });
      return;
    }
    dispatch({
      type: SET_LOGIN_WITH_FB_RESULT,
      data: response
    });
    if (selectedCategory) {
      fetchSignUpWithFB(
        communityId,
        response.accessToken,
        registerkey,
        response.email,
        lang
      );
    } else {
      fetchLoginWithFB(communityId, response.accessToken);
    }
  };

  render() {
    const { justifyContentCenter, alignItemsCenter } = commonStyles;

    return (
      <FacebookLogin
        appId={"331365397540120"}
        callback={this.responseFacebook}
        redirectUri={"https://ocmaping.digihapi.com"}
        fields="first_name,last_name,birthday,email,location,gender,picture.type(large)"
        render={renderProps => (
          <TouchableOpacity
            style={[
              {
                width: 54,
                height: 54,
                borderRadius: 27,
                backgroundColor: FB_COLOR
              },
              alignItemsCenter,
              justifyContentCenter
            ]}
            onPress={renderProps.onClick}
          >
            <FontAwesome5 name="facebook-f" size={26} color="white" />
          </TouchableOpacity>
        )}
        disableMobileRedirect={true}
      />
    );
  }
}

const mapStateToProps = state => {
  const {
    emailOrPseudo,
    registerkey,
    clearValidateFB,
    validFB,
    selectedCategory
  } = state.loginReducer;
  const { lang } = state.globalInformationsReducer;
  const { communityId } = state.communityReducer;

  return {
    clearValidateFB,
    validFB,
    registerkey,
    emailOrPseudo,
    selectedCategory,
    lang,
    communityId
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators(
    { fetchLoginWithFB, fetchSignUpWithFB },
    dispatch
  );
  return { ...actions, dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(FBLogin);
