import * as ImagePicker from "expo-image-picker";
import Clipboard from "expo-clipboard";
import React, { Component } from "react";
import {
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Text,
  View
} from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import commonStyles, {
  COLOR2,
  COLOR5,
  SCREEN_HEIGHT,
  isDesktop,
  isWeb,
  isiOS
} from "../styles/commonStyles";
import { t } from "../services/i18n";
import { Avatar, Input } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import ActionButton from "../components/actionButton/ActionButton";
import {
  SET_DROP_DOWN_ALERT_INFO,
  CLEAR_POST_PHOTO_DATA,
  SET_POST_PHOTO_DATA,
  SET_POST_DESCRIPTION_FR,
  SET_POST_DESCRIPTION_EN,
  SET_POST_TITLE_FR,
  SET_POST_TITLE_EN,
  VALIDATE_POST_INPUTS,
  CLEAR_POST_VALIDATE,
  RESET_POST,
  SET_POST_ENGLISH,
  SET_DROP_DOWN_ALERT_WARN,
  SET_READY_TO_COPY_URL,
  CLEAR_READY_TO_COPY_URL,
  SET_POST_VIDEO_ID
} from "../actions/types";
import { fetchPublishEvent } from "../actions/live";
import { getImage, getImageUri } from "../assets/Images";
import { ROLE_GUEST } from "../config/constants";
import {
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome
} from "@expo/vector-icons";
import { openBrowser } from "../utils/UrlUtil";
import YoutubePlayer from "react-native-youtube-iframe";

class Post extends Component {
  state = {
    menuOpen: false
  };

  componentWillUnmount() {
    this.props.dispatch({ type: RESET_POST });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      dispatch,
      navigation,
      fetchPublishEvent,
      descriptionErrorMsg,
      clearPostValidate,
      isValid,
      files,
      photoUri,
      title,
      description,
      lang,
      isFetching,
      youtubeVideoId
    } = this.props;
    // Cas des erreurs
    if (
      descriptionErrorMsg &&
      prevProps.descriptionErrorMsg !== descriptionErrorMsg
    ) {
      this.descriptionInput && this.descriptionInput.shake();
      this.descriptionInput && this.descriptionInput.focus();
    }
    // Validate inputs
    if (clearPostValidate && !prevProps.clearPostValidate) {
      dispatch({
        type: VALIDATE_POST_INPUTS
      });
    }

    if (isValid && !prevProps.isValid) {
      fetchPublishEvent({
        files,
        title,
        description,
        photoUri,
        youtubeVideoId,
        lang
      });
    }

    if (!isFetching && prevProps.isFetching && !descriptionErrorMsg) {
      navigation.goBack();
    }
  }

  _pickImage = async () => {
    if (!isWeb) {
      const {
        status
      } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        this.props.dispatch({
          type: SET_DROP_DOWN_ALERT_INFO,
          info: "photosinfo"
        });
        return;
      }
    }
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: "Images",
      quality: 0.1,
      base64: true
    });

    if (!result.cancelled) {
      if (isWeb) {
        const files = [];
        const name = "" + Date.now();
        files.push({
          name,
          base64: result.uri
        });
        this.props.dispatch({
          type: SET_POST_PHOTO_DATA,
          value: result.uri,
          files
        });
      } else {
        const files = [];
        const filename = result.uri.split("/").pop();
        const name = filename
          ? filename.substring(0, filename.lastIndexOf("."))
          : "" + Date.now();
        files.push({
          name,
          base64: "data:image/jpeg;base64," + result.base64
        });
        this.props.dispatch({
          type: SET_POST_PHOTO_DATA,
          value: result.uri,
          files
        });
      }
    }
  };

  renderActionsButtons = () => {
    const { fontBold, fs16 } = commonStyles;

    const { dispatch, navigation, isFetching, user } = this.props;

    const { menuOpen } = this.state;

    return (
      <ActionButton
        buttonColor={menuOpen ? COLOR5 : COLOR2}
        degrees={90}
        renderIcon={() => (
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={40}
            color="white"
          />
        )}
        onPressIn={() => this.setState({ menuOpen: true })}
        onReset={() => this.setState({ menuOpen: false })}
        disabled={isFetching}
      >
        <ActionButton.Item
          buttonColor={"red"}
          title={t("button:cancel")}
          onPress={() => {
            navigation.goBack();
          }}
          textStyle={[fontBold, fs16]}
        >
          <MaterialCommunityIcons name="cancel" size={36} color="white" />
        </ActionButton.Item>
        <ActionButton.Item
          buttonColor={COLOR2}
          title={t("button:post")}
          onPress={() =>
            user.role === ROLE_GUEST
              ? dispatch({
                  type: SET_DROP_DOWN_ALERT_WARN,
                  warn: "guestforbidden"
                })
              : dispatch({
                  type: CLEAR_POST_VALIDATE
                })
          }
          textStyle={[fontBold, fs16]}
        >
          <FontAwesome name="send" size={32} color="white" />
        </ActionButton.Item>
      </ActionButton>
    );
  };

  onFocus = async () => {
    const { dispatch, readyToCopyUrl } = this.props;
    if (readyToCopyUrl) {
      try {
        if (isDesktop) {
          const text = await Clipboard.getStringAsync();
          let match;
          const re = /^((http:\/\/|https:\/\/|www.)\S+?\.(?:jpe?g|gif|png|tiff|svg))$/;
          if (re.test(text)) {
            dispatch({
              type: SET_POST_PHOTO_DATA,
              value: text
            });
          } else if (
            (match = text.match(
              /^(https:\/\/www.|https:\/\/)youtube.com\/watch\?v=([-_a-zA-Z0-9]+)$/
            )) ||
            (match = text.match(
              /^(https:\/\/www.|https:\/\/)youtu.be\/([-_a-zA-Z0-9]+)$/
            ))
          ) {
            dispatch({
              type: SET_POST_VIDEO_ID,
              value: match[2]
            });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        dispatch({ type: CLEAR_READY_TO_COPY_URL });
      }
    }
  };

  handleOnChangeText = content => {
    const {
      dispatch,
      photoUri,
      lang,
      description,
      isEnglish,
      youtubeVideoId
    } = this.props;

    const regEnd = /^[-_a-zA-Z0-9][\n.!, ]$/;
    let oldContent = "";
    if (isEnglish) {
      if (lang && lang.en && lang.en.description) {
        oldContent = lang.en.description;
      }
    } else if (description) {
      oldContent = description;
    }
    if (
      content === "" ||
      photoUri ||
      youtubeVideoId ||
      oldContent.length > content.length ||
      content.length < 8 ||
      !regEnd.test(content.substring(content.length - 2))
    ) {
      return;
    }
    let match;
    if (
      (match = content.match(
        /^.*((http:\/\/|https:\/\/|www.)\S+?\.(?:jpe?g|gif|png|tiff|svg))[\n.!, ]$/
      ))
    ) {
      dispatch({
        type: SET_POST_PHOTO_DATA,
        value: match[1]
      });
    }
    if (
      (match = content.match(
        /^.*(https:\/\/www.|https:\/\/)youtube.com\/watch\?v=([-_a-zA-Z0-9]+)[\n.!, ]$/
      )) ||
      (match = content.match(
        /^.*(https:\/\/www.|https:\/\/)youtu.be\/([-_a-zA-Z0-9]+)[\n.!, ]$/
      ))
    ) {
      dispatch({
        type: SET_POST_VIDEO_ID,
        value: match[2]
      });
    }
  };

  render() {
    const {
      bgLightgrey,
      bgColor1,
      bgWhite,
      row,
      flex1,
      alignItemsCenter,
      justifyContentCenter,
      mt10,
      m10,
      p10,
      font,
      rounded5,
      w100p,
      shadowGrey,
      positionAbsolute,
      ml10,
      fs14
    } = commonStyles;

    const {
      dispatch,
      navigation,
      files,
      photoUri,
      lang,
      description,
      title,
      descriptionErrorMsg,
      isEnglish,
      youtubeVideoId
    } = this.props;
    return (
      <KeyboardAvoidingView
        {...(isiOS && { behavior: "padding" })}
        style={[flex1, bgColor1]}
      >
        <ScrollView contentContainerStyle={[flex1, { paddingBottom: 90 }]}>
          <View
            style={[
              w100p,
              p10,
              { paddingTop: 54 },
              bgWhite,
              mt10,
              shadowGrey,
              alignItemsCenter
            ]}
          >
            {isEnglish ? (
              <Input
                ref={input => (this.titleInputEn = input)}
                value={lang && lang.en && lang.en.title ? lang.en.title : ""}
                onChangeText={value =>
                  dispatch({ type: SET_POST_TITLE_EN, value })
                }
                onFocus={async () => {
                  await this.onFocus();
                }}
                placeholder={t("live:title")}
              />
            ) : (
              <Input
                ref={input => (this.titleInput = input)}
                value={title || ""}
                onChangeText={value =>
                  dispatch({ type: SET_POST_TITLE_FR, value })
                }
                onFocus={async () => {
                  await this.onFocus();
                }}
                placeholder={t("live:title")}
              />
            )}
            {isEnglish ? (
              <Input
                ref={input => (this.descriptionInputEn = input)}
                multiline
                value={
                  lang && lang.en && lang.en.description
                    ? lang.en.description
                    : ""
                }
                onChangeText={value => {
                  this.handleOnChangeText(value);
                  dispatch({ type: SET_POST_DESCRIPTION_EN, value });
                }}
                onFocus={async () => {
                  await this.onFocus();
                }}
                placeholder={t("live:postenglish")}
              />
            ) : (
              <Input
                ref={input => (this.descriptionInput = input)}
                multiline
                value={description || ""}
                onChangeText={value => {
                  this.handleOnChangeText(value);
                  dispatch({ type: SET_POST_DESCRIPTION_FR, value });
                }}
                onFocus={async () => {
                  await this.onFocus();
                }}
                errorMessage={descriptionErrorMsg}
                placeholder={t("live:postfrench")}
              />
            )}
            <Avatar
              containerStyle={[
                w100p,
                m10,
                { height: SCREEN_HEIGHT / 3 },
                youtubeVideoId && alignItemsCenter
              ]}
              size={SCREEN_HEIGHT / 3}
              placeholderStyle={[rounded5]}
              renderPlaceholderContent={
                <Image
                  style={[{ height: SCREEN_HEIGHT / 3 }, w100p, rounded5]}
                  source={getImage("placeholder")}
                />
              }
              source={
                Boolean(photoUri)
                  ? {
                      uri: photoUri
                    }
                  : undefined
              }
              overlayContainerStyle={[bgLightgrey, rounded5]}
              onPress={() => {
                Keyboard.dismiss();
                navigation.navigate("CameraScreen", {
                  type: SET_POST_PHOTO_DATA,
                  origin: "PostScreen",
                  showZoom: true
                });
              }}
            >
              {youtubeVideoId && (
                <YoutubePlayer
                  height={SCREEN_HEIGHT / 3}
                  width={((SCREEN_HEIGHT / 3) * 16) / 9}
                  videoId={youtubeVideoId}
                  play={false}
                />
              )}
            </Avatar>
            <ActionButton
              buttonColor={COLOR5}
              renderIcon={() => (
                <MaterialCommunityIcons
                  name="dots-horizontal"
                  size={34}
                  color="white"
                />
              )}
              size={44}
              verticalOrientation="down"
              offsetX={10}
              offsetY={20}
              degrees={90}
              spacing={10}
            >
              <ActionButton.Item
                buttonColor={COLOR2}
                title={t("button:camera")}
                onPress={() => {
                  Keyboard.dismiss();
                  navigation.navigate("CameraScreen", {
                    type: SET_POST_PHOTO_DATA,
                    origin: "PostScreen",
                    showZoom: true
                  });
                }}
                textStyle={font}
                textHeight={24}
              >
                <MaterialIcons name="camera-alt" size={34} color="white" />
              </ActionButton.Item>
              <ActionButton.Item
                buttonColor={COLOR2}
                title={t("button:albums")}
                onPress={this._pickImage}
                textStyle={font}
                textHeight={24}
              >
                <FontAwesome name="image" size={28} color="white" />
              </ActionButton.Item>
              {isDesktop && (
                <ActionButton.Item
                  buttonColor={COLOR2}
                  title={t("button:copyyoutube")}
                  onPress={() => {
                    dispatch({ type: SET_READY_TO_COPY_URL });
                    openBrowser(dispatch, "https://youtube.com");
                  }}
                  textStyle={font}
                  textHeight={24}
                >
                  <FontAwesome name="play" size={28} color="white" />
                </ActionButton.Item>
              )}
              {isDesktop && (
                <ActionButton.Item
                  buttonColor={COLOR2}
                  title={t("button:copygiphy")}
                  onPress={() => {
                    dispatch({ type: SET_READY_TO_COPY_URL });
                    openBrowser(dispatch, "https://giphy.com/search/camping");
                  }}
                  textStyle={font}
                  textHeight={24}
                >
                  <FontAwesome name="link" size={28} color="white" />
                </ActionButton.Item>
              )}
              <ActionButton.Item
                buttonColor={"white"}
                title={
                  isEnglish ? t("button:postfrench") : t("button:postenglish")
                }
                onPress={() =>
                  dispatch({ type: SET_POST_ENGLISH, payload: !isEnglish })
                }
                textStyle={font}
                textHeight={24}
              >
                <View style={[alignItemsCenter, justifyContentCenter]}>
                  {isEnglish ? (
                    <Image
                      style={{ width: 24, height: 24 }}
                      source={getImageUri("FR")}
                    />
                  ) : (
                    <Image
                      style={{ width: 24, height: 24 }}
                      source={getImageUri("GB")}
                    />
                  )}
                </View>
              </ActionButton.Item>
              {Boolean(files.length > 0 || youtubeVideoId) && (
                <ActionButton.Item
                  buttonColor={"red"}
                  title={t("button:delete")}
                  onPress={() => dispatch({ type: CLEAR_POST_PHOTO_DATA })}
                  textStyle={font}
                  textHeight={24}
                >
                  <MaterialCommunityIcons
                    name={"delete-forever"}
                    size={34}
                    color="white"
                  />
                </ActionButton.Item>
              )}
            </ActionButton>
            <View style={[positionAbsolute, { left: 20, top: 20 }]}>
              {isEnglish ? (
                <View style={[row, alignItemsCenter]}>
                  <Image
                    style={{ width: 24, height: 24 }}
                    source={getImageUri("GB")}
                  />
                  <Text style={[ml10, font, fs14]}>
                    {t("button:postenglish")}
                  </Text>
                </View>
              ) : (
                <View style={[row, alignItemsCenter]}>
                  <Image
                    style={{ width: 24, height: 24 }}
                    source={getImageUri("FR")}
                  />
                  <Text style={[ml10, font, fs14]}>
                    {t("button:postfrench")}
                  </Text>
                </View>
              )}
            </View>
          </View>
          {isiOS && this.renderActionsButtons()}
        </ScrollView>
        {!isiOS && this.renderActionsButtons()}
      </KeyboardAvoidingView>
    );
  }
}

const mapStateToProps = state => {
  const {
    isFetching,
    files,
    photoUri,
    title,
    description,
    descriptionErrorMsg,
    lang,
    clearPostValidate,
    isValid,
    isEnglish,
    readyToCopyUrl,
    youtubeVideoId
  } = state.postReducer;
  return {
    isFetching,
    files,
    photoUri,
    title,
    description,
    descriptionErrorMsg,
    lang,
    clearPostValidate,
    isValid,
    isEnglish,
    user: state.profileReducer.user,
    readyToCopyUrl,
    youtubeVideoId
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators({ fetchPublishEvent }, dispatch);
  return { ...actions, dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(Post);
