import moment from "moment";
import lang_FR from "../config/moment.fr";
export const fallback = "en";

export const supportedLocales = {
  en: {
    name: "English",
    translationFileLoader: () => require("../lang/en.json"),

    // en is default locale in Moment
    momentLocaleLoader: () => Promise.resolve()
  },
  fr: {
    name: "Français",
    translationFileLoader: () => require("../lang/fr.json"),
    momentLocaleLoader: () =>
      Promise.resolve(moment.updateLocale("fr", lang_FR))
  }
};

export const defaultNamespace = "live";

export const namespaces = [
  "live",
  "filter",
  "menu",
  "infos",
  "chat",
  "template",
  "button",
  "settings",
  "profile",
  "login",
  "weather",
  "country",
  "mapRoute",
  "alert"
];
