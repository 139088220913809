import React from "react";
import DropdownAlert from "react-native-dropdownalert";
import { NavigationContainer } from "@react-navigation/native";
import { ThemeProvider } from "react-native-elements";
import { COLOR2, GREEN_COLOR } from "../styles/commonStyles";
// Services
// import CheckUpdates from "../services/CheckUpdates";
import ErrorManagement from "../services/ErrorManagement";
import DeepLinkManagement from "../services/DeepLinkManagement";
// import PushNotifications from "../services/PushNotifications";
import { createRootAppStackNavigator } from "./AppNavigator";
import { useSelector } from "react-redux";
import { NAVIGATION } from "../actions/types";
import {
  ROLE_CAMPER,
  PWA_DEEPLINK_APP_URL,
  DEEPLINK_URL_SCHEME,
  DEEPLINK_APP_URL,
  APP_NAME
} from "../config/constants";
import PushNotifications from "../services/PushNotifications";

const theme = {
  colors: {
    primary: COLOR2
  }
};

// Gets the current screen from navigation state
const getActiveRouteName = state => {
  const route = state.routes[state.index];

  if (route.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }

  return route.name;
};

const getActiveRoute = state => {
  const route = state.routes[state.index];

  if (route.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }

  return route;
};

export default function AppNavigationContainer(props) {
  const store = props.store;

  const { user } = useSelector(state => state.profileReducer);
  const { accessDenied = false, isFetchingError } = useSelector(
    state => state.globalInformationsReducer
  );
  const { displayPreview, onClickPreview } = useSelector(
    state => state.templateReducer
  );
  const { communityDetails } = useSelector(state => state.communityReducer);

  const routeNameRef = React.useRef();
  const navigationRef = React.useRef();
  const myRef = React.useRef();
  const dropdownAlertRef = React.useRef();

  const onTap = message => {
    myRef?.current?.onTap(message);
  };

  const onClose = message => {
    myRef?.current?.onClose(message);
  };

  React.useEffect(() => {
    if (navigationRef.current) {
      const state = navigationRef.current.getRootState();

      // Save the initial route name
      routeNameRef.current = getActiveRouteName(state);
    }
  }, []);

  const linking1 = {
    prefixes: [PWA_DEEPLINK_APP_URL, DEEPLINK_APP_URL, DEEPLINK_URL_SCHEME],
    config: {
      ProfileUpdateScreen: `${APP_NAME}/enregistrement`
    }
  };

  const linking2 = {
    prefixes: [
      PWA_DEEPLINK_APP_URL,
      DEEPLINK_APP_URL,
      DEEPLINK_URL_SCHEME
      // DEEPLINK_DEV_URL
    ],
    config: {
      ProfileUpdateScreen: `${APP_NAME}/mon-profil`,
      CameraScreen: `${APP_NAME}/appareil-photo`,
      ProfileScreen: `${APP_NAME}/profil/:pseudo`,
      ProfileTeamScreen: `${APP_NAME}/profil`,
      ImageZoomScreen: `${APP_NAME}/zoom-image`,
      YoutubeScreen: `${APP_NAME}/youtube`,
      CGUScreen: `${APP_NAME}/conditions-generales-utilisation`,
      MaintenanceScreen: `${APP_NAME}/maintenance`,
      SelectActivityScreen: `${APP_NAME}/:communityUri/actualites/instants-partage`,
      SelectProposalScreen: `${APP_NAME}/:communityUri/actualites/demandes`,
      SelectTemplateScreen: `${APP_NAME}/:communityUri/actualites/modeles`,
      SelectTemplateDateScreen: `${APP_NAME}/:communityUri/actualites/parametres-publication`,
      PostScreen: `${APP_NAME}/:communityUri/actualites/edition-publication`,
      EventInterestedUsersScreen: `${APP_NAME}/:communityUri/actualites/publication`,
      GoodDealDetailsScreen: `${APP_NAME}/:communityUri/vie-locale/annonce`,
      adDetailsScreen: `${APP_NAME}/:communityUri/vie-locale/:adUri`,
      ChatTeamScreen: `${APP_NAME}/mes-conversations/conversation`,
      ChatScreen: `${APP_NAME}/mes-conversations/conversation/:pseudo`,
      ChatGrpScreen: `${APP_NAME}/:communityUri/conversations-de-groupe/:eventId`,
      TabScreen: {
        screens: {
          LiveTab: {
            screens: {
              LiveScreen: `${APP_NAME}/:communityUri/actualites`,
              SettingsScreen: `${APP_NAME}/:communityUri/parametres`
            }
          },
          ChatTab: {
            screens: {
              ChatOwnScreen: `${APP_NAME}/mes-conversations`,
              ChatGroupScreen: `${APP_NAME}/:communityUri/conversations-de-groupe`,
              ChatEmployeesScreen: `${APP_NAME}/:communityUri/a-mon-service`,
              ChatCampersScreen: `${APP_NAME}/:communityUri/mes-co-campeurs`
            }
          },
          GoodDealsTab: {
            screens: {
              GoodDealsScreen: `${APP_NAME}/:communityUri/vie-locale`,
              GoodDealsMapScreen: `${APP_NAME}/:communityUri/a-proximite`
            }
          },
          MeteoTab: {
            screens: {
              MeteoScreen: `${APP_NAME}/:communityUri/meteo`
            }
          },
          CommunityDetailsTab: {
            screens: {
              CommunityDetailsScreen: `${APP_NAME}/:communityUri`,
              AppInfosScreen: `${APP_NAME}/a-propos`
            }
          }
        }
      }
    }
  };

  const linking3 = {
    prefixes: [PWA_DEEPLINK_APP_URL, DEEPLINK_APP_URL, DEEPLINK_URL_SCHEME],
    config: {
      LandingScreen: `${APP_NAME}`,
      AppInfosScreen: `${APP_NAME}/a-propos`,
      CGUScreen: `${APP_NAME}/conditions-generales-utilisation`,
      GoodDealDetailsScreen: `${APP_NAME}/annonce`,
      SelectTemplateDateScreen: `${APP_NAME}/parametres-publication`,
      EventDetailsScreen: `${APP_NAME}/detail-publication`,
      MaintenanceScreen: `${APP_NAME}/maintenance`
    }
  };

  const linking4 = {
    prefixes: [PWA_DEEPLINK_APP_URL, DEEPLINK_APP_URL, DEEPLINK_URL_SCHEME],
    config: {
      LoadingScreen: `${APP_NAME}`,
      MaintenanceScreen: `${APP_NAME}/maintenance`
    }
  };

  const state = {
    routes: []
  };

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={
        Boolean(user && !isFetchingError)
          ? Boolean(
              !user.pseudo || (user.role === ROLE_CAMPER && user.needUpdate)
            )
            ? linking1
            : linking2
          : accessDenied || isFetchingError
          ? linking3
          : linking4
      }
      initialState={state}
      onStateChange={state => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = getActiveRouteName(state);

        if (previousRouteName !== currentRouteName) {
          const route = getActiveRoute(state);
          if (route instanceof Object) {
            store.dispatch({
              type: NAVIGATION,
              ...route
            });
          } else {
            store.dispatch({
              type: NAVIGATION,
              name: route
            });
          }
        }

        // Save the current route name for later comparision
        routeNameRef.current = currentRouteName;
      }}
    >
      <ThemeProvider theme={theme}>
        {createRootAppStackNavigator({
          dispatch: store.dispatch,
          user,
          accessDenied,
          navigation: navigationRef.current,
          isFetchingError,
          displayPreview,
          onClickPreview,
          communityDetails
        })}
      </ThemeProvider>
      {/* <CheckUpdates /> */}
      <ErrorManagement
        ref={myRef}
        navigationRef={navigationRef}
        dropDownHolder={dropdownAlertRef.current}
      />
      <DeepLinkManagement />
      <PushNotifications />
      <DropdownAlert
        ref={dropdownAlertRef}
        containerStyle={{ backgroundColor: GREEN_COLOR }}
        tapToCloseEnabled={true}
        closeInterval={6000}
        onTap={onTap}
        onClose={onClose}
        messageNumOfLines={5}
        updateStatusBar={false}
      />
    </NavigationContainer>
  );
}
