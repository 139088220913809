import React from "react";
import { Image, Platform, Text, View } from "react-native";
import { getImage } from "../assets/Images";
import { getTranslatedProperty } from "../services/i18n";
import commonStyles from "../styles/commonStyles";

const LiveWeatherElement = props => {
  let {
    font,
    fontLight,
    fontBold,
    p5,
    row,
    color4,
    flex1,
    flex2,
    fs10,
    fs20,
    wrap,
    alignItemsCenter,
    justifyContentCenter,
    textCenter,
    pr5,
    color5,
    contain
  } = commonStyles;
  let { hour, data, customProps } = props;

  return (
    <View style={[row, ...customProps, p5, flex1]}>
      <View style={[pr5, flex1]}>
        <Text style={[font, color4]}>
          {hour}
          h00
        </Text>
        <Text style={[fontBold, fs20]}>{data[hour].temp} °</Text>
        <Text style={[fontLight, fs10, color5]}>
          💦 {data[hour].humidity} %
        </Text>
      </View>
      <View style={[alignItemsCenter, justifyContentCenter, flex2]}>
        <View style={(flex1, { height: 80 })}>
          {Platform.OS === "web" ? (
            <Image
              style={[{ height: 70, width: 70 }]}
              source={getImage(data[hour].icon)}
            />
          ) : (
            <Image
              style={[flex1, contain]}
              source={getImage(data[hour].icon)}
            />
          )}
        </View>
        <Text
          style={[font, wrap, { fontSize: 11 }, textCenter]}
          numberOfLines={2}
        >
          {getTranslatedProperty(data[hour], "description")}
        </Text>
      </View>
    </View>
  );
};

export default LiveWeatherElement;
