import { createSelector } from "reselect";
import _ from "lodash";
import { TYPE_ACTIVITY, TYPE_TEMPLATE } from "../actions/types";
import { TYPE_PROPOSAL } from "../config/constants";

const getCategories = state => state.entitiesReducer.categories;
const getTemplates = state => state.entitiesReducer.templates;
const getUserId = state =>
  state.profileReducer.user ? state.profileReducer.user._id : null;

export const getActivities = createSelector([getTemplates], templates =>
  _.pickBy(templates, template => template.type === TYPE_ACTIVITY)
);

export const getProposals = createSelector([getTemplates], templates =>
  _.pickBy(templates, template => template.type === TYPE_PROPOSAL)
);

export const getTypeTemplates = createSelector([getTemplates], templates =>
  _.pickBy(templates, template => template.type === TYPE_TEMPLATE)
);

export const getUserTemplates = createSelector(
  [getTemplates, getUserId],
  (templates, userId) =>
    _.pickBy(
      templates,
      template => template.type === TYPE_TEMPLATE && template.userId === userId
    )
);

export const getNotifTemplates = createSelector([getTemplates], templates =>
  _.pickBy(templates, template => template.acceptNotif)
);

export const getCategoriesForActivities = createSelector(
  [getCategories, getActivities],
  getFilteredCategories()
);

export const getCategoriesForProposals = createSelector(
  [getCategories, getProposals],
  getFilteredCategories()
);

export const getCategoriesForTemplates = createSelector(
  [getCategories, getTypeTemplates],
  getFilteredCategories()
);

export const getCategoriesForUser = createSelector(
  [getCategories, getUserTemplates],
  getFilteredCategories()
);

export const getCategoriesForNotif = createSelector(
  [getCategories, getNotifTemplates],
  getFilteredCategories()
);

function getFilteredCategories() {
  return (categories, templates) =>
    _.pickBy(
      categories,
      category =>
        _.filter(category.templateIds, templateId => templates[templateId])
          .length > 0
    );
}
