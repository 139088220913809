import * as ImagePicker from "expo-image-picker";
import { Camera } from "expo-camera";
import React, { Component, Fragment } from "react";
import {
  ActivityIndicator,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Text,
  View
} from "react-native";
import CountryPicker from "react-native-country-picker-modal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Avatar, CheckBox, Input } from "react-native-elements";
import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons
} from "@expo/vector-icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SafeAreaView from "react-native-safe-area-view";
import {
  fetchGetUser,
  fetchUpdateProfile,
  fetchCheckPseudo
} from "../actions/profile";
import { fetchDeleteUser } from "../actions/login";
import {
  CLEAR_PHOTO,
  CLEAR_PROFILE_VALIDATE,
  SET_ADDRESS,
  SET_BIRTHDATE,
  SET_CITY,
  SET_COUNTRY_CODE,
  SET_DATE_RANGE,
  SET_DESCRIPTION,
  SET_FIRST_NAME,
  SET_GENDER,
  SET_LAST_NAME,
  SET_LOCATION_ID,
  SET_PHONE,
  SET_PHOTO_URI,
  SET_PSEUDO,
  SET_USER_HOBBIES,
  SET_ZIP_CODE,
  VALIDATE_PROFILE_INPUTS,
  SET_VALIDATE_FALSE,
  LOGOUT,
  SET_DROP_DOWN_ALERT_INFO,
  EDIT_PROFILE,
  CLEAR_VALIDATE_PSEUDO,
  VALIDATE_PSEUDO,
  REGISTER_FOR_PUSH_NOTIF,
  UPDATE_AUTOMATICALLY_HOBBIES,
  NOT_UPDATE_AUTOMATICALLY_HOBBIES,
  RESET_DATA,
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_CONFIRMATION
} from "../actions/types";
import { ROLE_CAMPER } from "../config/constants";
import ComposePicker from "../components/dateRanges/ComposePicker";
import Hobbies from "../components/Hobbies";
import ConfirmationModal from "../components/ConfirmationModal";
import { t } from "../services/i18n";
import commonStyles, {
  COLOR2,
  COLOR5,
  LIGHTLIGHTGREY_COLOR,
  PINK_COLOR,
  BLUE_COLOR,
  isWeb
} from "../styles/commonStyles";
import ActionButton from "../components/actionButton/ActionButton";
import CommonPicker from "../components/CommonPicker";
import { TextInputMask } from "react-native-masked-text";
import { getImage } from "../assets/Images";
const IMAGE_SIZE = 200;

class ProfileUpdate extends Component {
  static navigationOptions = {
    gestureEnabled: false
  };

  state = {
    needPushNotif: false,
    menuOpen: false,
    showConfirmationModal: false
  };

  componentDidMount() {
    const { dispatch, needUpdate = false } = this.props;

    dispatch({
      type: EDIT_PROFILE
    });

    if (needUpdate) {
      this.setState({
        needPushNotif: true
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      dispatch,
      fetchCheckPseudo,
      navigation,
      files,
      user,
      isValid,
      pseudoErrorMsg,
      clearProfileValidate,
      isPseudoClearValidate,
      needPseudoCheck,
      pseudo,
      needUpdate,
      deleteUserResult
    } = this.props;
    const { needPushNotif } = this.state;

    const { navigate } = navigation;

    if (needUpdate && !prevProps.needUpdate) {
      this.setState({
        needPushNotif: true
      });
    }

    if (deleteUserResult && !prevProps.deleteUserResult) {
      await AsyncStorage.removeItem("emailOrPseudo");
      await AsyncStorage.removeItem("hideTooltips");
      await AsyncStorage.removeItem("strategy");
      dispatch({
        type: LOGOUT
      });
      dispatch({
        type: RESET_DATA
      });
    }

    if (isPseudoClearValidate && !prevProps.isPseudoClearValidate) {
      dispatch({
        type: VALIDATE_PSEUDO
      });
    }
    if (!isPseudoClearValidate && prevProps.isPseudoClearValidate) {
      if (!pseudoErrorMsg && needPseudoCheck) {
        fetchCheckPseudo({ pseudo });
      } else if (pseudoErrorMsg) {
        this.pseudoInput && this.pseudoInput.shake();
        this.pseudoInput && this.pseudoInput.focus();
      }
    } else if (pseudoErrorMsg && !prevProps.pseudoErrorMsg) {
      this.pseudoInput && this.pseudoInput.shake();
      this.pseudoInput && this.pseudoInput.focus();
    }

    if (files && prevProps.files !== files) {
      this.pseudoInput && this.pseudoInput.focus();
    }

    // Validate inputs
    if (clearProfileValidate && !prevProps.clearProfileValidate) {
      dispatch({
        type: VALIDATE_PROFILE_INPUTS
      });
    }

    if (isValid && !prevProps.isValid) {
      this.updateProfile();
    }

    if (isValid && user && prevProps.user !== user) {
      navigate("TabScreen");
      dispatch({
        type: SET_VALIDATE_FALSE
      });
      if (needPushNotif) {
        this.setState({
          needPushNotif: false
        });
        dispatch({
          type: REGISTER_FOR_PUSH_NOTIF
        });
      }
    }
  }

  showConfirmationModal = () => {
    this.setState({ showConfirmationModal: true });
  };

  onConfirmationModalClosed = () => {
    this.setState({ showConfirmationModal: false });
  };

  deleteUser = () => {
    this.props.fetchDeleteUser();
  };

  updateProfile = () => {
    const {
      fetchUpdateProfile,
      pseudo,
      firstname,
      lastname,
      gender,
      address,
      zipCode,
      city,
      countryCode,
      photoUri,
      phone,
      description,
      hobbiesIds,
      birthdate,
      startDate,
      endDate,
      locationId,
      locationIdChanged,
      dateRangeChanged,
      files,
      updateHobbies,
      newpassword
    } = this.props;

    fetchUpdateProfile(
      {
        photoUri: photoUri && !files.length ? photoUri : null,
        pseudo,
        firstname,
        lastname,
        gender,
        address,
        zipCode,
        files,
        city,
        countryCode,
        phone,
        description,
        birthdate,
        hobbiesIds: [...hobbiesIds],
        updateHobbies,
        newpassword
      },
      locationIdChanged || dateRangeChanged
        ? {
            startDate,
            endDate,
            locationId
          }
        : null
    );
  };

  clearProfileValidate = () => {
    this.props.dispatch({
      type: CLEAR_PROFILE_VALIDATE
    });
  };

  _pickImage = async () => {
    if (!isWeb) {
      const {
        status
      } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        this.props.dispatch({
          type: SET_DROP_DOWN_ALERT_INFO,
          info: "photosinfo"
        });
        return;
      }
    }
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: "Images",
      // allowsEditing: true,
      aspect: [1, 1],
      quality: 0.1,
      base64: true
    });

    if (!result.cancelled) {
      if (Platform.OS === "web") {
        const files = [];
        const name = "" + Date.now();
        files.push({
          name,
          base64: result.uri
        });
        this.props.dispatch({
          type: SET_PHOTO_URI,
          value: result.uri,
          files
        });
      } else {
        const files = [];
        const filename = result.uri.split("/").pop();
        const name = filename
          ? filename.substring(0, filename.lastIndexOf("."))
          : null;
        files.push({
          name,
          base64: "data:image/jpeg;base64," + result.base64
        });
        this.props.dispatch({
          type: SET_PHOTO_URI,
          value: result.uri,
          files
        });
      }
    }
  };

  onConfirmDateRange = value => {
    this.props.dispatch({
      type: SET_DATE_RANGE,
      value
    });
  };

  onChangeLocation = value => {
    this.props.dispatch({
      type: SET_LOCATION_ID,
      value
    });
  };

  cancel = () => {
    const { dispatch, navigation } = this.props;
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      dispatch({
        type: LOGOUT
      });
    }
  };

  render() {
    const {
      red,
      bgColor1,
      darkgrey,
      bgWhite,
      color2,
      color5,
      flex1,
      alignItemsCenter,
      justifyContentCenter,
      fs12,
      fs16,
      fs20,
      row,
      p10,
      p20,
      ml10,
      mt10,
      mb10,
      mb20,
      mv10,
      mv20,
      ph10,
      font,
      fontBold,
      shadowGrey,
      w100p,
      ml5,
      mt5
    } = commonStyles;

    const {
      dispatch,
      navigation,
      pseudo,
      firstname,
      lastname,
      birthdate,
      address,
      zipCode,
      city,
      countryCode,
      phone,
      description,
      hobbiesIds,
      pseudoErrorMsg,
      firstnameErrorMsg,
      lastnameErrorMsg,
      birthdayErrorMsg,
      daterangeErrorMsg,
      locationErrorMsg,
      isFetching,
      photoUri,
      startDate,
      endDate,
      locationId,
      role,
      locale,
      firstLogin,
      gender,
      locations,
      phoneErrorMsg,
      updateHobbies,
      strategy,
      newpassword,
      newpasswordconfirmation,
      passwordErrorMsg,
      passwordConfirmationErrorMsg
    } = this.props;

    const { menuOpen } = this.state;

    return (
      <KeyboardAvoidingView
        {...(Platform.OS === "ios" && { behavior: "padding" })}
        style={[flex1, bgColor1]}
      >
        <ConfirmationModal
          description={t("settings:confirmdelete")}
          onClosed={this.onConfirmationModalClosed}
          title={t("menu:confirmation")}
          showConfirmationModal={this.state.showConfirmationModal}
          onConfirmation={this.deleteUser}
          isFetching={isFetching}
        />
        {isFetching ? (
          <ActivityIndicator
            style={[flex1, justifyContentCenter]}
            size="large"
            color={COLOR2}
          />
        ) : (
          <SafeAreaView style={[flex1]}>
            <ScrollView
              style={[flex1]}
              contentContainerStyle={{ paddingBottom: 90 }}
              showsVerticalScrollIndicator={false}
            >
              <View
                style={[
                  w100p,
                  p10,
                  bgWhite,
                  mt10,
                  mb20,
                  shadowGrey,
                  alignItemsCenter
                ]}
              >
                <Text style={[fontBold, color5, fs20, mv20]}>
                  {t("profile:profile")}
                </Text>

                <View style={[row, mb20, justifyContentCenter, w100p]}>
                  <Avatar
                    rounded
                    size={IMAGE_SIZE}
                    title={pseudo ? pseudo.toUpperCase().substr(0, 2) : "?"}
                    source={
                      Boolean(photoUri)
                        ? {
                            uri: photoUri
                          }
                        : gender === "M"
                        ? getImage("avatar")
                        : getImage("avatarF")
                    }
                    onPress={() => {
                      Keyboard.dismiss();
                      navigation.navigate("CameraScreen", {
                        showGridParam: true,
                        type: SET_PHOTO_URI,
                        cameraTypeParam: Camera.Constants.Type.front,
                        origin: "ProfileUpdateScreen"
                      });
                    }}
                  />
                  <ActionButton
                    buttonColor={COLOR5}
                    renderIcon={() => (
                      <MaterialCommunityIcons
                        name="dots-horizontal"
                        size={34}
                        color="white"
                      />
                    )}
                    verticalOrientation="down"
                    size={44}
                    offsetX={10}
                    offsetY={0}
                    degrees={90}
                    spacing={10}
                  >
                    {Boolean(photoUri) && (
                      <ActionButton.Item
                        buttonColor={"red"}
                        title={t("button:delete")}
                        onPress={() => dispatch({ type: CLEAR_PHOTO })}
                        textStyle={font}
                        textHeight={24}
                      >
                        <MaterialCommunityIcons
                          name={"delete-forever"}
                          size={34}
                          color="white"
                        />
                      </ActionButton.Item>
                    )}
                    <ActionButton.Item
                      buttonColor={COLOR2}
                      title={t("button:albums")}
                      onPress={this._pickImage}
                      textStyle={font}
                      textHeight={24}
                    >
                      <FontAwesome name="image" size={28} color="white" />
                    </ActionButton.Item>
                    <ActionButton.Item
                      buttonColor={COLOR2}
                      title={t("button:camera")}
                      onPress={() => {
                        Keyboard.dismiss();
                        navigation.navigate("CameraScreen", {
                          showGridParam: true,
                          type: SET_PHOTO_URI,
                          cameraTypeParam: Camera.Constants.Type.front,
                          origin: "ProfileUpdateScreen"
                        });
                      }}
                      textStyle={font}
                      textHeight={24}
                    >
                      <MaterialIcons
                        name="camera-alt"
                        size={34}
                        color="white"
                      />
                    </ActionButton.Item>
                  </ActionButton>
                </View>
                {role === ROLE_CAMPER && (
                  <Input
                    inputStyle={[
                      Platform.OS === "ios" ? ml10 : null,
                      darkgrey,
                      font
                    ]}
                    onChangeText={value =>
                      dispatch({ type: SET_PSEUDO, value })
                    }
                    onBlur={() => {
                      dispatch({
                        type: CLEAR_VALIDATE_PSEUDO
                      });
                    }}
                    placeholder={t("profile:pseudo")}
                    keyboardType="default"
                    autoCorrect={false}
                    autoCapitalize="none"
                    keyboardAppearance="light"
                    returnKeyType="next"
                    value={pseudo}
                    // Strange input is null once in 2
                    ref={input => (this.pseudoInput = input)}
                    errorMessage={pseudoErrorMsg}
                  />
                )}
                <View style={[mv10, row, alignItemsCenter]}>
                  <CheckBox
                    title={t("profile:male")}
                    textStyle={[{ color: BLUE_COLOR }, fontBold]}
                    checkedColor={BLUE_COLOR}
                    uncheckedColor={BLUE_COLOR}
                    checkedIcon="dot-circle-o"
                    uncheckedIcon="circle-o"
                    checked={gender === "M"}
                    onPress={() => dispatch({ type: SET_GENDER, value: "M" })}
                  />
                  <CheckBox
                    title={t("profile:female")}
                    textStyle={[{ color: PINK_COLOR }, fontBold]}
                    checkedColor={PINK_COLOR}
                    uncheckedColor={PINK_COLOR}
                    checkedIcon="dot-circle-o"
                    uncheckedIcon="circle-o"
                    checked={gender === "F"}
                    onPress={() => dispatch({ type: SET_GENDER, value: "F" })}
                  />
                </View>
                <Input
                  inputStyle={[
                    Platform.OS === "ios" ? ml10 : null,
                    darkgrey,
                    font
                  ]}
                  onChangeText={value =>
                    dispatch({ type: SET_FIRST_NAME, value })
                  }
                  onSubmitEditing={() => this.lastNameInput.focus()}
                  placeholder={t("profile:firstname")}
                  keyboardType="default"
                  autoCorrect={false}
                  autoCapitalize="words"
                  keyboardAppearance="light"
                  returnKeyType="next"
                  value={firstname}
                  ref={input => (this.firstNameInput = input)}
                  errorMessage={firstnameErrorMsg}
                />
                <Input
                  inputStyle={[
                    Platform.OS === "ios" ? ml10 : null,
                    darkgrey,
                    font
                  ]}
                  onChangeText={value =>
                    dispatch({ type: SET_LAST_NAME, value })
                  }
                  onSubmitEditing={() => {
                    if (role === ROLE_CAMPER) {
                      this.birthdayInput.getElement().focus();
                    } else {
                      this.phoneInput.getElement().focus();
                    }
                  }}
                  containerStyle={mb20}
                  placeholder={t("profile:lastname")}
                  autoCorrect={false}
                  autoCapitalize="words"
                  value={lastname}
                  keyboardType="default"
                  returnKeyType="next"
                  ref={input => (this.lastNameInput = input)}
                  errorMessage={lastnameErrorMsg}
                />
                {role === ROLE_CAMPER && (
                  <Fragment>
                    <View style={[{ paddingHorizontal: 10 }, w100p]}>
                      <TextInputMask
                        ref={input => (this.birthdayInput = input)}
                        type={Platform.OS === "web" ? "custom" : "datetime"}
                        options={
                          Platform.OS === "web"
                            ? {
                                mask: "99/99/9999"
                              }
                            : {
                                format: "DD/MM/YYYY"
                              }
                        }
                        placeholder={t("profile:birthdate")}
                        value={birthdate}
                        style={[
                          font,
                          {
                            paddingLeft: Platform.OS === "ios" ? 10 : 0,
                            paddingBottom: 10,
                            fontSize: 18,
                            height: 40,
                            width: "100%",
                            borderColor: "gray",
                            borderBottomWidth: 1
                          },
                          !birthdayErrorMsg && mb20
                        ]}
                        onChangeText={value =>
                          dispatch({ type: SET_BIRTHDATE, value })
                        }
                      />
                      {birthdayErrorMsg && (
                        <Text style={[ml5, mt5, red, fs12, mb20]}>
                          {birthdayErrorMsg}
                        </Text>
                      )}
                    </View>
                    <Input
                      inputStyle={[
                        Platform.OS === "ios" ? ml10 : null,
                        darkgrey,
                        font
                      ]}
                      onChangeText={value =>
                        dispatch({ type: SET_ADDRESS, value })
                      }
                      placeholder={t("profile:address")}
                      autoCorrect={false}
                      keyboardType="default"
                      keyboardAppearance="light"
                      returnKeyType="next"
                      value={address}
                      ref={input => (this.addressInput = input)}
                      onSubmitEditing={() => {
                        this.zipCodeInput.focus();
                      }}
                    />
                    <Input
                      inputStyle={[
                        Platform.OS === "ios" ? ml10 : null,
                        darkgrey,
                        font
                      ]}
                      onChangeText={value =>
                        dispatch({ type: SET_ZIP_CODE, value })
                      }
                      placeholder={t("profile:zipcode")}
                      autoCorrect={false}
                      keyboardType="default"
                      keyboardAppearance="light"
                      returnKeyType="next"
                      value={zipCode}
                      ref={input => (this.zipCodeInput = input)}
                      onSubmitEditing={() => {
                        this.cityInput.focus();
                      }}
                    />
                    <Input
                      inputStyle={[
                        Platform.OS === "ios" ? ml10 : null,
                        darkgrey,
                        font
                      ]}
                      containerStyle={mb20}
                      onChangeText={value =>
                        dispatch({ type: SET_CITY, value })
                      }
                      placeholder={t("profile:city")}
                      autoCorrect={false}
                      keyboardType="default"
                      keyboardAppearance="light"
                      returnKeyType="next"
                      value={city}
                      ref={input => (this.cityInput = input)}
                      onSubmitEditing={() => {
                        this.phoneInput && this.phoneInput.getElement().focus();
                      }}
                    />
                    <View
                      style={[flex1, alignItemsCenter, justifyContentCenter]}
                    >
                      <View style={[row, mb20, ph10, alignItemsCenter]}>
                        <CountryPicker
                          {...{
                            countryCode,
                            translation: locale === "fr" ? "fra" : "common",
                            withFlagButton: true,
                            withFilter: true,
                            onSelect: value =>
                              dispatch({
                                type: SET_COUNTRY_CODE,
                                value: value.cca2
                              })
                          }}
                        />
                        <Text style={[font, fs20]}>
                          {"  " + t("country:" + countryCode)}
                        </Text>
                      </View>
                    </View>
                  </Fragment>
                )}
                {!countryCode || countryCode === "FR" ? (
                  <View
                    style={[
                      { paddingHorizontal: 10 },
                      w100p,
                      role === ROLE_CAMPER && mb20
                    ]}
                  >
                    <TextInputMask
                      ref={input => (this.phoneInput = input)}
                      type={"custom"}
                      options={{
                        mask: "+33 (0)9 99 99 99 99"
                      }}
                      keyboardType="phone-pad"
                      placeholder={t("profile:phone")}
                      value={phone}
                      style={[
                        font,
                        {
                          paddingLeft: Platform.OS === "ios" ? 10 : 0,
                          paddingBottom: 10,
                          fontSize: 18,
                          height: 30,
                          width: "100%",
                          borderColor: "gray",
                          borderBottomWidth: 1
                        }
                      ]}
                      onChangeText={value =>
                        dispatch({ type: SET_PHONE, value })
                      }
                    />
                    {phoneErrorMsg && (
                      <Text style={[ml5, mt5, red, fs12]}>{phoneErrorMsg}</Text>
                    )}
                  </View>
                ) : (
                  <View
                    style={[
                      { paddingHorizontal: 10 },
                      w100p,
                      role === ROLE_CAMPER && mb20
                    ]}
                  >
                    <TextInputMask
                      ref={input => (this.phoneInput = input)}
                      type={"cel-phone"}
                      options={{
                        maskType: "INTERNATIONAL"
                      }}
                      placeholder={t("profile:phone")}
                      value={phone}
                      style={[
                        font,
                        {
                          paddingLeft: Platform.OS === "ios" ? 10 : 0,
                          paddingBottom: 10,
                          fontSize: 18,
                          height: 40,
                          width: "100%",
                          borderColor: "gray",
                          borderBottomWidth: 1
                        }
                      ]}
                      onChangeText={value =>
                        dispatch({ type: SET_PHONE, value })
                      }
                    />
                  </View>
                )}
                {role !== ROLE_CAMPER && (
                  <Input
                    inputStyle={[
                      Platform.OS === "ios" ? ml10 : null,
                      darkgrey,
                      font
                    ]}
                    containerStyle={mb20}
                    onChangeText={value =>
                      dispatch({ type: SET_DESCRIPTION, value })
                    }
                    placeholder={t("profile:description")}
                    keyboardType="default"
                    keyboardAppearance="light"
                    returnKeyType="next"
                    value={description}
                    ref={input => (this.descriptionInput = input)}
                  />
                )}
              </View>
              {role === ROLE_CAMPER && (
                <Fragment>
                  <View
                    style={[
                      w100p,
                      p20,
                      bgWhite,
                      mt10,
                      mb20,
                      shadowGrey,
                      alignItemsCenter
                    ]}
                  >
                    <Text style={[fontBold, color5, fs20, mv20]}>
                      {t("profile:hobbies")}
                    </Text>
                    <Hobbies
                      hobbiesIds={new Set(hobbiesIds)}
                      onHobbiesChange={value =>
                        dispatch({ type: SET_USER_HOBBIES, value })
                      }
                    />
                    <CheckBox
                      title={t("profile:updatehobbies")}
                      textStyle={[darkgrey, fontBold]}
                      containerStyle={[w100p]}
                      checkedColor={COLOR2}
                      uncheckedColor={COLOR2}
                      checkedIcon="dot-circle-o"
                      uncheckedIcon="circle-o"
                      checked={updateHobbies}
                      onPress={() =>
                        dispatch({ type: UPDATE_AUTOMATICALLY_HOBBIES })
                      }
                    />
                    <CheckBox
                      title={t("profile:noupdatehobbies")}
                      textStyle={[darkgrey, fontBold]}
                      containerStyle={[w100p]}
                      checkedColor={COLOR2}
                      uncheckedColor={COLOR2}
                      checkedIcon="dot-circle-o"
                      uncheckedIcon="circle-o"
                      checked={!updateHobbies}
                      onPress={() =>
                        dispatch({ type: NOT_UPDATE_AUTOMATICALLY_HOBBIES })
                      }
                    />
                  </View>
                  <View style={[w100p, p20, bgWhite, mt10, mb20, shadowGrey]}>
                    <View style={[alignItemsCenter]}>
                      <Text style={[fontBold, color5, fs20, mv20]}>
                        {t("profile:stay")}
                      </Text>
                    </View>
                    <View style={mb20}>
                      <ComposePicker
                        // style={{ width: "100%", height: 44 }}
                        customStyles={{
                          placeholderText: {
                            fontSize: 18,
                            fontFamily: "font"
                          }, // placeHolder style
                          headerStyle: { backgroundColor: COLOR5 }, // title container style
                          headerMarkTitle: {
                            fontFamily: "font",
                            color: COLOR2
                          }, // title mark style
                          headerDateTitle: {}, // title Date style
                          contentInput: {
                            backgroundColor: LIGHTLIGHTGREY_COLOR
                          }, //content text container style
                          contentText: font //after selected text Style
                        }} // optional
                        selectedBgColor={COLOR2}
                        outFormat="DD/MM/YYYY"
                        headFormat="DD/MM/YYYY"
                        returnFormat="DD/MM/YYYY"
                        markText={t("profile:selectdaterange")}
                        centerAlign // optional text will align center or not
                        allowFontScaling={false} // optional
                        placeholder={t("profile:bookingdates")}
                        onConfirm={this.onConfirmDateRange}
                        startDate={startDate}
                        endDate={endDate}
                        mode={"range"}
                      />
                      {daterangeErrorMsg && (
                        <Text style={[red, fs12]}>{daterangeErrorMsg}</Text>
                      )}
                    </View>

                    <CommonPicker
                      style={!locationErrorMsg && mb20}
                      data={locations}
                      firstPickerLabel={t("profile:selectaccomodation")}
                      selectedValue={locationId}
                      onChange={this.onChangeLocation}
                    />
                    {locationErrorMsg && (
                      <Text style={[red, fs12, mb20]}>{locationErrorMsg}</Text>
                    )}
                  </View>
                </Fragment>
              )}
              {strategy === "jwt" && (
                <View
                  style={[
                    w100p,
                    p20,
                    bgWhite,
                    mt10,
                    mb20,
                    shadowGrey,
                    alignItemsCenter
                  ]}
                >
                  <Text style={[fontBold, color2, fs20, mv20]}>
                    {t("profile:password")}
                  </Text>
                  <Input
                    inputStyle={[
                      Platform.OS === "ios" ? ml10 : null,
                      darkgrey,
                      font
                    ]}
                    containerStyle={mb10}
                    onChangeText={value =>
                      dispatch({ type: SET_NEW_PASSWORD, value })
                    }
                    placeholder={t("profile:newpassword")}
                    keyboardType="default"
                    keyboardAppearance="light"
                    autoCapitalize="none"
                    autoCorrect={false}
                    secureTextEntry={true}
                    returnKeyType="next"
                    value={newpassword}
                    ref={input => (this.passwordInput = input)}
                    errorMessage={passwordErrorMsg}
                  />
                  <Input
                    inputStyle={[
                      Platform.OS === "ios" ? ml10 : null,
                      darkgrey,
                      font
                    ]}
                    containerStyle={mb10}
                    onChangeText={value =>
                      dispatch({ type: SET_NEW_PASSWORD_CONFIRMATION, value })
                    }
                    placeholder={t("profile:newpasswordconfirmation")}
                    keyboardType="default"
                    keyboardAppearance="light"
                    autoCapitalize="none"
                    autoCorrect={false}
                    secureTextEntry={true}
                    returnKeyType="done"
                    value={newpasswordconfirmation}
                    ref={input => (this.passwordConfirmationInput = input)}
                    errorMessage={passwordConfirmationErrorMsg}
                  />
                </View>
              )}
            </ScrollView>
            <ActionButton
              buttonColor={menuOpen ? COLOR5 : COLOR2}
              degrees={90}
              renderIcon={() => (
                <MaterialCommunityIcons
                  name="dots-horizontal"
                  size={40}
                  color="white"
                />
              )}
              onPressIn={() => this.setState({ menuOpen: true })}
              onReset={() => this.setState({ menuOpen: false })}
              disabled={isFetching}
            >
              <ActionButton.Item
                buttonColor={"red"}
                title={t("button:deleteaccount")}
                onPress={this.showConfirmationModal}
                textStyle={[fontBold, fs16]}
              >
                <MaterialCommunityIcons
                  name="account-remove"
                  size={36}
                  color="white"
                />
              </ActionButton.Item>
              <ActionButton.Item
                buttonColor={"red"}
                title={t("button:cancel")}
                onPress={this.cancel}
                textStyle={[fontBold, fs16]}
              >
                <MaterialCommunityIcons name="cancel" size={36} color="white" />
              </ActionButton.Item>
              <ActionButton.Item
                buttonColor={COLOR2}
                title={firstLogin ? t("button:signup") : t("button:update")}
                onPress={this.clearProfileValidate}
                textStyle={[fontBold, fs16]}
              >
                <MaterialCommunityIcons
                  name="account-check"
                  size={36}
                  color="white"
                />
              </ActionButton.Item>
            </ActionButton>
          </SafeAreaView>
        )}
      </KeyboardAvoidingView>
    );
  }
}

const mapStateToProps = state => {
  const {
    files,
    id,
    pseudo,
    firstname,
    lastname,
    gender,
    birthdate,
    address,
    zipCode,
    city,
    countryCode,
    phone,
    description,
    photoUri,
    hobbiesIds,
    startDate,
    endDate,
    locationId,
    pseudoErrorMsg,
    firstnameErrorMsg,
    lastnameErrorMsg,
    birthdayErrorMsg,
    daterangeErrorMsg,
    locationErrorMsg,
    user,
    isValid,
    isFetching,
    dateRangeChanged,
    locationIdChanged,
    firstLogin,
    needPseudoCheck,
    clearProfileValidate,
    isPseudoClearValidate,
    isFirstnameClearValidate,
    isLastnameClearValidate,
    isBirthdateClearValidate,
    isPhoneClearValidate,
    phoneErrorMsg,
    needUpdate,
    updateHobbies,
    strategy,
    newpassword,
    newpasswordconfirmation,
    passwordErrorMsg,
    passwordConfirmationErrorMsg,
    isPasswordClearValidate,
    isPasswordConfirmationClearValidate
  } = state.profileReducer;

  const { lang } = state.globalInformationsReducer;

  return {
    files,
    id,
    pseudo,
    firstname,
    lastname,
    gender,
    birthdate,
    address,
    zipCode,
    city,
    countryCode,
    phone,
    description,
    photoUri,
    hobbiesIds,
    startDate,
    endDate,
    locationId,
    pseudoErrorMsg,
    firstnameErrorMsg,
    lastnameErrorMsg,
    birthdayErrorMsg,
    daterangeErrorMsg,
    locationErrorMsg,
    user,
    clearProfileValidate,
    isValid,
    isFetching,
    dateRangeChanged,
    locationIdChanged,
    locale: lang,
    role: user?.role,
    firstLogin,
    needPseudoCheck,
    locations: state.entitiesReducer.locations,
    isPseudoClearValidate,
    isFirstnameClearValidate,
    isLastnameClearValidate,
    isBirthdateClearValidate,
    isPhoneClearValidate,
    phoneErrorMsg,
    needUpdate,
    updateHobbies,
    deleteUserResult: state.loginReducer.deleteUserResult,
    strategy,
    newpassword,
    newpasswordconfirmation,
    passwordErrorMsg,
    passwordConfirmationErrorMsg,
    isPasswordClearValidate,
    isPasswordConfirmationClearValidate
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators(
    { fetchUpdateProfile, fetchCheckPseudo, fetchGetUser, fetchDeleteUser },
    dispatch
  );
  return { ...actions, dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUpdate);
