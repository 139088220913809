import {
  API_START,
  API_END,
  FETCH_GET_NOTIF_USER_TEMPLATES,
  SET_NOTIF_USER_TEMPLATES,
  FETCH_SET_NOTIF_USER_TEMPLATES,
  FETCH_DELETE_USER,
  FETCH_GET_NOTIF_STATUS,
  STATUS_UNDEFINED,
  STATUS_ALL,
  STATUS_SELECTION,
  SET_NOTIF_STATUS,
  FETCH_SET_NOTIF_SELECTION,
  FETCH_SET_NOTIF_ALL
} from "../actions/types";

const initialState = {
  isFetching: false,
  data: [],
  status: STATUS_UNDEFINED
};

export default function settingsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (
        action.payload === FETCH_GET_NOTIF_STATUS ||
        action.payload === FETCH_GET_NOTIF_USER_TEMPLATES ||
        action.payload === FETCH_SET_NOTIF_USER_TEMPLATES ||
        action.payload === FETCH_SET_NOTIF_SELECTION ||
        action.payload === FETCH_SET_NOTIF_ALL
      ) {
        return {
          ...state,
          isFetching: true
        };
      } else if (action.payload === FETCH_DELETE_USER) {
        return {
          isFetching: true
        };
      }
      break;

    case SET_NOTIF_USER_TEMPLATES:
      return {
        ...state,
        data: action.payload
      };

    case SET_NOTIF_STATUS:
      if (action.payload === null) {
        return {
          ...state,
          status: STATUS_UNDEFINED
        };
      } else if (action.payload.templateId === null) {
        return {
          ...state,
          status: STATUS_ALL
        };
      } else {
        return {
          ...state,
          status: STATUS_SELECTION
        };
      }

    case API_END:
      if (
        action.payload === FETCH_GET_NOTIF_STATUS ||
        action.payload === FETCH_GET_NOTIF_USER_TEMPLATES ||
        action.payload === FETCH_SET_NOTIF_USER_TEMPLATES ||
        action.payload === FETCH_DELETE_USER ||
        action.payload === FETCH_SET_NOTIF_SELECTION ||
        action.payload === FETCH_SET_NOTIF_ALL
      ) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
}
