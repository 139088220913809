import React, { Component, Fragment } from "react";
import { Picker, Platform, View } from "react-native";
import commonStyles from "../styles/commonStyles";
import PropTypes from "prop-types";
import { getTranslatedProperty } from "../services/i18n";
import ScrollPicker from "../components/ScrollPicker";

class CommonPicker extends Component {
  renderPickerItems = () => {
    const components = [];
    const { data } = this.props;
    for (const objId in data) {
      const label = getTranslatedProperty(data[objId], "name");
      components.push(
        <Picker.Item key={objId} label={label ? label : objId} value={objId} />
      );
    }
    return components;
  };

  render() {
    const {
      flex1,
      font,
      bgLightLightgrey,
      rounded5,
      borderBrown,
      mv10
    } = commonStyles;
    let { style, onChange, selectedValue, firstPickerLabel, data } = this.props;

    let values;
    if (Platform.OS === "android") {
      values = Object.values(data).map(obj =>
        getTranslatedProperty(obj, "name")
      );
      if (values.length > 1 && !values[1]) {
        values = Object.keys(data);
      }
    }

    return (
      <Fragment>
        {Platform.OS === "ios" || Platform.OS === "web" ? (
          <Picker
            selectedValue={selectedValue}
            itemStyle={[font, { fontSize: 18, height: 150 }]}
            style={[style, borderBrown, rounded5, bgLightLightgrey]}
            onValueChange={(itemValue, itemIndex) => {
              onChange(itemValue);
            }}
          >
            {firstPickerLabel && (
              <Picker.Item key={Date.now()} label={firstPickerLabel} />
            )}
            {this.renderPickerItems()}
          </Picker>
        ) : (
          <View
            style={[flex1, borderBrown, rounded5, mv10, { overflow: "hidden" }]}
          >
            <ScrollPicker
              dataSource={
                firstPickerLabel ? [firstPickerLabel, ...values] : values
              }
              selectedIndex={
                firstPickerLabel
                  ? Object.keys(data).indexOf(selectedValue) + 1
                  : Object.keys(data).indexOf(selectedValue)
              }
              itemHeight={50}
              wrapperHeight={150}
              highlightColor={"#d8d8d8"}
              onValueChange={(itemValue, itemIndex) => {
                onChange(
                  firstPickerLabel
                    ? Object.keys(data)[--itemIndex]
                    : Object.keys(data)[itemIndex]
                );
              }}
            />
          </View>
        )}
      </Fragment>
    );
  }
}

export default CommonPicker;

CommonPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  firstPickerLabel: PropTypes.string
};
