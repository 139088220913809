import {
  FETCH_GET_TEMPLATES,
  SET_TEMPLATES,
  SET_TEMPLATES_FAILURE,
  SET_TEMPLATE_DATES,
  SET_TEMPLATE_DATES_FAILURE,
  SET_TEMPLATE_TIMES,
  SET_TEMPLATE_TIMES_FAILURE,
  FETCH_GET_TEMPLATE_TIMES,
  FETCH_GET_TEMPLATE_DATES,
  FETCH_PUBLISH_ACTIVITY_REQUEST,
  SET_PUBLISH_RESULT,
  FETCH_GET_NOTIF_USER_TEMPLATES,
  FETCH_SET_NOTIF_USER_TEMPLATES,
  FETCH_DELETE_NOTIF_USER_TEMPLATE,
  SET_NOTIF_USER_TEMPLATES,
  FETCH_GET_NOTIF_STATUS,
  SET_NOTIF_STATUS,
  FETCH_SET_NOTIF_ALL,
  FETCH_SET_NOTIF_SELECTION,
  FETCH_FAILURE,
  FETCH_GET_GOOD_DEALS_TEMPLATES,
  SET_GOOD_DEALS_TEMPLATES
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";

// Define a templates schema
const template = new schema.Entity(
  "templates",
  {},
  {
    idAttribute: "_id"
  }
);

// Define your categories
export const categorie = new schema.Entity(
  "categories",
  {
    templateIds: [template]
  },
  {
    idAttribute: "_id"
  }
);

const templateDates = new schema.Entity(
  "templateDates",
  {},
  {
    idAttribute: "_id"
  }
);

const templateTimes = new schema.Entity(
  "templateTimes",
  {},
  {
    idAttribute: "_id"
  }
);

export function fetchGetTemplates(dispatchPostEvent = null) {
  let onSuccess;
  if (dispatchPostEvent) {
    onSuccess = normalized => [setTemplates(normalized), dispatchPostEvent];
  } else {
    onSuccess = normalized => [setTemplates(normalized)];
  }

  return apiAction({
    url: "/templates/categories",
    method: "GET",
    schema: [categorie],
    onSuccess,
    onFailure: setTemplatesFailure,
    label: FETCH_GET_TEMPLATES
  });
}

function setTemplates(normalized) {
  return {
    type: SET_TEMPLATES,
    entities: normalized.entities
  };
}

function setTemplatesFailure(error) {
  return {
    type: SET_TEMPLATES_FAILURE,
    payload: error
  };
}

export function fetchGetTemplateDates() {
  return apiAction({
    url: "/templates/dates",
    method: "GET",
    schema: [templateDates],
    onSuccess: setTemplateDates,
    onFailure: setTemplateDatesFailure,
    label: FETCH_GET_TEMPLATE_DATES
  });
}

function setTemplateDates(normalized) {
  return {
    type: SET_TEMPLATE_DATES,
    entities: normalized.entities
  };
}

function setTemplateDatesFailure(error) {
  return {
    type: SET_TEMPLATE_DATES_FAILURE,
    payload: error
  };
}

export function fetchGetTemplateTimes() {
  return apiAction({
    url: "/templates/times",
    method: "GET",
    schema: [templateTimes],
    onSuccess: setTemplateTimes,
    onFailure: setTemplateTimesFailure,
    label: FETCH_GET_TEMPLATE_TIMES
  });
}

function setTemplateTimes(normalized) {
  return {
    type: SET_TEMPLATE_TIMES,
    entities: normalized.entities
  };
}

function setTemplateTimesFailure(error) {
  return {
    type: SET_TEMPLATE_TIMES_FAILURE,
    payload: error
  };
}

export function fetchPublishRequest(data) {
  return apiAction({
    url: "/templates/publish",
    data,
    method: "POST",
    onSuccess: setPublishResult,
    onFailure: setFetchFailure,
    label: FETCH_PUBLISH_ACTIVITY_REQUEST
  });
}

function setPublishResult(data) {
  return {
    type: SET_PUBLISH_RESULT,
    payload: data
  };
}

export function fetchGetNotifStatus() {
  return apiAction({
    url: "/templates/alerts-status",
    method: "GET",
    onSuccess: data => [setNotifStatus(data), fetchGetNotifUserTemplates()],
    onFailure: setFetchFailure,
    label: FETCH_GET_NOTIF_STATUS
  });
}

export function fetchSetNotifAll() {
  return apiAction({
    url: "/templates/alerts-status",
    method: "POST",
    onSuccess: setNotifStatus,
    onFailure: setFetchFailure,
    label: FETCH_SET_NOTIF_ALL
  });
}

export function fetchSetNotifSelection() {
  return apiAction({
    url: "/templates/alerts-status",
    method: "DELETE",
    onSuccess: setNotifStatus,
    onFailure: setFetchFailure,
    label: FETCH_SET_NOTIF_SELECTION
  });
}

function setNotifStatus(data) {
  return {
    type: SET_NOTIF_STATUS,
    payload: data
  };
}

export function fetchGetNotifUserTemplates() {
  return apiAction({
    url: "/templates/alerts",
    method: "GET",
    onSuccess: setNotifUserTemplates,
    onFailure: setFetchFailure,
    label: FETCH_GET_NOTIF_USER_TEMPLATES
  });
}

export function fetchSetNotifUserTemplates(templatesIds) {
  return apiAction({
    url: "/templates/alerts",
    method: "POST",
    data: { templatesIds },
    onSuccess: setNotifUserTemplates,
    onFailure: setFetchFailure,
    label: FETCH_SET_NOTIF_USER_TEMPLATES
  });
}

export function fetchDeleteNotifUserTemplate(templateId) {
  return apiAction({
    url: "/templates/alert/" + templateId,
    method: "DELETE",
    data: { templateId },
    onSuccess: setNotifUserTemplates,
    onFailure: setFetchFailure,
    label: FETCH_DELETE_NOTIF_USER_TEMPLATE
  });
}

function setNotifUserTemplates(data) {
  return {
    type: SET_NOTIF_USER_TEMPLATES,
    payload: data
  };
}

function setFetchFailure(error) {
  return {
    type: FETCH_FAILURE,
    payload: error
  };
}

export function fetchGetGoodDealsTemplates() {
  return apiAction({
    url: "/templates/usedByGooddeals",
    method: "GET",
    schema: [template],
    onSuccess: setGoodDealsTemplates,
    onFailure: setFetchFailure,
    label: FETCH_GET_GOOD_DEALS_TEMPLATES
  });
}

function setGoodDealsTemplates(normalized) {
  return {
    type: SET_GOOD_DEALS_TEMPLATES,
    entities: normalized.entities
  };
}
