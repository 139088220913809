import axios from "axios";
import { API, FETCH_LOGIN_USING_TOKEN } from "../actions/types";
import {
  accessDenied,
  apiError,
  apiStart,
  apiEnd,
  serverInMaintenance
} from "../actions/api";
import { normalize } from "normalizr";
const apiMiddleware = ({ dispatch }) => next => async action => {
  next(action);

  if (action.type !== API) return;

  const {
    url,
    method,
    data,
    onSuccess,
    onFailure,
    label,
    headers,
    schema
  } = action.payload;
  const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

  // axios default configs
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  try {
    if (label) {
      dispatch(apiStart(label));
    }
    const response = await axios({
      url,
      method,
      headers,
      [dataOrParams]: data,
      withCredentials: true
    });
    if (schema) {
      dispatch(onSuccess(normalize(response.data, schema)));
    } else {
      dispatch(onSuccess(response.data));
    }
  } catch (error) {
    if (error.response && error.response.status === 503) {
      dispatch(serverInMaintenance(label));
    } else if (label === FETCH_LOGIN_USING_TOKEN) {
      // Retry
      try {
        const response2 = await axios({
          url,
          method,
          headers,
          [dataOrParams]: data,
          withCredentials: true
        });
        if (schema) {
          dispatch(onSuccess(normalize(response2.data, schema)));
        } else {
          dispatch(onSuccess(response2.data));
        }
      } catch (error) {
        if (error.response && error.response.status === 503) {
          dispatch(serverInMaintenance(label));
        } else {
          dispatch(apiError(error, label, data));
          dispatch(onFailure(error));
        }
      }
    } else {
      dispatch(apiError(error, label, data));
      dispatch(onFailure(error));
    }
    if (error.response && error.response.status === 403) {
      dispatch(accessDenied(url));
    }
  } finally {
    if (label) {
      dispatch(apiEnd(label));
    }
  }
};

export default apiMiddleware;
