import {
  API_START,
  API_END,
  FETCH_GET_GOOD_DEALS,
  SET_GOOD_DEALS_RESULT,
  WS_CONNECTED,
  WS_EVENT,
  ADD_GOODDEAL,
  DELETE_GOODDEAL,
  UPDATE_GOODDEAL,
  GOODDEALS_ON_TOP,
  RESET_DATA,
  CATEGORIES_FILTER,
  SET_GOOD_DEALS_LOCATION_RESULT,
  FETCH_GET_GOOD_DEALS_LOCATION,
  NAVIGATION,
  SET_GOODDEAL_ON_CLICK_FILTER,
  SET_GOODDEAL_DISPLAY_FILTER_OPTION
} from "../actions/types";
import merge from "lodash/merge";
import { gooddeal } from "../actions/gooddeal";
import { normalize } from "normalizr";

const initialState = {
  isFetching: false,
  gooddeals: {},
  gooddealsLocations: null,
  page: 1,
  hasNextPage: false,
  nextPage: 2,
  totalPages: 0,
  totalDocs: 0,
  reload: 0,
  inGoodDealsScreen: false,
  goodDealsOnTop: true,
  cptNewGoodDeals: 0,
  filter: [],
  onClickFilter: null,
  displayFilterOptions: false
};

export default function gooddealReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_GET_GOOD_DEALS) {
        return {
          ...state,
          isFetching: true
        };
      }
      if (action.payload === FETCH_GET_GOOD_DEALS_LOCATION) {
        return {
          ...state,
          isFetching: true,
          gooddealsLocations: null
        };
      }
      break;

    case RESET_DATA:
      return {
        ...initialState
      };

    case SET_GOODDEAL_ON_CLICK_FILTER:
      return {
        ...state,
        onClickFilter: action.payload
      };

    case SET_GOODDEAL_DISPLAY_FILTER_OPTION:
      return {
        ...state,
        displayFilterOptions: action.payload
      };

    case CATEGORIES_FILTER:
      return {
        ...state,
        filter: action.payload
      };

    case SET_GOOD_DEALS_RESULT: {
      const { pagesinfos, gooddeals } = action.entities;
      const { page, hasNextPage, totalPages, totalDocs } = pagesinfos["1"];

      if (page === 1) {
        return {
          ...state,
          gooddeals: gooddeals ? gooddeals : {},
          page,
          nextPage: 1 + page,
          hasNextPage,
          totalPages,
          totalDocs
        };
      } else {
        return merge({}, state, {
          gooddeals,
          page,
          nextPage: 1 + page,
          hasNextPage,
          totalPages,
          totalDocs
        });
      }
    }

    case SET_GOOD_DEALS_LOCATION_RESULT:
      return {
        ...state,
        gooddealsLocations: action.payload
      };

    case GOODDEALS_ON_TOP:
      return {
        ...state,
        goodDealsOnTop: action.value,
        cptNewGoodDeals: action.value ? 0 : state.cptNewGoodDeals
      };

    case NAVIGATION:
      if (
        action.name === "GoodDealsScreen" ||
        action.name === "GoodDealsScreens"
      ) {
        return {
          ...state,
          inGoodDealsScreen: true,
          cptNewGoodDeals: state.goodDealsOnTop ? 0 : state.cptNewGoodDeals
        };
      } else {
        return {
          ...state,
          inGoodDealsScreen: false
        };
      }

    case WS_EVENT:
      {
        if (action.payload.type === ADD_GOODDEAL) {
          const normalized = normalize(action.payload, gooddeal);
          const { gooddeals } = normalized.entities;
          return merge(
            {},
            {
              gooddeals,
              cptNewGoodDeals:
                state.inGoodDealsScreen && state.goodDealsOnTop
                  ? 0
                  : ++state.cptNewGoodDeals
            },
            state
          );
        } else if (action.payload.type === UPDATE_GOODDEAL) {
          // remove gooddeal id from state
          let {
            [action.payload._id]: value,
            ...withoutSecond
          } = state.gooddeals;
          const normalized = normalize(action.payload, gooddeal);
          const { gooddeals } = normalized.entities;
          return {
            ...state,
            gooddeals: { ...gooddeals, ...withoutSecond },
            cptNewGoodDeals:
              state.inGoodDealsScreen && state.goodDealsOnTop
                ? 0
                : ++state.cptNewGoodDeals
          };
        } else if (action.payload.type === DELETE_GOODDEAL) {
          const {
            [action.payload.id]: value,
            ...withoutSecond
          } = state.gooddeals;
          // When a record is removed, we stay in the same page
          return {
            ...state,
            gooddeals: { ...withoutSecond }
          };
        }
      }
      break;

    case WS_CONNECTED:
      return {
        ...state,
        reload: ++state.reload
      };

    case API_END:
      if (
        action.payload === FETCH_GET_GOOD_DEALS ||
        action.payload === FETCH_GET_GOOD_DEALS_LOCATION
      ) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
}
