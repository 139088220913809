import Constants from "expo-constants";

export const initExpoBranch = async () => {
  if (Constants.appOwnership === "standalone") {
    const ExpoBranch = await import("expo-branch");
    Branch = ExpoBranch.default;
    // Your code here ...
  }
};

export let Branch = null;
