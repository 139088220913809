import React, { useState, useEffect } from "react";
import * as Location from "expo-location";
import { BackHandler } from "react-native";
import MapView from "react-native-maps";

import { useDispatch, useSelector } from "react-redux";

import commonStyles, {
  // COLOR2,
  isiOS
} from "../styles/commonStyles";
import {
  SET_DROP_DOWN_ALERT_ERROR,
  SET_DROP_DOWN_ALERT_INFO,
  SET_COMMUNITY_ID
} from "../actions/types";
import { Button } from "react-native-elements";
import Modal from "react-native-modalbox";
import { t } from "../services/i18n";
import SafeAreaView from "react-native-safe-area-view";
// import { MaterialIcons } from "@expo/vector-icons";

const DEFAULT_LATITUDE_DELTA = 0.0763;
const DEFAULT_LONGITUDE_DELTA = 0.1284;

export default function CommunitiesMapModal(props) {
  const { onClosed, showCommunitiesMapModal } = props;
  const { communities, communityId } = useSelector(
    state => state.communityReducer
  );

  const dispatch = useDispatch();

  const [initialRegion, setInitialRegion] = useState(null);
  const [showsUserLocation, setShowsUserLocation] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const _getLocationAsync = async () => {
    const { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== "granted") {
      dispatch({
        type: SET_DROP_DOWN_ALERT_INFO,
        info: "locationinfo"
      });
    } else {
      try {
        let location = await Location.getCurrentPositionAsync({
          accuracy: isiOS ? Location.Accuracy.Lowest : Location.Accuracy.Low
        });
        setShowsUserLocation(true);
        setInitialRegion({
          latitudeDelta: DEFAULT_LATITUDE_DELTA,
          longitudeDelta: DEFAULT_LONGITUDE_DELTA,
          latitude: location.coords.latitude,
          longitude: location.coords.longitude
        });
      } catch ({ message }) {
        dispatch({
          type: SET_DROP_DOWN_ALERT_ERROR,
          error: "locationerror",
          message
        });
      }
    }
  };

  const onBackPress = () => {
    setOpen(false);
    return true;
  };

  useEffect(() => {
    if (showCommunitiesMapModal) {
      BackHandler.addEventListener("hardwareBackPress", onBackPress);
      if (communities && communities[communityId]) {
        const latitude = communities[communityId].latitude;
        const longitude = communities[communityId].longitude;
        setInitialRegion({
          latitudeDelta: DEFAULT_LATITUDE_DELTA,
          longitudeDelta: DEFAULT_LONGITUDE_DELTA,
          latitude,
          longitude
        });
      }
      setOpen(true);
    } else {
      setOpen(false);
      BackHandler.removeEventListener("hardwareBackPress", onBackPress);
    }
  }, [showCommunitiesMapModal]);

  const setCommunityId = communityId => {
    dispatch({
      type: SET_COMMUNITY_ID,
      value: communityId
    });
    setOpen(false);
  };

  const {
    darkgrey,
    flex1,
    justifyContentCenter,
    alignItemsCenter,
    fontBold,
    fs18,
    w100p
  } = commonStyles;

  return (
    <Modal
      backdrop={true}
      backdropOpacity={0.7}
      backdropColor="black"
      position="center"
      style={flex1}
      backdropPressToClose={false}
      swipeToClose={false}
      onClosed={onClosed}
      isOpen={isOpen}
    >
      <SafeAreaView
        style={[
          w100p,
          alignItemsCenter,
          justifyContentCenter,
          {
            position: "absolute",
            bottom: 30,
            left: 0,
            zIndex: 3
          }
        ]}
      >
        <Button
          type="clear"
          activeOpacity={0.8}
          title={t("button:close")}
          onPress={() => setOpen(false)}
          titleStyle={[darkgrey, fontBold, fs18]}
        />
        {/* <TouchableOpacity
          style={{
            position: "absolute",
            left: 40,
            zIndex: 2
          }}
          onPress={async () => await _getLocationAsync()}
        >
          <MaterialIcons name="my-location" size={40} color={COLOR2} />
        </TouchableOpacity> */}
      </SafeAreaView>

      {isOpen && (
        <MapView
          style={[flex1]}
          initialRegion={initialRegion}
          showsUserLocation={showsUserLocation}
        >
          {communities &&
            Object.values(communities).map(
              ({ _id, name, latitude, longitude }) =>
                latitude &&
                longitude && (
                  <MapView.Marker
                    key={_id}
                    coordinate={{
                      latitude: latitude,
                      longitude: longitude
                    }}
                    title={name}
                    onPress={() => setCommunityId(_id)}
                  />
                )
            )}
        </MapView>
      )}
    </Modal>
  );
}
