import React, { Component, Fragment } from "react";
import {
  ActivityIndicator,
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { connect } from "react-redux";
import { t } from "../services/i18n";
import commonStyles, {
  COLOR2,
  DARKGREY_COLOR,
  SCREEN_WIDTH
} from "../styles/commonStyles";
import MapRoute from "./MapRoute.js";
import { getImage } from "../assets/Images";
import { MaterialIcons } from "@expo/vector-icons";
import { BUSINESS_NAME } from "../config/constants.js";
import { openURL, openBrowser } from "../utils/UrlUtil";

class CommunityDetails extends Component {
  renderView = () => {
    const {
      color3,
      color2,
      color5,
      darkgrey,
      bgWhite,
      font,
      fontBold,
      bgColor1,
      justifyContentCenter,
      spaceBetween,
      row,
      fs20,
      mt5,
      mt10,
      mv10,
      mt20,
      p20,
      pv20,
      mb10,
      mb20,
      shadowGrey,
      textCenter,
      flex1,
      flex2,
      contain,
      w100p    
    } = commonStyles;

    const {
      name,
      address,
      zipCode,
      city,
      phone,
      fax,
      mail,
      webSiteUrl,
      logoUri,
      mapUri,
      mapSmallUri,
      latitude,
      longitude
    } = this.props.community;
    const { navigation, dispatch } = this.props;
    const { navigate } = navigation;

    return (
      <ScrollView style={[bgColor1]} showsVerticalScrollIndicator={false}>
        <View style={[p20, bgWhite, mt5, shadowGrey]}>
          <Text style={[fontBold, color3, fs20, textCenter]}>
            {BUSINESS_NAME}
          </Text>
          <Text style={[fontBold, color2, fs20, textCenter, mt10]}>{name}</Text>
          {logoUri && (
            <View style={[flex1, row, mt20, spaceBetween]}>
              <View style={flex2}>
                {address && (
                  <Text
                    style={[font, darkgrey, { fontSize: 17, flexWrap: "wrap" }]}
                  >
                    {address}
                  </Text>
                )}
                <Text style={[font, darkgrey, mt10, { fontSize: 17 }]}>
                  {zipCode} {city}
                </Text>

                {phone && (
                  <Text style={[font, mt10, darkgrey, { fontSize: 15 }]}>
                    {t("infos:tel")}{" "}
                    <Text
                      style={[font, mt10, color3, { fontSize: 15 }]}
                      onPress={() => openURL(dispatch, "telprompt:" + phone)}
                    >
                      {phone}
                    </Text>
                  </Text>
                )}

                {fax && (
                  <Text style={[font, mt10, darkgrey, { fontSize: 15 }]}>
                    {t("infos:fax")}{" "}
                    <Text style={[font, mt10, color3, { fontSize: 15 }]}>
                      {fax}
                    </Text>
                  </Text>
                )}
              </View>

              <View
                style={[
                  flex1,
                  justifyContentCenter,
                  {
                    alignItems: "flex-end",
                    height: 100
                  }
                ]}
              >
                {logoUri ? (
                  <Image
                    style={[{ height: 100 }, w100p, contain]}
                    source={{ uri: logoUri }}
                  />
                ) : (
                  <Image
                    style={[{ height: 100 }, w100p, contain]}
                    source={getImage("logo")}
                  />
                )}
              </View>
            </View>
          )}
          {mail && (
            <Text style={[font, mt20, darkgrey, { fontSize: 12 }]}>
              {t("infos:contact")}{" "}
              <Text
                style={[font, mt20, color2, { fontSize: 12 }]}
                onPress={() => openURL(dispatch, "mailto:" + mail)}
              >
                {mail}
              </Text>
            </Text>
          )}
          {webSiteUrl && (
            <Text style={[font, mt20, darkgrey, { fontSize: 12 }]}>
              {t("infos:website")}{" "}
              <Text
                style={[font, mt20, color2, { fontSize: 12 }]}
                onPress={() => openBrowser(dispatch, webSiteUrl)}
              >
                {webSiteUrl}
              </Text>
            </Text>
          )}
        </View>
        {latitude && longitude && (
          <View style={[pv20, bgWhite, mt10, shadowGrey]}>
            <Text style={[font, color5, fs20, mb20, textCenter]}>
              {t("infos:location")}
            </Text>
            <MapRoute
              latitude={latitude}
              longitude={longitude}
              title={BUSINESS_NAME + " " + name}
              lang={this.props.lang}
            />
          </View>
        )}
        {mapUri && mapSmallUri && (
          <View style={[flex1, p20, bgWhite, mv10, shadowGrey]}>
            <Text style={[font, color5, fs20, mb10, textCenter]}>
              {t("infos:map")}
            </Text>

            <View
              style={[
                {
                  height: 300
                },
                w100p
              ]}
            >
              <Image
                style={[
                  {
                    height: 300
                  },
                  w100p,
                  contain
                ]}
                source={{ uri: mapSmallUri }}
              />
              <TouchableOpacity
                style={{
                  width: 160,
                  position: "absolute",
                  bottom: 60,
                  left: SCREEN_WIDTH / 2 - 100
                }}
              >
                <MaterialIcons.Button
                  name="zoom-in"
                  size={30}
                  color={DARKGREY_COLOR}
                  backgroundColor={"rgba(255, 255, 255, 0.4)"}
                  borderRadius={25}
                  onPress={() => navigate("ImageZoomScreen", { uri: mapUri })}
                >
                  <Text style={[font]}>{t("infos:clickimage")}</Text>
                </MaterialIcons.Button>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </ScrollView>
    );
  };

  render() {
    const { flex1, bgColor1, justifyContentCenter } = commonStyles;

    return (
      <View style={[flex1, bgColor1]}>
        {this.props.isFetching ? (
          <ActivityIndicator
            style={[flex1, justifyContentCenter]}
            size="large"
            color={COLOR2}
          />
        ) : (
          <Fragment>{this.renderView()}</Fragment>
        )}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.communityReducer.isFetching,
    community: state.communityReducer.communityDetails,
    lang: state.globalInformationsReducer.lang
  };
};

export default connect(mapStateToProps)(CommunityDetails);
