import React, { useEffect } from "react";
import { Image } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { fetchCheckVersionAuthenticated } from "../actions";
import {
  SET_COMMUNITY_ID,
  SET_CITY_ID,
  SET_INITIAL_URL
} from "../actions/types";
import { useDispatch, useSelector } from "react-redux";
import { getImage } from "../assets/Images";
import commonStyles from "../styles/commonStyles";
import { Linking } from "react-native";

export default function LoadingScreen() {
  const dispatch = useDispatch();

  const { initUrl, accessDenied, serverVersion } = useSelector(
    state => state.globalInformationsReducer
  );

  useEffect(() => {
    AsyncStorage.getItem("cityId").then(cityId => {
      if (cityId) {
        dispatch({
          type: SET_CITY_ID,
          value: cityId
        });
      }
    });
    AsyncStorage.getItem("communityId").then(communityId => {
      if (communityId) {
        dispatch({
          type: SET_COMMUNITY_ID,
          value: communityId
        });
      }
    });

    (async () => {
      if (!initUrl) {
        dispatch({
          type: SET_INITIAL_URL,
          value: await Linking.getInitialURL()
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (initUrl && !accessDenied) {
      if (serverVersion) {
        // Workaround: wait a while before checking authentication
        // if we logout and call fetchCheckVersionAuthenticated quickly, the user session will be reused
        setTimeout(() => dispatch(fetchCheckVersionAuthenticated(false)), 1000);
      } else {
        dispatch(fetchCheckVersionAuthenticated(false));
      }
    }
  }, [initUrl]);

  const { cover, w100p, h100p } = commonStyles;
  return <Image style={[cover, w100p, h100p]} source={getImage("splash")} />;
}
