import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Image,
  ImageBackground,
  Linking,
  Modal,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  StatusBar
} from "react-native";
import { Button, Icon, Tooltip } from "react-native-elements";
import Swiper from "react-native-web-swiper";
import {
  fetchGetCities,
  fetchGetCityCommunities,
  fetchCheckVersion
} from "../actions";
import {
  SET_COMMUNITY_ID,
  SET_CITY_ID,
  SET_DEEP_LINK_TOKEN,
  SET_QUERY_CITY_ID,
  SET_SELECTED_CATEGORY,
  SET_DROP_DOWN_ALERT_WARN,
  SHOW_LOGIN_MODAL,
  SHOW_QR_CODE_SCANNER_MODAL,
  HIDE_QR_CODE_SCANNER_MODAL,
  SET_REGISTER_KEY,
  CLEAR_SCAN,
  SET_NEED_REGISTER_KEY,
  CLEAR_INITIAL_URL
} from "../actions/types";
import {
  APPLE_STORE_URL,
  GOOGLE_PLAY_URL,
  CREATE_COMMUNITY_URL,
  PWA_DEEPLINK_APP_URL
} from "../config/constants";
import { getImageUri, getImage } from "../assets/Images";
import CommunitiesMapModal from "../components/CommunitiesMapModal";
import LoginModal from "../components/LoginModal";
import { t } from "../services/i18n";
import commonStyles, {
  COLOR2,
  isiOS,
  DARKGREY_COLOR,
  SCREEN_HEIGHT
} from "../styles/commonStyles";
import { openURL, openBrowser } from "../utils/UrlUtil";
import QrCodeScannerModal from "../components/QrCodeScannerModal";
import { parse } from "search-params";
import ModalSelector from "../components/ModalSelector/ModalSelector";
import { useDispatch, useSelector } from "react-redux";
// import AsyncStorage from "@react-native-async-storage/async-storage";
import { useLinkTo } from "@react-navigation/native";

export default function LandingScreen(props) {
  const dispatch = useDispatch();
  const linkTo = useLinkTo();

  const { navigation } = props;

  const tooltip1Ref = useRef();
  const tooltip2Ref = useRef();
  const selectorRef = useRef();
  const communitySelectorRef = useRef();

  const [
    isCommunitiesMapModalDisplayed,
    setCommunitiesMapModalDisplayed
  ] = useState(false);

  const {
    accessDenied,
    dropDownAlertInfoGI,
    dropDownAlertErrorGI,
    initialUrl
  } = useSelector(state => state.globalInformationsReducer);
  let { dropDownAlertError, dropDownAlertInfo } = useSelector(
    state => state.errorReducer
  );
  const { link } = useSelector(state => state.urlscanReducer);
  let {
    cities,
    currentCommunity,
    queryCityId,
    cityId,
    communityId,
    computedCommunities,
    needRegisterKey
  } = useSelector(state => state.communityReducer);

  const userAgent = window.navigator.userAgent;
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && safari.pushNotification)
    ) ||
    userAgent.match(/iPad/i) ||
    userAgent.match(/iPhone/i);

  const getRedirectLink = url => {
    if (__DEV__ && url?.startsWith("https://192.168.1.74:19006")) {
      return url.substring("https://192.168.1.74:19006".length);
    } else if (__DEV__ && url?.startsWith("http://192.168.1.74:19006")) {
      return url.substring("http://192.168.1.74:19006".length);
    } else if (__DEV__ && url?.startsWith("https://localhost:19006")) {
      return url.substring("https://localhost:19006".length);
    } else if (__DEV__ && url?.startsWith("http://localhost:19006")) {
      return url.substring("http://localhost:19006".length);
    } else if (url?.startsWith(PWA_DEEPLINK_APP_URL)) {
      return url.substring(PWA_DEEPLINK_APP_URL.length);
    } else {
      return null;
    }
  };
  dropDownAlertError = dropDownAlertErrorGI || dropDownAlertError;
  dropDownAlertInfo = dropDownAlertInfoGI || dropDownAlertInfo;

  const urlMatch = url => {
    let matches = url.match(/\/(connexion|rejoindre)\?([^\n]*)/);
    if (matches) {
      let params = matches[2];
      const { cityId, communityId, token, registerkey } = parse(params);
      if (cityId) {
        dispatch(fetchGetCityCommunities(cityId));
        dispatch({
          type: SET_CITY_ID,
          value: cityId
        });
      }
      if (communityId) {
        dispatch({
          type: SET_COMMUNITY_ID,
          value: communityId
        });
      }
      if (token) {
        dispatch({
          type: SET_DEEP_LINK_TOKEN,
          payload: token
        });
        dispatch({
          type: SET_SELECTED_CATEGORY,
          value: 0
        });
        dispatch({
          type: SHOW_LOGIN_MODAL
        });
      }
      if (registerkey) {
        dispatch({
          type: SET_REGISTER_KEY,
          value: registerkey,
          hideRegisterKey: true
        });
        dispatch({
          type: SET_SELECTED_CATEGORY,
          value: 1
        });
        dispatch({
          type: SHOW_LOGIN_MODAL
        });
      }
      return true;
    }
    return false;
  };

  const urlListener = event => {
    urlMatch(event.url);
  };

  useEffect(() => {
    (async () => {
      Linking.addEventListener("url", urlListener);

      // const hideTooltips = await AsyncStorage.getItem("hideTooltips");
      const hideTooltips = true;
      if (!hideTooltips) {
        setTimeout(() => tooltip1Ref?.current?.toggleTooltip(), 1000);
      }
    })();

    return () => Linking.removeEventListener("url", urlListener);
  }, []);

  useEffect(() => {
    if (initialUrl) {
      if (!urlMatch(initialUrl)) {
        const link = getRedirectLink(initialUrl);
        if (link && link !== "/") {
          linkTo(link);
        }
      }
      dispatch({
        type: CLEAR_INITIAL_URL
      });
    }
  }, [initialUrl]);

  useEffect(() => {
    if (link) {
      openURL(dispatch, link);
      dispatch({
        type: CLEAR_SCAN
      });
    }
  }, [link]);
  // 1: ACCESS DENIED => Retrieve cities and versions
  useEffect(() => {
    if (accessDenied) {
      dispatch(fetchGetCities());
      dispatch(fetchCheckVersion());
    }
  }, [accessDenied]);

  // 2: Cities retrieved
  useEffect(() => {
    if (cities && cityId) {
      dispatch(fetchGetCityCommunities(cityId));
    }
  }, [cities]);
  useEffect(() => {
    if (queryCityId) {
      setCommunitiesMapModalDisplayed(true);
    }
  }, [queryCityId]);

  useEffect(() => {
    dispatch({
      type: SET_NEED_REGISTER_KEY,
      value: needRegisterKey
    });
  }, [needRegisterKey]);

  const showLoginModal = () => {
    if (currentCommunity) {
      dispatch({
        type: SHOW_LOGIN_MODAL
      });
    } else {
      dispatch({
        type: SET_DROP_DOWN_ALERT_WARN,
        warn: "selectcamping"
      });
    }
  };

  const {
    color2,
    bgColor1,
    bgWhite,
    bgColor2,
    darkgrey,
    white,
    flex1,
    flex2,
    fs18,
    fs25,
    font,
    fontBold,
    underline,
    p20,
    ph20,
    pb20,
    justifyContentCenter,
    alignItemsCenter,
    positionAbsolute,
    w100p,
    cover,
    rounded22,
    textCenter,
    mb20,
    m20,
    overflowHidden,
    content
  } = commonStyles;

  let communityName = "";
  if (currentCommunity) {
    communityName = currentCommunity.name;
  }

  computedCommunities = computedCommunities.map(obj => ({
    ...obj,
    component: <Text style={[font, darkgrey, textCenter]}>{obj.label}</Text>
  }));

  let computedCities = cities
    ? Object.values(cities).map(obj => ({
        label: obj.name,
        value: obj._id,
        component: <Text style={[font, darkgrey, textCenter]}>{obj.name}</Text>
      }))
    : [];

  computedCommunities = [
    {
      value: "selectcamping",
      section: true,
      component: (
        <Text style={[fontBold, color2, textCenter]}>
          {t("login:selectcamping")}
        </Text>
      )
    },
    ...computedCommunities,
    {
      value: "createcommuniy",
      section: true,
      component: (
        <TouchableOpacity
          onPress={() => openBrowser(dispatch, CREATE_COMMUNITY_URL)}
        >
          <Text style={[fontBold, textCenter]}>
            {t("login:createcommunity")}
          </Text>
        </TouchableOpacity>
      )
    }
  ];
  computedCities = [
    {
      value: "selectcity",
      section: true,
      component: (
        <Text style={[fontBold, color2, textCenter]}>
          {t("login:selectcity")}
        </Text>
      )
    },
    ...computedCities
  ];

  return (
    <ScrollView
      contentContainerStyle={[flex1, bgColor1]}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
    >
      <StatusBar barStyle="dark-content" />
      <LoginModal
        onClosed={() =>
          dispatch({
            type: HIDE_LOGIN_MODAL
          })
        }
      />
      <CommunitiesMapModal
        onClosed={() => setCommunitiesMapModalDisplayed(false)}
        showCommunitiesMapModal={isCommunitiesMapModalDisplayed}
      />
      <QrCodeScannerModal
        onClosed={() =>
          dispatch({
            type: HIDE_QR_CODE_SCANNER_MODAL
          })
        }
      />
      <View style={[isSafari ? { minHeight: SCREEN_HEIGHT / 2 } : {}, flex1]}>
        <ImageBackground source={getImageUri("tent")} style={[flex1, cover]}>
          <View style={flex2}>
            <TouchableOpacity
              style={[flex1]}
              onPress={() => navigation.navigate("AppInfosScreen")}
            >
              <Image
                style={[flex1, m20]}
                source={getImage("logo")}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
          <View style={flex1}>
            <ModalSelector
              ref={communitySelectorRef}
              overlayStyle={content}
              customSelector={
                <TouchableOpacity
                  style={[alignItemsCenter, { marginBottom: 50 }]}
                  onPress={() => communitySelectorRef?.current?.open()}
                >
                  <Text style={[fontBold, darkgrey, fs25, mb20, textCenter]}>
                    {communityName}
                  </Text>
                  <Text style={[fontBold, fs18, white, underline]}>
                    {t("login:choosecamping")}
                  </Text>
                </TouchableOpacity>
              }
              data={computedCommunities}
              supportedOrientations={["portrait"]}
              keyExtractor={item => item.value}
              initValue={communityId}
              cancelText={t("button:cancel")}
              onChange={option => {
                dispatch({
                  type: SET_COMMUNITY_ID,
                  value: option.value
                });
                navigation.navigate("LandingScreen", {
                  communityUri: option.customUri
                });
              }}
            />
          </View>
        </ImageBackground>
        <ModalSelector
          ref={selectorRef}
          overlayStyle={content}
          customSelector={<Fragment />}
          data={computedCities}
          supportedOrientations={["portrait"]}
          keyExtractor={item => item.value}
          initValue={cityId}
          accessible={true}
          scrollViewAccessibilityLabel={"Select city"}
          cancelText={t("button:cancel")}
          onChange={option => {
            dispatch({
              type: SET_QUERY_CITY_ID,
              value: option.value
            });
            dispatch(fetchGetCityCommunities(option.value));
          }}
        />
        <TouchableOpacity
          style={[
            positionAbsolute,
            {
              left: 13,
              bottom: 10
            }
          ]}
          onPress={() =>
            dispatch({
              type: SHOW_QR_CODE_SCANNER_MODAL
            })
          }
        >
          <Tooltip
            ref={tooltip1Ref}
            ModalComponent={Modal}
            popover={<Text style={[font, white]}>{t("login:tooltip1")}</Text>}
            backgroundColor={DARKGREY_COLOR}
            withOverlay={false}
            toggleOnPress={false}
            height={100}
            width={180}
            onClose={() => {
              tooltip2Ref?.current?.toggleTooltip();
            }}
          >
            <Icon
              raised
              reverse
              name="qrcode-scan"
              color={COLOR2}
              reverseColor="white"
              type="material-community"
              size={28}
            />
          </Tooltip>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            positionAbsolute,
            {
              right: 13,
              bottom: 10
            }
          ]}
          onPress={() => selectorRef?.current?.open()}
        >
          <Tooltip
            ref={tooltip2Ref}
            ModalComponent={Modal}
            backgroundColor={DARKGREY_COLOR}
            withOverlay={false}
            toggleOnPress={false}
            height={80}
            width={180}
            popover={<Text style={[font, white]}>{t("login:tooltip2")}</Text>}
          >
            <Icon
              raised
              reverse
              name="map-search-outline"
              color={COLOR2}
              reverseColor="white"
              type="material-community"
              size={28}
            />
          </Tooltip>
        </TouchableOpacity>
      </View>
      <View style={[flex1, p20]}>
        <Swiper
          loop={true}
          activeDotColor={COLOR2}
          dotColor={"rgba(255, 255, 255, 0.4)"}
          containerStyle={{
            marginBottom: 20,
            flex: 1,
            borderRadius: 22,
            backgroundColor: "white"
          }}
          controlsProps={{
            dotActiveStyle: { backgroundColor: COLOR2 },
            prevTitle: t("button:previous"),
            nextTitle: t("button:next"),
            prevTitleStyle: { color: COLOR2, fontFamily: "fontBold" },
            nextTitleStyle: { color: COLOR2, fontFamily: "fontBold" }
          }}
        >
          <View style={[flex1, rounded22, overflowHidden]}>
            <ImageBackground
              source={getImageUri("tent2")}
              style={[
                flex1,
                cover,
                alignItemsCenter,
                justifyContentCenter,
                p20
              ]}
              imageStyle={{ opacity: 0.9 }}
            >
              <Text style={[fontBold, white, fs25, textCenter, mb20]}>
                {t("login:meet")}
              </Text>
            </ImageBackground>
          </View>
          <View style={[flex1, rounded22, overflowHidden]}>
            <ImageBackground
              source={getImageUri("hike")}
              style={[
                flex1,
                cover,
                alignItemsCenter,
                justifyContentCenter,
                p20,
                {
                  backgroundColor: "rgba(0, 0, 0, 0.1)"
                }
              ]}
            >
              <Text style={[fontBold, white, fs25, textCenter, mb20]}>
                {t("login:find")}
              </Text>
            </ImageBackground>
          </View>
          <View style={[flex1, rounded22, overflowHidden]}>
            <ImageBackground
              source={getImageUri("workshop")}
              style={[
                flex1,
                cover,
                alignItemsCenter,
                justifyContentCenter,
                p20
              ]}
            >
              <Text style={[fontBold, white, fs25, textCenter, mb20]}>
                {t("login:alert")}
              </Text>
            </ImageBackground>
          </View>
          <View style={[flex1, rounded22, overflowHidden]}>
            <ImageBackground
              source={getImageUri("park")}
              style={[
                flex1,
                cover,
                alignItemsCenter,
                justifyContentCenter,
                p20
              ]}
            >
              <Text style={[fontBold, white, fs25, textCenter, mb20]}>
                {t("login:discount")}
              </Text>
            </ImageBackground>
          </View>
          <View style={[flex1, rounded22, overflowHidden]}>
            <ImageBackground
              source={getImageUri("firework")}
              style={[
                flex1,
                cover,
                alignItemsCenter,
                justifyContentCenter,
                p20
              ]}
            >
              <TouchableOpacity
                style={alignItemsCenter}
                onPress={() =>
                  isiOS
                    ? openURL(dispatch, APPLE_STORE_URL)
                    : openURL(dispatch, GOOGLE_PLAY_URL)
                }
              >
                <Text style={[fontBold, white, fs25, textCenter, mb20]}>
                  {t("login:like")}
                </Text>
                <Text style={[fontBold, fs18, white, underline]}>
                  {t("login:rate")}
                </Text>
              </TouchableOpacity>
            </ImageBackground>
          </View>
        </Swiper>
        <Button
          buttonStyle={[bgColor2]}
          containerStyle={[w100p]}
          activeOpacity={0.8}
          title={t("login:join")}
          onPress={showLoginModal}
          titleStyle={[white, font]}
          raised
        />
      </View>
    </ScrollView>
  );
}
