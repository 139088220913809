import React, { useState, useCallback, useRef } from "react";
import { Image, TouchableOpacity, View } from "react-native";
import { Avatar } from "react-native-elements";
import YoutubePlayer from "react-native-youtube-iframe";
import { getImage } from "../assets/Images";
import commonStyles, { SCREEN_WIDTH } from "../styles/commonStyles";

const WIDTH = (200 * 16) / 9;
const width = WIDTH > SCREEN_WIDTH - 70 ? SCREEN_WIDTH - 70 : WIDTH;

const YoutubeView = ({ youtubeVideoId }) => {
  const [playing, setPlaying] = useState(false);

  const { h200, roundedTop5, overflowHidden } = commonStyles;

  const onStateChange = useCallback(state => {
    if (state === "ended") {
      setPlaying(false);
    }
  }, []);

  const togglePlaying = useCallback(() => {
    setPlaying(prev => !prev);
  }, []);

  return (
    <Avatar
      containerStyle={[{ width }, h200, roundedTop5, overflowHidden]}
      renderPlaceholderContent={
        <Image
          style={[{ width }, h200, roundedTop5]}
          source={getImage("placeholder")}
        />
      }
      onPress={togglePlaying}
    >
      <YoutubePlayer
        height={200}
        width={width}
        play={playing}
        videoId={youtubeVideoId}
        onChangeState={onStateChange}
      />
    </Avatar>
  );
};

export default YoutubeView;
