import React, { Component } from "react";
// Workaround use react-native Image, react-native-expo-image-cache crashed in case of ios
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { withNavigation } from "@react-navigation/compat";
import { connect } from "react-redux";
import {
  getActivities,
  getCategoriesForActivities,
  getCategoriesForUser,
  getUserTemplates,
  getCategoriesForTemplates,
  getTypeTemplates,
  getProposals,
  getCategoriesForProposals
} from "../selectors";
import { getTranslatedProperty, t } from "../services/i18n";
import commonStyles, {
  DARKGREY_COLOR,
  SCREEN_WIDTH
} from "../styles/commonStyles";
import { SET_PUBLISH_TEMPLATE } from "../actions/types";
import {
  ROLE_ADMINISTRATOR,
  ROLE_WEBMASTER,
  TYPE_ACTIVITY,
  TYPE_PROPOSAL,
  TYPE_TEMPLATE
} from "../config/constants";
import { getImage } from "../assets/Images";
import { openBrowser } from "../utils/UrlUtil";
const itemSize = SCREEN_WIDTH / 3 - 20 > 120 ? 120 : SCREEN_WIDTH / 3 - 20;
const contentSize = itemSize - 40;

class SelectTemplate extends Component {
  componentDidUpdate(prevProps, prevState) {
    const { navigation, template } = this.props;

    if (template && !prevProps.template) {
      navigation.navigate("SelectTemplateDateScreen", {
        origin: "LiveScreen",
        id: template._id,
        name: getTranslatedProperty(template, "name")
      });
    }
  }

  renderCategoryTemplates = category => {
    const {
      flexWrap,
      p10,
      row,
      font,
      wrap,
      textCenter,
      flex1,
      justifyContentCenter,
      alignItemsCenter,
      opacity0,
      darkgrey
    } = commonStyles;
    const { buttonActivity } = styles;
    const components = [];
    const { templates, dispatch } = this.props;
    for (const templateId of category.templateIds) {
      const template = templates[templateId];
      if (template) {
        components.push(
          <TouchableOpacity
            key={template._id}
            style={[buttonActivity]}
            onPress={() =>
              dispatch({
                type: SET_PUBLISH_TEMPLATE,
                payload: template
              })
            }
          >
            {template.picto ? (
              <View style={flex1}>
                <Image
                  style={[
                    {
                      width: contentSize,
                      height: contentSize,
                      tintColor: DARKGREY_COLOR
                    },
                    justifyContentCenter,
                    alignItemsCenter
                  ]}
                  source={getImage(template.picto)}
                />
                <Image
                  style={[
                    {
                      width: contentSize,
                      height: contentSize
                    },
                    opacity0,
                    justifyContentCenter,
                    alignItemsCenter
                  ]}
                  source={getImage(template.picto)}
                />
              </View>
            ) : (
              <View style={flex1}>
                <Image
                  style={[
                    { width: contentSize, height: contentSize },
                    justifyContentCenter,
                    alignItemsCenter
                  ]}
                  source={{ uri: template.photoUri }}
                />
              </View>
            )}

            <Text
              style={[
                font,
                darkgrey,
                wrap,
                { fontSize: 11, paddingHorizontal: 5 },
                textCenter
              ]}
              numberOfLines={2}
            >
              {getTranslatedProperty(template, "name")}
            </Text>
          </TouchableOpacity>
        );
      }
    }

    return <View style={[flexWrap, row, p10]}>{components}</View>;
  };

  renderCategories() {
    const components = [];
    const { fontBold, color5, fs20, ml10 } = commonStyles;

    const { categories } = this.props;
    for (const categoryId in categories) {
      components.push(
        <View key={categoryId}>
          <Text style={[fontBold, color5, fs20, ml10, { marginTop: 15 }]}>
            {getTranslatedProperty(categories[categoryId], "name")}
          </Text>
          {this.renderCategoryTemplates(categories[categoryId])}
        </View>
      );
    }

    return components;
  }

  render() {
    const {
      flex1,
      bgColor1,
      font,
      color5,
      color2,
      ml10,
      mb10,
      m10,
      mt20,
      fs12
    } = commonStyles;

    const { dispatch, description, type } = this.props;
    return (
      <View style={[flex1, bgColor1]}>
        <Text
          style={[font, color5, ml10, mb10, { fontSize: 18, marginTop: 15 }]}
        >
          {description}
        </Text>
        <ScrollView style={[bgColor1]}>
          {this.renderCategories()}
          {type !== TYPE_TEMPLATE && (
            <Text style={[font, m10, color5, fs12, { lineHeight: 20 }]}>
              {t("infos:pictosshort")}
              <Text
                style={[font, mt20, color2, fs12]}
                onPress={() => openBrowser(dispatch, "https://www.freepik.com")}
              >
                Freepik
              </Text>
              {t("infos:from")}
              <Text
                style={[font, mt20, color2, fs12]}
                onPress={() =>
                  openBrowser(dispatch, "https://www.flaticon.com")
                }
              >
                www.flaticon.com
              </Text>
            </Text>
          )}
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = state => {
  const { template } = state.templateReducer;
  const newProps = {
    type: TYPE_TEMPLATE,
    template
  };

  if (
    state.profileReducer.user.role === ROLE_ADMINISTRATOR ||
    state.profileReducer.user.role === ROLE_WEBMASTER
  ) {
    newProps.categories = getCategoriesForTemplates(state);
    newProps.templates = getTypeTemplates(state);
  } else {
    newProps.categories = getCategoriesForUser(state);
    newProps.templates = getUserTemplates(state);
  }
  newProps.title = t("menu:templates");
  newProps.description = t("template:select");
  return newProps;
};

const mapStateToPropsActivities = state => {
  const { template } = state.templateReducer;
  return {
    template,
    type: TYPE_ACTIVITY,
    categories: getCategoriesForActivities(state),
    templates: getActivities(state),
    title: t("menu:instantsharing"),
    description: t("template:propose")
  };
};

const mapStateToPropsProposals = state => {
  const { template } = state.templateReducer;
  return {
    template,
    type: TYPE_PROPOSAL,
    categories: getCategoriesForProposals(state),
    templates: getProposals(state),
    title: t("menu:needservice"),
    description: t("template:need")
  };
};

export default {
  SelectActivity: connect(mapStateToPropsActivities)(
    withNavigation(SelectTemplate)
  ),
  SelectProposal: connect(mapStateToPropsProposals)(
    withNavigation(SelectTemplate)
  ),
  SelectTemplate: connect(mapStateToProps)(withNavigation(SelectTemplate))
};

const styles = StyleSheet.create({
  buttonActivity: {
    height: itemSize,
    width: itemSize,
    borderColor: "black",
    borderRadius: 3,
    backgroundColor: "white",
    borderWidth: 1,
    alignItems: "center",
    marginRight: 10,
    marginBottom: 10,
    paddingVertical: 5
  }
});
