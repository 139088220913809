import {
  FETCH_GET_GOOD_DEALS,
  SET_GOOD_DEALS_RESULT,
  SET_GOOD_DEALS_FAILURE,
  FETCH_GET_GOOD_DEAL_DETAILS,
  SET_GOOD_DEAL_DETAILS_RESULT,
  SET_GOOD_DEAL_DETAILS_FAILURE,
  FETCH_GET_GOOD_DEALS_CATEGORIES,
  SET_GOOD_DEALS_CATEGORIES,
  SET_GOOD_DEALS_CATEGORIES_FAILURE,
  FETCH_GET_GOOD_DEALS_LOCATION,
  SET_GOOD_DEALS_LOCATION_RESULT,
  SET_GOOD_DEALS_LOCATION_FAILURE,
  FETCH_GET_GOOD_DEAL_DETAILS_USING_URI
} from "./types";
import { schema } from "normalizr";
import { apiAction } from "./api";

const goodDealCategory = new schema.Entity(
  "goodDealsCategories",
  {},
  {
    idAttribute: "_id"
  }
);

export const gooddeal = new schema.Entity(
  "gooddeals",
  {
    categoryId: goodDealCategory
  },
  { idAttribute: "_id" }
);

const pageinfos = new schema.Entity("pagesinfos", {
  docs: [gooddeal]
});

export function fetchGetGoodDealsCategories() {
  return apiAction({
    url: "/gooddeals/categories",
    method: "GET",
    schema: [goodDealCategory],
    onSuccess: setGoodDealsCategories,
    onFailure: setGoodDealsCategoriesFailure,
    label: FETCH_GET_GOOD_DEALS_CATEGORIES
  });
}

function setGoodDealsCategories(normalized) {
  return {
    type: SET_GOOD_DEALS_CATEGORIES,
    entities: normalized.entities
  };
}

function setGoodDealsCategoriesFailure(error) {
  return {
    type: SET_GOOD_DEALS_CATEGORIES_FAILURE,
    payload: error
  };
}

export function fetchGetGoodDeals(page, filter) {
  return apiAction({
    url: `/gooddeals/community?page=${page}${
      filter ? "&filter=" + filter : ""
    }`,
    schema: pageinfos,
    method: "GET",
    onSuccess: setGoodDeals,
    onFailure: setGoodDealsFailure,
    label: FETCH_GET_GOOD_DEALS
  });
}

function setGoodDeals(normalized) {
  return {
    type: SET_GOOD_DEALS_RESULT,
    entities: normalized.entities
  };
}

function setGoodDealsFailure(error) {
  return {
    type: SET_GOOD_DEALS_FAILURE,
    payload: error
  };
}

export function fetchGetGoodDealsLocation(page, filter) {
  return apiAction({
    url: `/gooddeals/community?page=${page}${
      filter ? "&filter=" + filter : ""
    }&all=true`,
    method: "GET",
    onSuccess: setGoodDealsLocation,
    onFailure: setGoodDealsLocationFailure,
    label: FETCH_GET_GOOD_DEALS_LOCATION
  });
}

function setGoodDealsLocation(data) {
  return {
    type: SET_GOOD_DEALS_LOCATION_RESULT,
    payload: data
  };
}

function setGoodDealsLocationFailure(error) {
  return {
    type: SET_GOOD_DEALS_LOCATION_FAILURE,
    payload: error
  };
}

export function fetchGetGoodDealDetails(id) {
  return apiAction({
    url: "/gooddeals/details/" + id,
    method: "GET",
    onSuccess: setGoodDealDetails,
    onFailure: setGoodDealDetailsFailure,
    label: FETCH_GET_GOOD_DEAL_DETAILS
  });
}

export function fetchGetGoodDealDetailsUsingUri(customUri) {
  return apiAction({
    url: "/gooddeals/communitytype",
    method: "GET",
    data: { customUri },
    onSuccess: setGoodDealDetails,
    onFailure: setGoodDealDetailsFailure,
    label: FETCH_GET_GOOD_DEAL_DETAILS_USING_URI
  });
}

function setGoodDealDetails(data) {
  return {
    type: SET_GOOD_DEAL_DETAILS_RESULT,
    payload: data
  };
}

function setGoodDealDetailsFailure(error) {
  return {
    type: SET_GOOD_DEAL_DETAILS_FAILURE,
    payload: error
  };
}
