import React, { useEffect } from "react";
import { View } from "react-native";
import { fetchPushToken } from "../actions/login";
import {
  SET_DROP_DOWN_ALERT_INFO,
  CLEAR_REGISTER_PUSH_NOTIFICATIONS
} from "../actions/types";
import { useSelector, useDispatch } from "react-redux";
import firebase from "firebase/app";
import "firebase/messaging";

export default function PushNotifications() {
  const dispatch = useDispatch();
  const { registerForPushNotifications } = useSelector(
    state => state.loginReducer
  );
  const { lang } = useSelector(state => state.globalInformationsReducer);

  const registerForPushNotificationsAsync = async () => {
    let pushToken;
    try {
      const messaging = firebase.messaging();
      const currentToken = await messaging.getToken();
      if (currentToken) {
        console.log("FCM token> ", currentToken);
        pushToken = currentToken;
      } else {
        console.log("No Token available");
      }

      messaging.onMessage(payload => {
        console.log("Message received. ", payload);
        const { title, ...options } = payload.notification;
        navigator.serviceWorker.register("firebase-messaging-sw.js");
        function showNotification() {
          Notification.requestPermission(function (result) {
            if (result === "granted") {
              navigator.serviceWorker.ready.then(function (registration) {
                registration.showNotification(payload.notification.title, {
                  body: payload.notification.body,
                  tag: payload.notification.tag
                });
              });
            }
          });
        }
        showNotification();
      });
    } catch (error) {
      console.log("An error ocurred while retrieving token. ", error);
    }

    return pushToken;
  };

  useEffect(() => {
    if (registerForPushNotifications) {
      (async () => {
        const token = await registerForPushNotificationsAsync();
        if (token) {
          dispatch(fetchPushToken(token, lang));
        } else {
          dispatch({
            type: SET_DROP_DOWN_ALERT_INFO,
            info: "notificationinfo"
          });
          dispatch({
            type: CLEAR_REGISTER_PUSH_NOTIFICATIONS
          });
        }
      })();
    }
  }, [registerForPushNotifications]);

  return (
    // Empty view
    <View />
  );
}
