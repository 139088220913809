import {
  API_START,
  API_END,
  FETCH_PUSH_TOKEN,
  SET_TOKEN_RESULT
} from "../actions/types";

const initialState = {
  isFetching: false,
  data: null
};

export default function tokenReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_PUSH_TOKEN) {
        return {
          ...state,
          isFetching: true,
          data: null
        };
      }
      break;

    case SET_TOKEN_RESULT:
      return {
        ...state,
        data: action.payload
      };

    case API_END:
      if (action.payload === FETCH_PUSH_TOKEN) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
}
