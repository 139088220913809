import firebase from "firebase/app";
import "@firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDLGVmwponIgZX-L9eg8zs1IsRzgj5cNY8",
  authDomain: "digihapi.firebaseapp.com",
  databaseURL: "https://digihapi.firebaseio.com",
  projectId: "digihapi",
  storageBucket: "digihapi.appspot.com",
  messagingSenderId: "52274617384",
  appId: "1:52274617384:web:9fb56a31cc692def80291d",
  measurementId: "G-52EHQ9HFRH"
};

export const firebaseInit = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
};
