import {
  FETCH_GET_LOCATIONS,
  SET_LOCATIONS,
  SET_LOCATIONS_FAILURE
} from "./types";
import { schema } from "normalizr";
import { apiAction } from "./api";

const location = new schema.Entity(
  "locations",
  {},
  {
    idAttribute: "_id"
  }
);

export function fetchGetLocations() {
  return apiAction({
    url: "/locations",
    method: "GET",
    schema: [location],
    onSuccess: setLocations,
    onFailure: setLocationsFailure,
    label: FETCH_GET_LOCATIONS
  });
}

function setLocations(normalized) {
  return {
    type: SET_LOCATIONS,
    entities: normalized.entities
  };
}

function setLocationsFailure(error) {
  return {
    type: SET_LOCATIONS_FAILURE,
    payload: error
  };
}
