import React from "react";
import { View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export let insetsBottom = 0;

export default function Insets() {
  const insets = useSafeAreaInsets();

  React.useEffect(() => {
    insetsBottom = insets.bottom;
  }, []);

  return <View />;
}
