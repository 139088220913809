import React, { Component, Fragment } from "react";
import { Text, ScrollView, View, LayoutAnimation } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchPublishRequest } from "../actions/template";
import commonStyles, { COLOR2, COLOR5 } from "../styles/commonStyles";
import { t } from "../services/i18n";
import {
  SET_DROP_DOWN_ALERT_WARN,
  SET_PUBLISH_DATE,
  SET_PUBLISH_TIME,
  SET_PUBLISH_EQUIPMENT,
  CLEAR_PUBLISH,
  SET_PUBLISH_CHOICE,
  TOGGLE_DISPLAY_PREVIEW,
  SET_ON_CLICK_PREVIEW,
  SET_PUBLISH_TEMPLATE
} from "../actions/types";
import { ROLE_GUEST } from "../config/constants";
import CommonPicker from "../components/CommonPicker";
import ActionButton from "../components/actionButton/ActionButton";
import LiveEvent from "../components/LiveEvent";
import { MaterialCommunityIcons, FontAwesome } from "@expo/vector-icons";

// import * as WebBrowser from "expo-web-browser";
// import { Linking } from "expo";

class SelectTemplateDate extends Component {
  state = {
    menuOpen: false
  };

  onClickPreview = () => {
    LayoutAnimation.easeInEaseOut();
    this.props.dispatch({
      type: TOGGLE_DISPLAY_PREVIEW
    });
  };

  componentDidMount() {
    let { dispatch, route, template, templates } = this.props;
    if (!template && route.params && route.params.id) {
      template = templates[route.params.id];
      dispatch({
        type: SET_PUBLISH_TEMPLATE,
        payload: template
      });
    }
    dispatch({
      type: SET_ON_CLICK_PREVIEW,
      payload: this.onClickPreview
    });
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: CLEAR_PUBLISH
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, navigation, route } = this.props;
    const { origin } = route?.params || {};

    if (data && !prevProps.data) {
      if (origin) {
        navigation.navigate("LiveScreen");
      } else {
        navigation.goBack();
      }
    }
  }

  // _handleRedirect = event => {
  //   WebBrowser.dismissBrowser();

  //   let data = Linking.parse(event.url);

  //   console.log(data);
  // };

  // _addLinkingListener = () => {
  //   Linking.addEventListener("url", this._handleRedirect);
  // };

  // _removeLinkingListener = () => {
  //   Linking.removeEventListener("url", this._handleRedirect);
  // };

  // openCartInBrowser = async () => {
  //   let {
  //     user,
  //   } = this.props;

  //   this._addLinkingListener();
  //   let result = await WebBrowser.openBrowserAsync(
  //     `${
  //       DEV_URL ? DEV_URL : URL
  //     }/camper/cart?linkingUri=${Linking.makeUrl()}&pseudo=${
  //       user.pseudo
  //     }&communityId=${user.lastCampsiteId}`
  //   );
  //   console.log(result);
  //   this._removeLinkingListener();
  // }

  sendRequest = () => {
    let {
      dispatch,
      fetchPublishRequest,
      user,
      locationId,
      dateId,
      timeId,
      equipmentId,
      template,
      choiceId,
      groupId,
      goodDealId
    } = this.props;

    if (user) {
      if (user.role === ROLE_GUEST) {
        dispatch({
          type: SET_DROP_DOWN_ALERT_WARN,
          warn: "guestforbidden"
        });
      } else {
        fetchPublishRequest({
          dateId,
          timeId,
          locationId,
          equipmentId,
          templateId: template._id,
          groupId,
          choiceId,
          goodDealId
        });
      }
    } else {
      dispatch({
        type: SET_DROP_DOWN_ALERT_WARN,
        warn: "nouserforbidden"
      });
    }
  };

  render() {
    const {
      color5,
      bgColor1,
      flex1,
      font,
      fontBold,
      fs16,
      pv10,
      column
    } = commonStyles;

    let {
      dispatch,
      navigation,
      isFetching,
      dateId,
      timeId,
      equipmentId,
      locationId,
      choiceId,
      template,
      templateDates,
      templateTimes,
      equipments,
      displayTimes,
      templates,
      locations,
      displayPreview,
      route
    } = this.props;

    if (!template && route.params && route.params.id) {
      template = templates[route.params.id];
    }
    const { origin } = route?.params || {};
    const { menuOpen } = this.state;

    return (
      <View style={[flex1, bgColor1, column]}>
        {displayPreview && template && (
          <LiveEvent
            groupId={template.type?.toLowerCase()}
            template={template}
            event={{
              dateId,
              timeId,
              locationId,
              equipmentId,
              choiceId
            }}
            locations={locations}
            equipments={equipments}
            templateDates={templateDates}
            templateTimes={templateTimes}
          />
        )}

        <ScrollView style={flex1} contentContainerStyle={{ paddingBottom: 90 }}>
          {template && template.choices && (
            <Fragment>
              <Text
                style={[
                  font,
                  color5,
                  { fontSize: 18, marginLeft: 10, marginTop: 15 }
                ]}
              >
                {t("template:selectmessage")}
              </Text>
              <View style={{ paddingTop: 10, paddingHorizontal: 20 }}>
                <CommonPicker
                  data={template.choices}
                  selectedValue={choiceId || ""}
                  onChange={itemValue =>
                    dispatch({
                      type: SET_PUBLISH_CHOICE,
                      payload: itemValue
                    })
                  }
                />
              </View>
            </Fragment>
          )}
          <Text
            style={[
              font,
              color5,
              { fontSize: 18, marginLeft: 10, marginTop: 15 }
            ]}
          >
            {t("template:proposedate")}
          </Text>
          <View style={{ paddingTop: 10, paddingHorizontal: 20 }}>
            <CommonPicker
              data={templateDates}
              firstPickerLabel={t("template:proposedate")}
              selectedValue={dateId || ""}
              onChange={itemValue =>
                dispatch({
                  type: SET_PUBLISH_DATE,
                  payload: itemValue
                })
              }
            />
            {displayTimes && (
              <View style={{ paddingTop: 10 }}>
                <CommonPicker
                  data={templateTimes}
                  selectedValue={timeId || ""}
                  onChange={itemValue =>
                    dispatch({
                      type: SET_PUBLISH_TIME,
                      payload: itemValue
                    })
                  }
                />
              </View>
            )}
          </View>
          {equipments && (
            <Fragment>
              <Text
                style={[
                  font,
                  color5,
                  { fontSize: 18, marginLeft: 10, marginTop: 15 }
                ]}
              >
                {t("template:proposelocation")}
              </Text>
              <View style={[pv10, { paddingHorizontal: 20 }]}>
                <CommonPicker
                  data={equipments}
                  firstPickerLabel={t("template:proposelocation")}
                  selectedValue={equipmentId || ""}
                  onChange={itemValue =>
                    dispatch({
                      type: SET_PUBLISH_EQUIPMENT,
                      payload: itemValue
                    })
                  }
                />
              </View>
            </Fragment>
          )}
        </ScrollView>
        <ActionButton
          buttonColor={menuOpen ? COLOR5 : COLOR2}
          degrees={90}
          renderIcon={() => (
            <MaterialCommunityIcons
              name="dots-horizontal"
              size={40}
              color="white"
            />
          )}
          onPressIn={() => this.setState({ menuOpen: true })}
          onReset={() => this.setState({ menuOpen: false })}
          disabled={isFetching}
        >
          <ActionButton.Item
            buttonColor={"red"}
            title={t("button:cancel")}
            onPress={() => {
              if (origin) {
                navigation.navigate("LiveScreen");
              } else {
                navigation.goBack();
              }
            }}
            textStyle={[fontBold, fs16]}
          >
            <MaterialCommunityIcons name="cancel" size={36} color="white" />
          </ActionButton.Item>
          <ActionButton.Item
            buttonColor={COLOR2}
            title={t("button:post")}
            onPress={this.sendRequest}
            textStyle={[fontBold, fs16]}
          >
            <FontAwesome name="send" size={32} color="white" />
          </ActionButton.Item>
        </ActionButton>
      </View>
    );
  }
}

const mapStateToProps = state => {
  const {
    isFetching,
    dateId,
    timeId,
    equipmentId,
    goodDealId,
    choiceId,
    locationId,
    templateDates,
    templateTimes,
    equipments,
    displayTimes,
    template,
    data,
    groupId,
    displayPreview
  } = state.templateReducer;
  const { templates, locations } = state.entitiesReducer;
  return {
    isFetching,
    dateId,
    timeId,
    equipmentId,
    goodDealId,
    choiceId,
    locationId,
    templateDates,
    templateTimes,
    equipments,
    displayTimes,
    template,
    data,
    groupId,
    user: state.profileReducer.user,
    templates,
    locations,
    displayPreview
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators({ fetchPublishRequest }, dispatch);
  return { ...actions, dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectTemplateDate);
