import { Linking } from "react-native";
import * as WebBrowser from "expo-web-browser";
import { COLOR2, WHITE_COLOR } from "../styles/commonStyles";
import { SET_DROP_DOWN_ALERT_ERROR } from "../actions/types";
import { t } from "../services/i18n";

export async function openBrowser(dispatch, url) {
  try {
    return await WebBrowser.openBrowserAsync(url, {
      toolbarColor: COLOR2,
      controlsColor: WHITE_COLOR
    });
  } catch (err) {
    console.error("An error occurred", err);
    dispatch({
      type: SET_DROP_DOWN_ALERT_ERROR,
      error: t("alert:urlerror")
    });
  }
}

export async function openURL(dispatch, url) {
  try {
    const supported = await Linking.canOpenURL(url);
    if (supported) {
      return await Linking.openURL(url);
    } else {
      dispatch({
        type: SET_DROP_DOWN_ALERT_ERROR,
        error: t("alert:urlnotsupported")
      });
    }
  } catch (err) {
    console.error("An error occurred", err);
    dispatch({
      type: SET_DROP_DOWN_ALERT_ERROR,
      error: t("alert:urlerror")
    });
  }
}
