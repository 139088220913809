import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  Modal,
  ScrollView,
  TouchableHighlight
} from "react-native";
import Styles, { IMG } from "./LabelSelectStyle";
import { t } from "../services/i18n";
import commonStyles from "../styles/commonStyles";

export default class LabelSelect extends Component {
  addIcon = {
    uri: IMG.addIcon
  };

  static defaultProps = {
    style: {},
    customStyle: {},
    title: " ",
    enable: true,
    readOnly: false,
    onConfirm: () => {},
    enableAddBtn: true
    // confirmText: t("button:confirm"),
    // cancelText: t("button:cancel")
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false
    };
    this.selectedList = new Set();
    this.toggleSelect = this.toggleSelect.bind(this);
    this.cancelSelect = this.cancelSelect.bind(this);
    this.confirmSelect = this.confirmSelect.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  setModalVisible(isVisible) {
    this.setState({ isModalVisible: isVisible });
  }

  cancelSelect() {
    this.selectedList = new Set();
    this.setModalVisible(false);
  }

  confirmSelect() {
    const { onConfirm } = this.props;
    onConfirm(this.selectedList);
    this.cancelSelect();
  }

  openModal() {
    this.props.enable && !this.props.readOnly && this.setModalVisible(true);
  }

  toggleSelect(hobby) {
    if (this.selectedList.has(hobby._id)) {
      this.selectedList.delete(hobby._id);
    } else {
      this.selectedList.add(hobby._id);
    }
  }

  render() {
    const {
      readOnly,
      enable,
      title,
      style,
      enableAddBtn,
      customStyle,
      confirmText,
      cancelText
    } = this.props;

    const {
      font,
      fontBold,
      bgColor5,
      bgColor1,
      textCenter,
      white,
      justifyContentCenter,
      color5
    } = commonStyles;

    let selectedLabels = React.Children.toArray(this.props.children)
      .filter(item => item.type === Label)
      .map((child, index) => {
        return React.cloneElement(child, {
          enable: enable,
          readOnly: readOnly
        });
      });

    let modalItems = this.state.isModalVisible
      ? React.Children.toArray(this.props.children)
          .filter(item => item.type === ModalItem)
          .map((child, index) => {
            return React.cloneElement(child, {
              toggleSelect: this.toggleSelect
            });
          })
      : null;

    return (
      <View style={[Styles.selectedView, style]}>
        {selectedLabels}
        {enable && !readOnly && enableAddBtn && (
          <TouchableHighlight
            style={[Styles.selectedItem, Styles.addItem]}
            underlayColor="transparent"
            onPress={this.openModal}
          >
            <Image
              style={Styles.addIcon}
              source={this.addIcon}
              resizeMode="cover"
            />
          </TouchableHighlight>
        )}
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.isModalVisible}
          onRequestClose={() => {}}
        >
          <View style={{ flex: 1 }}>
            <TouchableHighlight
              style={Styles.modalMask}
              activeOpacity={1}
              underlayColor="#00000077"
              onPress={this.cancelSelect}
            >
              <View style={Styles.modalContainer}>
                <View style={[Styles.modal, customStyle.modal || {}]}>
                  <View style={[Styles.title, bgColor5]}>
                    <Text
                      style={[
                        Styles.titleText,
                        bgColor5,
                        font,
                        textCenter,
                        white
                      ]}
                    >
                      {title}
                    </Text>
                  </View>
                  <View style={Styles.scrollView}>
                    <ScrollView>{modalItems}</ScrollView>
                  </View>
                  <View
                    style={[Styles.buttonView, customStyle.buttonView || {}]}
                  >
                    <TouchableHighlight
                      underlayColor="transparent"
                      activeOpacity={0.8}
                      onPress={this.cancelSelect}
                    >
                      <View
                        style={[
                          Styles.modalButton,
                          bgColor1,
                          customStyle.cancelButton || {}
                        ]}
                      >
                        <Text
                          style={[
                            Styles.buttonText,
                            fontBold,
                            color5,
                            customStyle.cancelText || {}
                          ]}
                        >
                          {t("button:cancel")}
                        </Text>
                      </View>
                    </TouchableHighlight>
                    <TouchableHighlight
                      underlayColor="transparent"
                      activeOpacity={0.8}
                      onPress={this.confirmSelect}
                    >
                      <View
                        style={[
                          Styles.modalButton,
                          Styles.confirmButton,
                          justifyContentCenter,
                          customStyle.confirmButton || {}
                        ]}
                      >
                        <Text
                          style={[
                            Styles.buttonText,
                            fontBold,
                            customStyle.confirmText || {}
                          ]}
                        >
                          {t("button:confirm")}
                        </Text>
                      </View>
                    </TouchableHighlight>
                  </View>
                </View>
              </View>
            </TouchableHighlight>
          </View>
        </Modal>
      </View>
    );
  }
}

class Label extends Component {
  closeIcon = {
    uri: IMG.closeIcon
  };

  static defaultProps = {
    onCancel: () => {},
    enable: true,
    readOnly: false,
    customStyle: {}
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { enable, readOnly, onCancel, customStyle } = this.props;
    const { font } = commonStyles;
    return (
      <View style={[Styles.selectedItem, !enable && Styles.disableColor]}>
        <Text
          style={[
            Styles.labelText,
            !enable && Styles.disableText,
            font,
            customStyle.text || {}
          ]}
          numberOfLines={1}
          ellipsisMode="tail"
        >
          {this.props.children}
        </Text>
        {enable && !readOnly && (
          <TouchableHighlight
            style={Styles.closeContainer}
            underlayColor="transparent"
            activeOpacity={0.5}
            onPress={onCancel}
          >
            <View>
              <Image
                style={Styles.closeIcon}
                source={this.closeIcon}
                resizeMode="cover"
              />
            </View>
          </TouchableHighlight>
        )}
      </View>
    );
  }
}

class ModalItem extends Component {
  static defaultProps = {
    customStyle: {}
  };

  constructor(props) {
    super(props);
    this.isSelected = false;
    this._toggleSelect = this._toggleSelect.bind(this);
  }

  _toggleSelect() {
    const { toggleSelect, data } = this.props;
    // this.isSelected ? hobbiesIds.delete(data._id) : hobbiesIds.add(data._id);
    this.isSelected = !this.isSelected;
    this.forceUpdate();
    toggleSelect(data);
  }

  render() {
    const { customStyle } = this.props;
    const { font } = commonStyles;
    return (
      <TouchableHighlight
        activeOpacity={0.5}
        underlayColor="transparent"
        onPress={this._toggleSelect}
      >
        <View style={Styles.modalItem}>
          <Text
            style={[Styles.modalText, font, customStyle.modalText || {}]}
            numberOfLines={1}
            ellipsisMode="tail"
          >
            {this.props.children}
          </Text>
          <View
            style={[
              Styles.outerCircle,
              this.isSelected ? Styles.enableCircle : {},
              customStyle.outerCircle || {}
            ]}
          >
            {this.isSelected && (
              <View
                style={[Styles.innerCircle, customStyle.innerCircle || {}]}
              />
            )}
          </View>
        </View>
      </TouchableHighlight>
    );
  }
}

LabelSelect.Label = Label;
LabelSelect.ModalItem = ModalItem;
