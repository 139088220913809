import React, { Component } from "react";
import { Modal, View, TouchableHighlight, Text } from "react-native";
import DateRange from "./DateRange";
import moment from "moment";
import { normalize } from "./normalizeText";
import commonStyles, { COLOR1, COLOR4 } from "../../styles/commonStyles";
import { t } from "../../services/i18n";
import { Button } from "react-native-elements";

const styles = {
  placeholderText: {
    color: "#c9c9c9",
    fontSize: normalize(18)
  },
  contentInput: {
    alignItems: "center",
    justifyContent: "center"
  },
  contentText: {
    fontSize: normalize(18)
  },
  stylish: {
    height: 48,
    borderColor: COLOR4,
    borderRadius: 5,
    borderWidth: 1,
    backgroundColor: "#fafafa"
  }
};
export default class ComposePicker extends Component {
  constructor(props) {
    super(props);
    const { startDate, endDate, outFormat } = props;
    // console.log(startDate && endDate ? startDate + " → " + endDate : "");
    this.state = {
      modalVisible: false,
      allowPointerEvents: true,
      showContent: startDate && endDate ? true : false,
      selected: startDate && endDate ? startDate + " → " + endDate : "",
      startDate: startDate && endDate ? moment(startDate, outFormat) : null,
      endDate: startDate && endDate ? moment(endDate, outFormat) : null,
      date: new Date(),
      focus: "startDate",
      currentDate: moment()
    };
  }

  isDateBlocked = date => {
    if (this.props.blockBefore) {
      return date.isBefore(moment(), "day");
    }
    return false;
  };

  onDatesChange = event => {
    const { startDate, endDate, focusedInput, currentDate } = event;
    if (currentDate) {
      this.setState({ currentDate });
      return;
    }
    this.setState({ ...this.state, focus: focusedInput }, () => {
      this.setState({ ...this.state, startDate, endDate });
    });
  };

  setModalVisible = visible => {
    this.setState({ modalVisible: visible });
  };

  onConfirm = () => {
    const returnFormat = this.props.returnFormat || "YYYY/MM/DD";
    const outFormat = this.props.outFormat || "LL";
    if (!this.props.mode || this.props.mode === "single") {
      this.setState({
        showContent: true,
        selected: this.state.currentDate.format(outFormat)
      });
      this.setModalVisible(false);
      if (typeof this.props.onConfirm === "function") {
        this.props.onConfirm({
          currentDate: this.state.currentDate.format(returnFormat)
        });
      }
      return;
    }

    if (this.state.startDate && this.state.endDate) {
      const start = this.state.startDate.format(outFormat);
      const end = this.state.endDate.format(outFormat);
      this.setState({ showContent: true, selected: `${start} → ${end}` });
      this.setModalVisible(false);

      if (typeof this.props.onConfirm === "function") {
        this.props.onConfirm({
          startDate: this.state.startDate.format(returnFormat),
          endDate: this.state.endDate.format(returnFormat)
        });
      }
    }
  };
  getTitleElement() {
    const { placeholder, customStyles = {}, allowFontScaling } = this.props;
    const showContent = this.state.showContent;
    if (!showContent && placeholder) {
      return (
        <Text
          allowFontScaling={allowFontScaling}
          style={[styles.placeholderText, customStyles.placeholderText]}
        >
          {placeholder}
        </Text>
      );
    }
    return (
      <Text
        allowFontScaling={allowFontScaling}
        style={[styles.contentText, customStyles.contentText]}
      >
        {this.state.selected}
      </Text>
    );
  }
  render() {
    const { customStyles = {} } = this.props;
    const {
      white,
      bgColor4,
      rounded22,
      h44,
      w200,
      fontLight,
      content
    } = commonStyles;

    let style = styles.stylish;
    style = this.props.centerAlign ? { ...style } : style;
    style = { ...style, ...this.props.style };

    return (
      <TouchableHighlight
        underlayColor={"transparent"}
        onPress={() => {
          this.setModalVisible(true);
        }}
        style={[
          { width: "100%", height: "100%", justifyContent: "center" },
          style
        ]}
      >
        <View>
          <View>
            <View style={[customStyles.contentInput, styles.contentInput]}>
              {this.getTitleElement()}
            </View>
          </View>
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.modalVisible}
            onRequestClose={() => this.setModalVisible(false)}
          >
            <View style={[content, { flex: 1, flexDirection: "column" }]}>
              <View
                style={{
                  height: "90%",
                  backgroundColor: COLOR1
                }}
              >
                <DateRange
                  headFormat={this.props.headFormat}
                  customStyles={customStyles}
                  markText={this.props.markText}
                  onDatesChange={this.onDatesChange}
                  isDateBlocked={this.isDateBlocked}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  focusedInput={this.state.focus}
                  selectedBgColor={this.props.selectedBgColor || undefined}
                  selectedTextColor={this.props.selectedTextColor || undefined}
                  mode={this.props.mode || "single"}
                  currentDate={this.state.currentDate}
                />
              </View>
              <View
                style={{
                  // paddingBottom: "5%",
                  width: "100%",
                  height: "10%",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: COLOR1
                }}
              >
                <Button
                  buttonStyle={[bgColor4, h44, rounded22, w200]}
                  // containerStyle={[mt20, flex0]}
                  activeOpacity={0.8}
                  title={t("button:ok")}
                  onPress={this.onConfirm}
                  titleStyle={[white, fontLight]}
                  disabled={!this.state.startDate || !this.state.endDate}
                />
              </View>
            </View>
          </Modal>
        </View>
      </TouchableHighlight>
    );
  }
}
