import fr from "javascript-time-ago/locale/fr";
import en from "javascript-time-ago/locale/en";
import TimeAgo from "javascript-time-ago";

TimeAgo.addLocale(fr);
TimeAgo.addLocale(en);

function get2Chars(param) {
  return ("" + param).length === 1 ? "0" + param : param;
}

export function formatDate(d) {
  const date = new Date(d);
  return `${get2Chars(date.getDate())}/${get2Chars(
    date.getMonth() + 1
  )} - ${date.getHours()}:${get2Chars(date.getMinutes())}`;
}

function formatDateFr(d, datetimeDiff) {
  const timeAgo = new TimeAgo("fr-FR");

  const date = new Date();
  const now = date.getTime() + datetimeDiff;
  const dateEvent = new Date(d);

  let dateStr = timeAgo.format(now - (date.getTime() - dateEvent.getTime()));

  if (dateStr && dateStr.length > 0) {
    dateStr = dateStr.charAt(0).toUpperCase() + dateStr.slice(1);
  }

  switch (dateStr) {
    case "Il y a 4 heures":
    case "Il y a 5 heures":
    case "Il y a 6 heures":
    case "Il y a 7 heures":
    case "Il y a 8 heures":
    case "Il y a 9 heures":
    case "Il y a 10 heures":
    case "Il y a 11 heures":
    case "Il y a 12 heures":
    case "Il y a 13 heures":
    case "Il y a 14 heures":
    case "Il y a 15 heures":
    case "Il y a 16 heures":
    case "Il y a 17 heures":
    case "Il y a 18 heures":
    case "Il y a 19 heures":
    case "Il y a 20 heures":
    case "Il y a 21 heures":
    case "Il y a 22 heures":
    case "Il y a 23 heures":
      if (dateEvent.getHours() < date.getHours()) {
        dateStr = `Aujourd'hui ${dateEvent.getHours()}:${get2Chars(
          dateEvent.getMinutes()
        )}`;
      } else {
        dateStr = `Hier ${dateEvent.getHours()}:${get2Chars(
          dateEvent.getMinutes()
        )}`;
      }
      break;
    case "Il y a 1 jour":
      dateStr = `Hier ${dateEvent.getHours()}:${get2Chars(
        dateEvent.getMinutes()
      )}`;
      break;
    case "Il y a 2 jours":
      dateStr = `Avant-hier ${dateEvent.getHours()}:${get2Chars(
        dateEvent.getMinutes()
      )}`;
      break;
    default:
      // Do nothing
      break;
  }

  return dateStr;
}

function formatDateEn(d, datetimeDiff) {
  const timeAgo = new TimeAgo("en_EN");

  const date = new Date();
  const now = date.getTime() + datetimeDiff;
  const dateEvent = new Date(d);

  let dateStr = timeAgo.format(now - (date.getTime() - dateEvent.getTime()));

  if (dateStr && dateStr.length > 0) {
    dateStr = dateStr.charAt(0).toUpperCase() + dateStr.slice(1);
  }

  switch (dateStr) {
    case "In a moment":
      // case "In 2 minutes":
      dateStr = "Just now";
      break;
    case "4 hours ago":
    case "5 hours ago":
    case "6 hours ago":
    case "7 hours ago":
    case "8 hours ago":
    case "9 hours ago":
    case "10 hours ago":
    case "11 hours ago":
    case "12 hours ago":
    case "13 hours ago":
    case "14 hours ago":
    case "15 hours ago":
    case "16 hours ago":
    case "17 hours ago":
    case "18 hours ago":
    case "19 hours ago":
    case "20 hours ago":
    case "21 hours ago":
    case "22 hours ago":
    case "23 hours ago":
      if (dateEvent.getHours() < date.getHours()) {
        dateStr = `Today ${dateEvent.getHours()}:${get2Chars(
          dateEvent.getMinutes()
        )}`;
      } else {
        dateStr = `Yesterday ${dateEvent.getHours()}:${get2Chars(
          dateEvent.getMinutes()
        )}`;
      }
      break;
    case "A day ago":
      dateStr = `Yesterday ${dateEvent.getHours()}:${get2Chars(
        dateEvent.getMinutes()
      )}`;
      break;
    case "2 days ago":
      dateStr = `Before yesterday ${dateEvent.getHours()}:${get2Chars(
        dateEvent.getMinutes()
      )}`;
      break;
    default:
      // Do nothing
      break;
  }

  return dateStr;
}

export function formatDateIntl(lang, d, datetimeDiff) {
  if (lang === "fr") {
    return formatDateFr(d, datetimeDiff);
  }
  return formatDateEn(d, datetimeDiff);
}
