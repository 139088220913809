export const BUSINESS_NAME = "O’Camping";
export const SLOGAN = "Partageons nos vacances";
export const APP_NAME = "OCamping";
export const APP_BUNDLE_ID = "com.sa.ocamping";
export const COMMUNITY = "campsite";
export const COMMUNITIES = "campsites";

export const WEB_URL = "www.ocamping.fr";
export const DEEPLINK_APP_URL = "https://app.ocamping.fr";
export const DEEPLINK_URL_SCHEME = "ocamping://";
// export const DEEPLINK_DEV_URL = "exp://127.0.0.1:19000/--/";
// export const PWA_DEEPLINK_APP_URL = `http://localhost:3001`;
export const PWA_DEEPLINK_APP_URL = "https://digihapi-staging.herokuapp.com";
export const CONTACT_MAIL = "contact@digihapi.fr";

export const NOTIFICATION_ANDROID_CHANNEL_NAME = APP_NAME;

export const GOOGLE_CLOUD_STORAGE_URL =
  "https://storage.googleapis.com/digihapi/" + APP_NAME;

export const CREATE_COMMUNITY_URL =
  "https://www.ocamping.fr/enregistrement-camping";
export const SENTRY_DSN =
  "https://a4410f74e1de4e88b54bc7f3d0882892@sentry.io/1806094";

export const APPLE_STORE_URL =
  "https://apps.apple.com/fr/app/ocamping/id1456533134";
export const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=com.sa.ocamping";

export const COMMUNITY_MARKER_SUBSCRIBE =
  "https://mt.google.com/vt/icon/name=icons/spotlight/camping_v_L_8x.png&scale=2";
export const COMMUNITY_MARKER_UNSUBSCRIBE =
  "https://mt.google.com/vt/icon/name=icons/spotlight/camping_search_v_L_8x.png&scale=2";

export const TYPE_ACTIVITY = "Activity";
export const TYPE_PROPOSAL = "Proposal";
export const TYPE_TEMPLATE = "Template";

export const ROLE_GUEST = "Guest";
export const ROLE_ADVERTISER = "Advertiser";
export const ROLE_CAMPER = "Camper";
export const ROLE_CAMPER_GUEST = "Camper guest";
export const ROLE_EMPLOYEE = "Employee";
export const ROLE_EMPLOYEE_GUEST = "Employee guest";
export const ROLE_ADMINISTRATOR = "Administrator";
export const ROLE_WEBMASTER = "Webmaster";
export const ROLE_ADVERTISER_GUEST = "Advertiser guest";

export const GROUP_ID_WEATHER = "weather";
export const GROUP_ID_ACTIVITY = "activity";
export const GROUP_ID_GOODDEAL_ACTIVITY = "gooddealactivity";
export const GROUP_ID_PROPOSAL = "proposal";
export const GROUP_ID_TEMPLATE = "template";
export const GROUP_ID_PUBLISH = "publish";
export const GROUP_ID_ANSWERS = "answers";

export const EVENT_PING = "ping";
