import React, { Component } from "react";
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  Text,
  View
} from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchDeleteNotifUserTemplate,
  fetchGetNotifStatus,
  fetchSetNotifUserTemplates,
  fetchSetNotifAll,
  fetchSetNotifSelection
} from "../actions/template";
import {
  STATUS_ALL,
  STATUS_SELECTION,
  STATUS_UNDEFINED
} from "../actions/types";
import LabelSelect from "../components/LabelSelect";
import { getNotifTemplates } from "../selectors";
import { getTranslatedProperty, t } from "../services/i18n";
import commonStyles, { COLOR2 } from "../styles/commonStyles";
import { CheckBox } from "react-native-elements";

class Settings extends Component {
  componentDidUpdate(prevProps, prevState) {
    const { tokenResult, fetchGetNotifStatus } = this.props;

    if (!prevProps.tokenResult && tokenResult) {
      fetchGetNotifStatus();
    }
  }

  componentDidMount() {
    this.props.fetchGetNotifStatus();
  }

  selectConfirm = templatesIds => {
    let { selected, fetchSetNotifUserTemplates } = this.props;
    selected = new Set([...selected, ...templatesIds]);

    fetchSetNotifUserTemplates(Array.from(selected.values()));
  };

  deleteItem = templateId => {
    this.props.fetchDeleteNotifUserTemplate(templateId);
  };

  renderLabelSelectLabels() {
    const components = [];
    let { selected, templates } = this.props;

    for (const templateId of selected) {
      if (templateId) {
        components.push(
          <LabelSelect.Label
            key={templateId}
            data={templates[templateId]}
            onCancel={() => {
              this.deleteItem(templateId);
            }}
          >
            {getTranslatedProperty(templates[templateId], "name")}
          </LabelSelect.Label>
        );
      }
    }

    return components;
  }

  setNotifAll = () => {
    const { fetchSetNotifAll, dispatch, status } = this.props;
    // if (status === STATUS_UNDEFINED) {
    //   dispatch({
    //     type: REGISTER_FOR_PUSH_NOTIF
    //   });
    // } else {
    fetchSetNotifAll();
    // }
  };

  setNotifSelection = () => {
    const { fetchSetNotifSelection, dispatch, status } = this.props;
    // if (status === STATUS_UNDEFINED) {
    //   dispatch({
    //     type: REGISTER_FOR_PUSH_NOTIF
    //   });
    // } else {
    fetchSetNotifSelection();
    // }
  };

  render() {
    const {
      bgColor1,
      font,
      flex1,
      justifyContentCenter,
      darkgrey,
      p10,
      mb10
    } = commonStyles;

    const { isFetching, gender, templates, selected, status } = this.props;

    return (
      <View style={[flex1, bgColor1]}>
        {isFetching ? (
          <ActivityIndicator
            style={[flex1, justifyContentCenter]}
            size="large"
            color={COLOR2}
          />
        ) : (
          <ScrollView style={[flex1, p10]}>
            <Text
              style={[font, darkgrey, mb10, { fontSize: 18, marginTop: 15 }]}
            >
              {gender === "M" ? t("settings:notif") : t("settings:notif_F")}
            </Text>
            <CheckBox
              title={t("settings:allposts")}
              textStyle={[darkgrey, font]}
              containerStyle={[flex1]}
              checkedColor={COLOR2}
              uncheckedColor={COLOR2}
              checkedIcon="dot-circle-o"
              uncheckedIcon="circle-o"
              checked={status === STATUS_ALL}
              onPress={this.setNotifAll}
            />
            <CheckBox
              title={t("settings:selectedposts")}
              textStyle={[darkgrey, font]}
              containerStyle={[flex1]}
              checkedColor={COLOR2}
              uncheckedColor={COLOR2}
              checkedIcon="dot-circle-o"
              uncheckedIcon="circle-o"
              checked={status === STATUS_SELECTION}
              onPress={this.setNotifSelection}
            />
            {status !== STATUS_UNDEFINED &&
              (status === STATUS_SELECTION || selected.size > 0) && (
                <LabelSelect
                  title={t("settings:alerts")}
                  ref="select"
                  style={styles.labelSelect}
                  readOnly={status !== STATUS_SELECTION}
                  hobbiesIds={selected}
                  onConfirm={this.selectConfirm}
                >
                  {this.renderLabelSelectLabels()}
                  {Object.values(templates)
                    .filter(item => !selected.has(item._id))
                    .map(template => (
                      <LabelSelect.ModalItem
                        key={template._id}
                        data={template}
                        hobbiesIds={templates}
                      >
                        {getTranslatedProperty(template, "name")}
                      </LabelSelect.ModalItem>
                    ))}
                </LabelSelect>
              )}
          </ScrollView>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  labelSelect: {
    width: "100%",
    marginTop: 5,
    marginBottom: 20,
    padding: 5,
    borderWidth: 1,
    borderRadius: 6,
    borderStyle: "dashed",
    borderColor: COLOR2,
    backgroundColor: "#fafafa"
  }
});

const mapStateToProps = state => {
  const { isFetching, status, data } = state.settingsReducer;
  const { data: tokenResult } = state.tokenReducer;

  return {
    isFetching,
    status,
    selected: new Set(data),
    templates: getNotifTemplates(state),
    gender: state.profileReducer.user ? state.profileReducer.user.gender : null,
    tokenResult
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators(
    {
      fetchGetNotifStatus,
      fetchSetNotifUserTemplates,
      fetchDeleteNotifUserTemplate,
      fetchSetNotifAll,
      fetchSetNotifSelection
    },
    dispatch
  );
  return { ...actions, dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
