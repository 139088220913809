import React, { Component } from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { getTranslatedProperty, t } from "../services/i18n";
import { COLOR4 } from "../styles/commonStyles";
import LabelSelect from "./LabelSelect";

class Hobbies extends Component {
  constructor(props) {
    super(props);
    this.selectConfirm = this.selectConfirm.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  selectConfirm(selectedList) {
    let { hobbiesIds, onHobbiesChange } = this.props;
    hobbiesIds = new Set([...hobbiesIds, ...selectedList]);
    if (onHobbiesChange) {
      onHobbiesChange(Array.from(hobbiesIds.values()));
    }
    this.forceUpdate();
  }

  deleteItem(hobbyId) {
    let { hobbiesIds, onHobbiesChange } = this.props;
    hobbiesIds.delete(hobbyId);
    if (onHobbiesChange) {
      onHobbiesChange(Array.from(hobbiesIds.values()));
    }
    this.forceUpdate();
  }

  renderLabelSelectLabels() {
    const components = [];
    let { hobbiesIds, hobbies } = this.props;

    for (const hobbyId of hobbiesIds) {
      components.push(
        <LabelSelect.Label
          key={hobbyId}
          data={hobbies[hobbyId]}
          onCancel={() => {
            this.deleteItem(hobbyId);
          }}
        >
          {getTranslatedProperty(hobbies[hobbyId], "name")}
        </LabelSelect.Label>
      );
    }

    return components;
  }

  render() {
    let { hobbiesIds, readOnly = false } = this.props;

    return (
      <LabelSelect
        title={t("profile:hobbies")}
        ref="select"
        readOnly={readOnly}
        style={styles.labelSelect}
        hobbiesIds={hobbiesIds}
        onConfirm={this.selectConfirm}
      >
        {this.renderLabelSelectLabels()}
        {Object.values(this.props.hobbies)
          .filter(item => !hobbiesIds.has(item._id))
          .map((hobby, index) => (
            <LabelSelect.ModalItem
              key={hobby._id}
              data={hobby}
              hobbiesIds={hobbiesIds}
            >
              {getTranslatedProperty(hobby, "name")}
            </LabelSelect.ModalItem>
          ))}
      </LabelSelect>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: "#e3eeee"
  },
  labelSelect: {
    width: "100%",
    marginTop: 5,
    marginBottom: 20,
    padding: 5,
    borderWidth: 1,
    borderRadius: 6,
    borderStyle: "dashed",
    borderColor: COLOR4,
    backgroundColor: "#fafafa"
  },
  text: {
    fontSize: 16,
    color: "rgb(13, 131, 144)"
  }
});

const mapStateToProps = state => {
  return {
    hobbies: state.entitiesReducer.hobbies
  };
};

export default connect(mapStateToProps)(Hobbies);
