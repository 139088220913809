import React from "react";
import { Image, TouchableOpacity, View } from "react-native";
import { getImage } from "../assets/Images";
import commonStyles, { COLOR5 } from "../styles/commonStyles";

export const DEFAULT_HEADER_STYLE = {
  backgroundColor: COLOR5,
  elevation: 0,
  shadowOpacity: 0,
  borderBottomWidth: 0
};

export const getHeaderLeftBack = (navigation, options) => {
  const {
    flex1,
    bgColor2,
    justifyContentCenter,
    alignItemsCenter
  } = commonStyles;

  return () => (
    <TouchableOpacity
      style={flex1}
      onPress={() => {
        if (options?.origin) {
          navigation.navigate(options.origin);
        } else if (navigation.canGoBack()) {
          navigation.goBack();
        }
        if (options?.callback) {
          options.callback();
        }
      }}
    >
      <View
        style={[
          bgColor2,
          alignItemsCenter,
          justifyContentCenter,
          {
            height: "100%",
            width: 56,
            paddingBottom: 3
          }
        ]}
      >
        <Image style={{ width: 20, height: 20 }} source={getImage("back")} />
      </View>
    </TouchableOpacity>
  );
};
